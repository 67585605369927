import React, { useContext, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import dynamic_text from '../../../../../assets/img/EditorIcons/dynamic_text.svg';
import signatureIcon from '../../../../../assets/img/EditorIcons/signature_icon.png';
import insert_content from '../../../../../assets/img/EditorIcons/insert_content.png';
import insert_image from '../../../../../assets/img/EditorIcons/insert_image.png';
import InsertContentLinkModal from '../../../components/InsertContentLinkModal';
import useIsMobile from '../../../utilities/useIsMobile';
import insertTrackableLinkPopup from '../../../helpers/insertTrackableLinkPopup';
import { insertListTinyEditor } from '../../../utilities/insertListTinyEditor';
import { toggleSignature } from '../CrmEmailComponent/emailSignatureFunctions';
import { store } from '../../../../Store';
import createNotification from '../../../../Settings/Utilities/CreateNotification';
import strip_tags from '../../../utilities/stripTags';
import { validateEmail } from '../../../../../utils/validateEmail';
import useGetInitialEmailAddress from '../CrmEmailComponent/useGetInitialEmailAddress';

export default function TinyEditor({
  stateContent,
  handleBody,
  autoFocus,
  disabled,
  setEditorOnLoad = () => {},
  signatureEditor,
}) {
  const [showInsertContentModal, setShowInsertContentModal] = useState(false);
  const [bookmark, setBookmark] = useState(null);
  const editorRef = useRef(null);
  const onChange = (content) => {
    handleBody(content);
  };
  const isMobile = useIsMobile(480);
  const { getInitialSenderEmailAddress } = useGetInitialEmailAddress();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { currentEmailDetails, emailAddresses } = state;
  const insertContentLink = (item) => {
    const contentLink =
      item.contentType === 'Tweet' ? item.contentUrl : `${process.env.REACT_APP_APPURL}${item.contentUrl}`;
    const linkText = item.title;
    setShowInsertContentModal(false);
    editorRef.current.selection.select(editorRef.current.getBody(), true);
    editorRef.current.selection.collapse(false);
    editorRef.current.focus();
    editorRef.current.selection.bookmarkManager.moveToBookmark(bookmark);
    editorRef.current.execCommand(
      'mceInsertContent',
      false,
      `<a href=${contentLink} target="_blank" id="content_link">${linkText}</a>`
    );
  };

  const handleSignatureClick = () => {
    let bodyString = String(editorRef.current.getBody().innerHTML);
    let sender = getInitialSenderEmailAddress(currentEmailDetails.senderEmailAddressId, emailAddresses);
    let newBody = toggleSignature({
      emailAddressItem: sender,
      emailBody: bodyString,
    });
    let subject = document.getElementById('email-component-subject-input');
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'currentEmailDetails',
      value: { ...currentEmailDetails, body: newBody, subject: subject.value },
    });
  };

  const uploadImageHandler = (file, editor) => {
    let allowedImageTypesToInsert = ['image/png', 'image/gif', 'image/jpeg'];
    let fileType = file?.type;
    if (!allowedImageTypesToInsert.includes(fileType)) {
      return createNotification('warning', `Unsupported image type. Please use a JPG, PNG or GIF`);
    }
    const data = new FileReader();
    if (file?.name) {
      data.fileName = file.name;
    }

    const maxAllowedSizeMb = 0.2;
    if (file?.size > maxAllowedSizeMb * 1024 * 1024) {
      return createNotification('warning', `Max file size - ${maxAllowedSizeMb * 1000}Kb`);
    }
    data.onload = (e) => {
      editor.insertContent(`<img src="${data.result}" data-name="${data.fileName}" style="width:100px">`);
    };
    if (data && file && file.type.match('image.*')) {
      data.readAsDataURL(file);
    }
  };

  useEffect(() => {
    let buttons = document.querySelectorAll('.tox-tbtn');
    buttons.forEach((btn) => {
      btn.title = '';
    });
  }, [editorRef.current]);

  return (
    <>
      <InsertContentLinkModal
        showPopup={showInsertContentModal}
        setShowPopup={setShowInsertContentModal}
        insertContentLink={insertContentLink}
      />
      <Editor
        disabled={disabled}
        tinymceScriptSrc={process.env.REACT_APP_APPURL + '/tinymce/tinymce.min.js'}
        licenseKey='gpl'
        onInit={(evt, editor) => {
          editorRef.current = editor;
          setEditorOnLoad(false);
        }}
        onFocus={(evt, editor) => {
          editor.selection.select(editor.getBody(), true);
          editor.selection.collapse(false);
        }}
        value={stateContent}
        init={{
          newline_behavior: 'linebreak',
          height: '100%',
          menubar: false,
          plugins: [
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
          ],
          autolink_pattern: /^(https?:\/\/|ssh:\/\/|ftp:\/\/|file:\/|www\.)(.+)$/i,
          toolbar: disabled
            ? false
            : `customBold customItalic | bullist numlist | ${signatureEditor ? 'insertImageBtn |' : ''} dynamicTextBtn signatureBtn | customLink | insertContentBtn`,
          content_style: `@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400&family=Work+Sans:wght@300&display=swap'); body { font-family: Lato, sans-serif; line-height:1.5; font-size:15px; padding-left:12px; margin-top:18px !important; margin-left:${
            isMobile ? 0 : 10
          }px; color:#00122b;} p {margin: 0} a{color:#0094cc} #salutation_text {margin-left: 2.5px}`,
          extended_valid_elements: 'span[*]',
          toolbar_location: 'bottom',
          forced_root_block: 'p',
          forced_root_block_attrs: {
            style: 'font-family: Lato, sans-serif; font-size:15px; margin: 0; color: #00122b;',
          },
          link_assume_external_targets: 'http',
          convert_urls: false,
          link_title: false,
          anchor_top: false,
          anchor_bottom: false,
          paste_preprocess: (editor, args) => {
            let content = strip_tags(args.content, '<b><u><i><p><li><strong><a>');
            const parser = new DOMParser();
            const document = parser.parseFromString(content, 'text/html');
            let paragraphs = document.querySelectorAll('p');
            for (let i = 0; i < paragraphs?.length; i++) {
              let paragraph = paragraphs[i];
              paragraph.setAttribute(
                'style',
                'font-family: Lato, sans-serif; font-size:15px; margin: 0; color: #00122b;'
              );
            }
            args.content = document.body?.innerHTML;
          },
          setup: function (editor) {
            editor.on('init', function (e) {
              if (autoFocus) {
                editor.selection.select(editor.getBody(), true);
                editor.selection.collapse(false);
                editor.focus();
              }
            });
            editor.on('NodeChange', function (e) {
              if (e.element.tagName === 'P') {
                const toolbarButtons = editor.editorContainer.querySelectorAll('.tox-tbtn');
                toolbarButtons[2]?.classList.remove('tox-tbtn--enabled');
                toolbarButtons[3]?.classList.remove('tox-tbtn--enabled');
              }
              let signatureElem = editor.getBody().querySelector('#email_signature');
              if (signatureElem) {
                if (signatureElem?.innerText?.trim()?.length === 0 && !signatureElem?.innerHTML?.includes('<img')) {
                  setSignatureBtnDataTip(false);
                  handleSignatureClick();
                }
              }
            });
            var urlInputValue = '';
            editor.on('ExecCommand', function (e) {
              if (e.command === 'mceLink') {
                let dialog = document.getElementsByClassName('tox-dialog');
                let dialogBackdrop = document.getElementsByClassName('tox-dialog-wrap__backdrop');
                setTimeout(() => {
                  insertTrackableLinkPopup(dialog, dialogBackdrop, editor);
                  if (dialog?.[0]) {
                    let inputField = dialog[0].querySelector('input[type="url"]');
                    urlInputValue = inputField.value;
                    inputField.oninput = (e) => (urlInputValue = e?.target?.value);
                  }
                }, 10);
              }
              if (e.command === 'mceFocus') {
                let dialog = document.getElementsByClassName('tox-dialog');
                if (dialog) {
                  setTimeout(() => {
                    if (dialog?.[0]?.innerHTML?.includes('Do you want to add the required mailto: prefix')) {
                      let emailIsValid = !!validateEmail(String(urlInputValue));
                      let action = emailIsValid ? 'Yes' : 'No';
                      dialog[0].querySelector(`.tox-button[data-mce-name=${action}]`).click();
                      urlInputValue = '';
                    }
                  }, 10);
                }
              }
            });
            editor.on('Drop', async (event) => {
              event.preventDefault();
              event.stopImmediatePropagation();
              event.stopPropagation();
              uploadImageHandler(event?.dataTransfer?.files?.[0], editor);
            });
            var toggleState = false;
            editor.ui.registry.addIcon(
              'dynamicText',
              `<img class='salutation-toolbar-button' data-tooltip-content="Add salutation placeholder" data-tooltip-id="general-tooltip" data-tooltip-class-name="header-tooltip pricing-tooltip-description" src="${dynamic_text}" />`
            );
            if (signatureEditor) {
              editor.ui.registry.addIcon(
                'insertImageIcon',
                `<img class='insert-image-toolbar-button' data-tooltip-content="Insert image" data-tooltip-id="settings-tooltip" data-tooltip-class-name="header-tooltip pricing-tooltip-description" src="${insert_image}" />`
              );
            }
            let setSignatureBtnDataTip = (active, api) => {
              let signatureBtn = document.querySelector('#signature_btn');
              if (signatureBtn) {
                let text = '';
                let sender = getInitialSenderEmailAddress(currentEmailDetails.senderEmailAddressId, emailAddresses);
                if (!sender) {
                  text = "You'll be able to insert a signature once you select a 'From' address";
                  signatureBtn.setAttribute('data-tooltip-class-name', 'header-tooltip tooltip-width-220');
                  api?.setEnabled(false);
                } else if (!sender?.signature) {
                  text = 'You can create an email signature by going to Settings > Sender email addresses';
                  signatureBtn.setAttribute('data-tooltip-class-name', 'header-tooltip tooltip-width-260');
                  api?.setEnabled(false);
                } else {
                  text = `${active ? 'Remove' : 'Add'} signature`;
                  api?.setEnabled(true);
                  signatureBtn.setAttribute('data-tooltip-class-name', 'header-tooltip tooltip-width-220');
                }
                signatureBtn.setAttribute('data-tooltip-content', text);
              }
            };
            editor.ui.registry.addIcon(
              'signature',
              `<span id="signature_btn" data-tooltip-content="Signature" data-tooltip-id="general-tooltip" data-tooltip-class-name="header-tooltip tooltip-width-220"></span><img class="signature-toolbar-button" src="${signatureIcon}"/>`
            );
            editor.ui.registry.addIcon(
              'insertContent',
              `<img src="${insert_content}" style="transform:translateY(1px); padding: 10px 5px 10px 12px" />`
            );
            // editor.ui.registry.addMenuButton("dynamicTextBtn", {
            //   type: "menubutton",
            //   text: ``,
            //   icon: "dynamicText",
            //   fetch: function (callback) {
            //     var items = [
            //       {
            //         type: "togglemenuitem",
            //         text: "salutation",
            //         onAction: function () {
            //           toggleState = !toggleState;
            //           editor.insertContent(
            //             '&nbsp;<span id="salutation_text" style="border:1px solid rgba(0, 18, 43, 0.15); padding: 5px 5px 5px 36px;border-radius: 3px; background: url(https://cdn.policymogul.com/images/dynamic_text.svg); background-position: 10px; background-repeat: no-repeat; background-size: 17px; background-color:rgba(0, 148, 204, 0.04);">salutation</span>&nbsp;'
            //           );
            //         },
            //       },
            //     ];
            //     callback(items);
            //   },
            // });
            editor.ui.registry.addButton('dynamicTextBtn', {
              type: 'button',
              text: ``,
              tooltip: '',
              icon: 'dynamicText',
              onAction: function () {
                toggleState = !toggleState;
                editor.insertContent(
                  '<img id="salutation_text" style="pointer-events: none; margin-bottom: -6px;" contenteditable="false" src="https://cdn.policymogul.com/images/salutation_small.svg" alt="salutation">'
                );
              },
            });
            if (signatureEditor) {
              editor.ui.registry.addButton('insertImageBtn', {
                type: 'button',
                text: ``,
                tooltip: '',
                icon: 'insertImageIcon',
                onAction: function (api) {
                  let uploadImageInput = document.querySelector('#upload-image');
                  if (uploadImageInput) {
                    uploadImageInput.click();
                  }
                },
                onSetup: function (api) {
                  let uploadImageInput = document.querySelector('#upload-image');
                  if (uploadImageInput) {
                    uploadImageInput.addEventListener('change', (e) => {
                      uploadImageHandler(e.target.files[0], editor);
                    });
                  }
                },
              });
            }
            editor.ui.registry.addToggleButton('signatureBtn', {
              type: 'button',
              text: ``,
              tooltip: '',
              icon: 'signature',
              onAction: function (api) {
                let state = editor.getBody().querySelector('#email_signature');
                setSignatureBtnDataTip(!state, api);
                handleSignatureClick();
              },
              onSetup: function (api) {
                let state = !editor.getBody().querySelector('#nosignature');
                setSignatureBtnDataTip(state, api);
              },
            });

            editor.ui.registry.addButton('insertContentBtn', {
              type: 'button',
              text: 'Insert content',
              tooltip: '',
              icon: 'insertContent',
              onAction: function () {
                toggleState = !toggleState;
                const currBookmark = editor.selection.bookmarkManager.getBookmark();
                setBookmark(currBookmark);
                setShowInsertContentModal(!showInsertContentModal);
              },
            });
            editor.ui.registry.addToggleButton('customBold', {
              icon: 'bold',
              onAction: (api) => {
                editor.execCommand('mceToggleFormat', false, 'bold');
              },
              onSetup: function (api) {
                editor.formatter.formatChanged('bold', function (state) {
                  api.setActive(state);
                });
              },
            });
            editor.ui.registry.addToggleButton('customItalic', {
              icon: 'italic',
              onAction: (api) => {
                editor.execCommand('mceToggleFormat', false, 'italic');
              },
              onSetup: function (api) {
                editor.formatter.formatChanged('italic', function (state) {
                  api.setActive(state);
                });
              },
            });
            editor.ui.registry.addToggleButton('customLink', {
              icon: 'link',
              text: 'Insert trackable link',
              onAction: (api) => {
                editor.execCommand('mceLink', false, 'link');
              },
              onSetup: function (api) {
                editor.formatter.formatChanged('link', function (state) {
                  api.setActive(state);
                });
              },
            });
            editor.on('KeyUp', (e) => insertListTinyEditor(e, editor));
          },
        }}
        onEditorChange={onChange}
      />
    </>
  );
}
