import React from 'react';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import SelectOfHiddenItems from '../../Settings/Team/selectOfHiddenItems';
import { useAdminAgencyUser } from './UserSideNav';
import SelectClientsItemsAdmin from './AdminUserPage/SelectClientsItemsAdmin';
import ModalComponent from '../../Common/Modal';

const CreateANewTeamPopUp = (props) => {
  const {
    createTeamFields,
    teamFunctions,
    isOpenAddATeamPopup,
    setIsOpenAddATeamPopup,
    showSuccessMessage,
    setShowSuccessMessage,
    updateField,
    setCreateTeamFields,
    initialFields,
    userPage,
    temporaryPassword,
    localKeywordList,
    setLocalKeywordList,
    localClientsState,
  } = props;

  const { localClients, setLocalClients } = localClientsState ?? {};

  const { transformTopicOrClientWord } = useTopicOrClientWord();
  const { agencyUser, newAgencyPlan } = useAdminAgencyUser();

  const { createFunction, copyCredentials } = teamFunctions;

  const createMarkup = (body) => {
    return { __html: body };
  };

  const closeModal = () => {
    setIsOpenAddATeamPopup(false);
    setShowSuccessMessage(false);
    setCreateTeamFields(initialFields);
    if (setLocalKeywordList) {
      setLocalKeywordList(localKeywordList?.map((item) => ({ ...item, myState: 'Enabled' })));
    }
    if (setLocalClients) {
      setLocalClients(localClients?.map((item) => ({ ...item, myState: 'Enabled' })));
    }
  };

  const selectHiddenItemsProps = {
    newMember: createTeamFields.find((field) => field.name === 'teamRole')?.value === 'Member',
    newAdminMember: createTeamFields.find((field) => field.name === 'teamRole')?.value === 'Admin',
    adminPage: true,
  };

  return (
    <ModalComponent isOpen={isOpenAddATeamPopup} setIsOpen={closeModal}>
      <h3 className='topic-title mb-4 section-title pl-lg-5 pl-4 py-3'>Add a new {userPage ? 'user' : 'team'}</h3>
      <div className='px-lg-5 px-4'>
        {showSuccessMessage && (
          <div className='text-center create-user-success'>
            <img src={`${process.env.REACT_APP_CDNURL}/images/circle-tick.svg`} alt='success tick' />
            <p className='title-h4'>User {userPage ? '' : 'and team'} successfully created</p>
            <div className='py-3 mb-4'>
              <p className='main-subtle-text'>
                Email: <strong>{createTeamFields.find((field) => field.name === 'email').value}</strong>
              </p>
              <p className='main-subtle-text'>
                Temporary password: <strong>{temporaryPassword}</strong>
              </p>
            </div>
          </div>
        )}
        {!showSuccessMessage && (
          <div className='pb-3 topic-email-alerts-content row'>
            {createTeamFields.map((item, index) => {
              const { type, value, fieldName, name, options, required, placeholder, errorMessage } = item;
              if (fieldName !== null) {
                return (
                  <div key={`item-${name}`} className='col-lg-8  mb-3'>
                    <label htmlFor={`item-${name}`}>
                      {fieldName}
                      {required && <span className='text-red'>*</span>}
                    </label>
                    {type === 'text' && (
                      <>
                        <input
                          id={`item-${name}`}
                          className='form-control main-input'
                          type='text'
                          value={value}
                          required={required}
                          onChange={(e) => {
                            let newValue = e.target.value;
                            updateField(name, newValue);
                          }}
                        />
                      </>
                    )}
                    {type === 'select' && (
                      <>
                        <select
                          className='form-control main-input'
                          id={`item-${name}`}
                          onChange={(e) => {
                            let newValue = e.target.value;
                            updateField(name, newValue);
                          }}
                          value={value}
                        >
                          {placeholder && (
                            <option value='' hidden>
                              {placeholder}
                            </option>
                          )}
                          {options.map((option, indexOption) => (
                            <option value={option?.value ?? option?.name} key={`index-${indexOption}`}>
                              {option?.name}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    {errorMessage && (
                      <div className='mt-2 general-error-message'>
                        <p className='mb-0 message py-2 px-3' dangerouslySetInnerHTML={createMarkup(errorMessage)} />
                      </div>
                    )}
                  </div>
                );
              } else {
                return null;
              }
            })}
            {userPage && (
              <div key={`item-SelectOfHiddenItems`} className='col-lg-8  mb-3'>
                <label htmlFor={`item-SelectOfHiddenItems`}>
                  {agencyUser || newAgencyPlan ? (
                    <>Clients</>
                  ) : (
                    <>
                      {transformTopicOrClientWord({
                        plural: true,
                        uppercase: true,
                      })}
                    </>
                  )}
                </label>
                {!!localKeywordList.length && (
                  <>
                    {newAgencyPlan ? (
                      <SelectClientsItemsAdmin {...selectHiddenItemsProps} localClientsState={localClientsState} />
                    ) : (
                      <SelectOfHiddenItems
                        {...selectHiddenItemsProps}
                        localKeywordList={localKeywordList}
                        setLocalKeywordList={setLocalKeywordList}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
        <div className='d-flex justify-content-end pb-2'>
          <button
            className={`general-button action-button no-select-button px-3 py-2`}
            onClick={(e) => {
              if (showSuccessMessage) {
                copyCredentials();
              } else {
                createFunction(e);
              }
            }}
          >
            {showSuccessMessage ? 'Copy temporary password' : `Add a new ${userPage ? 'user' : 'team'}`}
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

export default CreateANewTeamPopUp;
