import React, { useEffect, useRef, useState } from 'react';
import inviteUser from './inviteUser';
import axios from 'axios';
import createNotification from '../Utilities/CreateNotification';
import ReactGA from 'react-ga4';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';
import useGetAccessToken from '../../Dashboard/apiCalls/useGetAccessToken';
import ModalComponent from '../../Common/Modal';
// import SelectOfHiddenItems from "./selectOfHiddenItems";
// import { store } from "../../Store";
// import { useContext } from "react";
// import useHiddenItemsFunctions from "../../Dashboard/hooks/useHiddenItemsFunctions";

const InviteUsersPopUp = (props) => {
  const { team, setTeam, setShowPopUpInvites, showPopUpInvites } = props;

  useEffect(() => {
    if (showPopUpInvites) {
      setTimeout(() => {
        elements.current.inputEmail0.focus();
      }, 50);
    }
  }, [showPopUpInvites]);

  // const { updateHiddenItemState } = useHiddenItemsFunctions();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = [
    {
      email: '',
      role: 'Member',
    },
    {
      email: '',
      role: 'Member',
    },
    {
      email: '',
      role: 'Member',
    },
  ];
  const [emailItems, setEmailItems] = useState(initialValues);
  // const [localKeywordList, setLocalKeywordList] = useState({});
  // const { getAllKeywordLists } = useHiddenItemsFunctions();

  const elements = useRef([]);
  const { getAccessToken } = useGetAccessToken();

  /* useEffect(() => {    
    const asyncFn = async () => {
      const items = await getAllKeywordLists(team.teamId);
        if (items?.length) {
          let preparedItems = items.map((list) => ({
            ...list,
            myState: "Enabled",
          }));
          let initialLists = {};
          for (let i = 0; i < 3; i++) {
            initialLists[i] = preparedItems;
          }
          setLocalKeywordList(initialLists);
        }
      }
      asyncFn();
    }, []); */

  useEffect(() => {
    return () => {
      source.cancel('Invites canceled by the user.');
    };
  }, [source]);

  const createGoogleAnalyticsEvent = () => {
    ReactGA.event({
      category: 'Team Membership',
      action: 'Invite team member',
      label: team.teamId,
    });
  };

  const sendInvitations = async (e) => {
    const { form } = elements.current;
    if (form.checkValidity()) {
      e.preventDefault();
      setIsLoading(true);
      for (let i = 0; i < emailItems.length; i++) {
        if (emailItems[i].email.includes('@')) {
          let invite = await inviteUser(getAccessToken, source, {
            email: emailItems[i].email,
            role: emailItems[i].role,
          });
          if (invite !== undefined) {
            let response = JSON.parse(invite);
            createGoogleAnalyticsEvent();
            if (response.success) {
              // let userId = response?.team?.openInvitations?.find(
              //   (item) => item.email === emailItems[i].email
              // )?.id;
              // let updateHiddenItemStateAllRes = await Promise.all(
              //   localKeywordList[i]
              //     .filter((item) => item.myState !== "Enabled")
              //     .map(
              //       async (item) =>
              //         await updateHiddenItemState({
              //           state: item.myState,
              //           userId,
              //           listId: item.id,
              //           showSuccsessMessage: false,
              //         })
              //     )
              // );
              // if (updateHiddenItemStateAllRes) {
              createNotification('success', `Invitation sent to ${emailItems[i].email}`);
              if (team) {
                setTeam(response.team);
              }
              // }
            } else {
              createNotification('danger', `${response.message}`);
            }
          }
        }
      }
      closeModal();
      setIsLoading(false);
      setEmailItems(initialValues);
    }
  };

  const closeModal = () => {
    setShowPopUpInvites(false);
  };

  const RoleSelect = (props) => {
    const roleElement = useRef(null);
    const [showRole, setShowRole] = useState(false);
    const hideRole = () => {
      setShowRole(false);
    };
    const { role, position } = props;
    useOutsideElement(roleElement, hideRole);

    const setNewRole = (value) => {
      let newItems = emailItems.slice();
      newItems[position].role = value;
      setEmailItems(newItems);
    };
    return (
      <div className='position-relative' ref={roleElement}>
        <p
          className={`mb-0 hour-select px-3 ${showRole ? 'hour-select-opendropdown' : ''} team-select`}
          onClick={() => {
            setShowRole(!showRole);
          }}
        >
          {role === 'Member' ? 'Team member' : role}
        </p>

        {showRole && (
          <>
            <div className='frequency-dropdown text-left team-role-dropdown'>
              <p
                className={`mb-0 frequency-item px-3 py-2 ${role === 'Member' ? 'frequency-item-selected' : ''}`}
                onClick={() => {
                  setNewRole('Member');
                }}
              >
                Team member
              </p>
              <p
                className={`mb-0 frequency-item px-3 py-2 ${role === 'Admin' ? 'frequency-item-selected' : ''}`}
                onClick={() => {
                  setNewRole('Admin');
                }}
              >
                Admin
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  const createInviteItems = () => {
    let invites = [];
    for (let i = 0; i < 3; i++) {
      invites.push(
        <div className='row mx-0 mb-lg-3 pt-2 team-popup-invitation' key={`invite${i}`}>
          <div className='col-md-10 px-0 pr-md-1 mb-2 mb-md-0 '>
            {/* <div className="col-md-7 px-0 pr-md-1 mb-2 mb-md-0 "> */}
            <input
              ref={(input) => (elements.current[`inputEmail${i}`] = input)}
              value={emailItems[i].email}
              onChange={(e) => {
                let newItems = emailItems.slice();
                newItems[i].email = e.target.value;
                setEmailItems(newItems);
              }}
              className='form-control'
              type='email'
              placeholder={`Email address ${i !== 0 ? '(optional)' : ''}`}
              required={i === 0}
            />
          </div>
          <div className='col-md-6 px-0 pl-md-1'>
            {/* <div className="col-md-4 px-0 pl-md-2 mb-2 mb-md-0"> */}
            <RoleSelect role={emailItems[i].role} position={i} />
          </div>
          {/* <div className="col-md-5 px-0 pl-md-2">
            {localKeywordList[i]?.length && (
              <SelectOfHiddenItems
                localKeywordList={localKeywordList[i]}
                setLocalKeywordList={(val) =>
                  setLocalKeywordList({ ...localKeywordList, [i]: val })
                }
                newMember
              />
            )}
          </div> */}
        </div>
      );
    }
    return invites;
  };

  return (
    <ModalComponent maxWidth={880} isOpen={showPopUpInvites} setIsOpen={closeModal}>
      <div className='create-email-popup invite-user-popup pt-0'>
        <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 delete-message team-form'>
          <h3 className='my-1'>Who’s on your team?</h3>
          <p className='mb-4 team-popup-subtitle'>
            <em>
              Invite up to three colleagues at a time.{' '}
              <a
                href='https://support.policymogul.com/en/articles/4555367-inviting-your-team'
                target='_blank'
                rel='noopener noreferrer'
              >
                Learn about user types
              </a>
            </em>
          </p>

          <form ref={(form) => (elements.current['form'] = form)}>
            <div className='px-2'>{createInviteItems()}</div>
            <div className='d-flex justify-content-end pb-2'>
              <button
                className='btn btn-general mb-4 mt-2 mt-sm-3 action-button px-4 py-1'
                onClick={(e) => {
                  sendInvitations(e);
                }}
              >
                {isLoading ? <i className='fas fa-spinner fa-spin'></i> : 'Send invites'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </ModalComponent>
  );
};

export default InviteUsersPopUp;
