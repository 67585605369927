import React, { useState } from 'react';
import dayjs from 'dayjs';
import DayPicker from 'react-day-picker';
import ModalComponent from '../../../Common/Modal';
const CalendarExpirtyDate = (props) => {
  const { setShowCalendarPicker, visibilityOptions, showCalendarPicker } = props;
  const { date } = visibilityOptions;

  const closeModal = () => {
    setShowCalendarPicker(false);
  };

  const [selectedDay, setSelectedDay] = useState(new Date());

  const handleDayClick = (day, modifiers = {}) => {
    setSelectedDay(day);
    date.setValue({
      name: dayjs(day).format('DD MMM[,] YYYY'),
      value: dayjs(day).utc().startOf('day').format().replace('Z', ''),
      selected: true,
    });
    closeModal();
  };

  return (
    <ModalComponent
      style={{ position: 'absolute', top: '29%' }}
      maxWidth={260}
      isOpen={showCalendarPicker}
      setIsOpen={closeModal}
    >
      <div className='modal-calendar'>
        <div className={`calendar-picker tweets-calendar`}>
          <DayPicker
            month={new Date()}
            className='Selectable'
            selectedDays={selectedDay}
            modifiers={{}}
            onDayClick={handleDayClick}
            firstDayOfWeek={1}
            showOutsideDays
            weekdaysShort={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default CalendarExpirtyDate;
