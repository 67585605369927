import { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { store } from '../../Store';
import getUrlParam from '../utilities/getUrlParam';
import { useParams } from 'react-router-dom';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter';
import { useFilters } from '../Filters/FiltersContext';
import LocalStorage from '../../../utils/LocalStorage';

const useResetReference = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { search } = state;

  const navigate = useNavigate();
  const location = useLocation();
  const { clearFilters } = useFilters();
  const params = useParams();
  const contactParamId = getUrlParam('contact-id');
  const { searchResultsSection, referenceSection } = useMainSectionParameter(params);

  const resetReference = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'search',
      value: {
        ...search,
        list: null,
        activeSearch: false,
        query: '',
      },
    });
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'referenceState',
      value: {
        activeReference: false,
        keywords: [],
        andKeywords: [],
        keywordExclusions: [],
      },
    });
    clearFilters();
    LocalStorage.removeItem('contentFilters');
    dispatch({ type: 'MODIFY_SECTION', parameter: 'totalNumbers', value: {} });
    dispatch({ type: 'MODIFY_SECTION', parameter: 'clearValueSimpleSearchBar', value: true });
    if (searchResultsSection || referenceSection) {
      navigate(`${location.pathname}${contactParamId ? `&contact-id=${contactParamId}` : ''}`);
    }
  };

  return { resetReference };
};

export default useResetReference;
