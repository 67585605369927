import { useEffect, useRef, useState } from 'react';
import { useGroupFunctions } from './GroupFunctions';
import createNotification from '../../../Settings/Utilities/CreateNotification';
import SelectFilterComponent from './GroupSelectFilterComponent';
import { isVisibleVertically, smoothScrollTo } from './GroupScrollFunctions';

const GroupFilters = (props) => {
  const { filtersState, scrollBarPosition, scrollbarRef } = props;
  const { filters, setFilters } = filtersState ?? {};
  const filtersGroupButton = useRef();
  const [isDeleting, setIsDeleting] = useState(false);
  const [groupAction, setGroupAction] = useState(null);

  return (
    <>
      {filters.length > 0 && (
        <>
          {filters.map((item, index) => {
            return (
              <SingleGroupFilter
                filtersState={filtersState}
                item={item}
                index={index}
                key={`filter-group-${index}`}
                scrollBarPosition={scrollBarPosition}
                deletingState={{ isDeleting, setIsDeleting }}
                groupActionState={{ groupAction, setGroupAction }}
                scrollbarRef={scrollbarRef}
              />
            );
          })}
        </>
      )}
      <button
        ref={filtersGroupButton}
        className='general-button border rounded bg-blue-hovered py-2 mb-1 w-100 groups-add-filter-group-button'
        onClick={() => {
          setFilters([...filters, { groupFilters: [] }]);
          setGroupAction('adding');
        }}
      >
        + Add a filter group
      </button>
    </>
  );
};

const SingleGroupFilter = (props) => {
  const { index, filtersState, item, scrollBarPosition, deletingState, scrollbarRef, groupActionState } = props;
  const { filters, setFilters, setFiltersNumber } = filtersState ?? {};
  const { groupAction, setGroupAction } = groupActionState ?? {};
  const { isDeleting, setIsDeleting } = deletingState ?? {};
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const { groupFilters } = item ?? {};
  const { previewGroup, createDynamicQueries } = useGroupFunctions();

  const groupContainer = useRef();

  const noFiltersSet = filters ? createDynamicQueries(filters)?.flatMap((item) => item.filters)?.length === 0 : false;

  useEffect(() => {
    if (
      !isVisibleVertically({ groupRef: groupContainer, scrollbarRef }) &&
      !!scrollbarRef &&
      index === filters?.length - 1 &&
      (groupAction === 'cloning' || groupAction === 'adding')
    ) {
      smoothScrollTo(scrollbarRef, groupContainer.current?.offsetTop - 16);
    }
  }, [index, filters, scrollbarRef?.current, groupAction]);

  return (
    <>
      <div className='bg-blue-hovered rounded p-3 border mb-4 group-filter-item' ref={groupContainer}>
        <div className='flex-centered justify-content-between'>
          <p className='mb-0 font-weight-bold paragraph filter-group-title'>Filter group {index + 1}</p>
          {filtersState && (
            <div className='flex-centered '>
              <button
                className='general-button mr-3'
                data-tooltip-id={'general-tooltip'}
                data-tooltip-content={'Clone filter group'}
                onClick={() => {
                  setGroupAction('cloning');
                  let newFilters = [...filters];
                  newFilters.push(item);
                  setFilters(newFilters);
                  createNotification('success', 'Filter group cloned');
                }}
              >
                <span className='icon-copy main-light-text' />
              </button>

              <button
                className='general-button'
                data-tooltip-id={'general-tooltip'}
                data-tooltip-content={'Delete filter group'}
                onClick={async (e) => {
                  e.currentTarget.blur();
                  if (!isDeleting) {
                    try {
                      setGroupAction('deleting');
                      let newFilters = [...filters];
                      newFilters.splice(index, 1);
                      if (newFilters?.length === 0) {
                        createNotification('danger', 'You must have at least 1 filter group');
                      } else {
                        if (noFiltersSet) {
                          setFilters(newFilters);
                        } else {
                          setIsLoadingDelete(true);
                          setIsDeleting(true);
                          let results = await previewGroup({ filters: newFilters });
                          if (!!results) {
                            setFiltersNumber(results?.totalHits);
                            setFilters(newFilters);
                            setIsDeleting(false);
                            setIsLoadingDelete(false);
                          }
                        }
                        createNotification('success', 'Filter group deleted');
                      }
                    } catch (error) {}
                  }
                }}
              >
                {isLoadingDelete ? (
                  <i className='fas fa-spinner fa-spin'></i>
                ) : (
                  <span className='icon-delete main-light-text' />
                )}
              </button>
            </div>
          )}
        </div>
        <div className='rounded bg-main-white pb-3 border mt-3 group-single-container'>
          {groupFilters?.length > 0 && (
            <>
              {groupFilters?.map((item, position) => {
                let { expandedFilter } = item;
                return (
                  <FilterItem
                    key={`item-specific-${index}-${position}`}
                    item={item}
                    groupPosition={index}
                    index={position}
                    filtersState={filtersState}
                    groupFilters={groupFilters}
                    scrollBarPosition={scrollBarPosition}
                    deletingState={deletingState}
                    expandedFilter={expandedFilter}
                  />
                );
              })}
            </>
          )}
          {filtersState && (
            <SelectFilterComponent
              addingState={groupFilters?.length === 0}
              filtersState={filtersState}
              groupPosition={index}
              scrollBarPosition={scrollBarPosition}
              groupFilters={groupFilters}
              scrollbarRef={scrollbarRef}
            />
          )}
          {groupFilters?.length === 0 && (
            <>
              {/* AE: When disabled */}
              <div className='py-3'>
                <p className='paragraph-p5 filter-item-and-text px-3'>
                  <span className='py-1 px-3 font-weight-bold text-uppercase rounded border light-50-text bg-main-white'>
                    and
                  </span>
                </p>
                <div className='px-3 pt-3'>
                  <button
                    className='border rounded py-1 px-3 general-button bg-grey-50 main-light-text'
                    disabled={true}
                  >
                    + Add filter
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className='my-3 text-center position-relative group-separator'>
        <hr className='position-absolute border-top top-0 w-100' />
        <span className='text-uppercase font-weight-bold bg-main-white py-1 px-3 d-inline-block z-10 position-relative or-separator'>
          or
        </span>
      </div>
    </>
  );
};

const FilterItem = (props) => {
  const { item, groupPosition, filtersState, groupFilters, index, scrollBarPosition, deletingState, expandedFilter } =
    props;
  const { filters, setFilters, setFiltersNumber } = filtersState ?? {};
  const { category, operator, value, topicDate, sentimentTopic } = item;
  const { isDeleting, setIsDeleting } = deletingState ?? {};

  const [editState, setEditState] = useState(expandedFilter);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const formatter = new Intl.ListFormat('en-GB', { type: 'disjunction' });
  const { previewGroup } = useGroupFunctions();

  const modifyItem = async (item) => {
    try {
      let newFilters = [...filters];
      let newGroupFilters = [...groupFilters];
      newGroupFilters[index] = { ...item };
      newFilters[groupPosition] = { ...newFilters[groupPosition], groupFilters: newGroupFilters };

      let results = await previewGroup({ filters: newFilters });
      if (!!results) {
        let preparedFilters = newFilters?.map((filter) => ({
          ...filter,
          groupFilters: filter?.groupFilters.map((groupFilter) => ({ ...groupFilter, isSaved: true })),
        }));
        setFiltersNumber(results?.totalHits);
        setFilters(preparedFilters);
        setEditState(false);
      }
    } catch (error) {}
  };

  const deleteFilter = async () => {
    try {
      if (!isLoadingDelete && !isDeleting) {
        setIsLoadingDelete(true);
        setIsDeleting(true);
        let newFilters = [...filters];
        let newGroupFilters = [...groupFilters];
        newGroupFilters.splice(index, 1);
        newFilters[groupPosition] = { ...newFilters[groupPosition], groupFilters: newGroupFilters };
        if (groupFilters.length !== 1) {
          let results = await previewGroup({ filters: newFilters });
          if (!!results) {
            setFiltersNumber(results?.totalHits);
          }
        } else {
          setFiltersNumber(0);
        }
        setFilters(newFilters);
        setIsDeleting(false);
        setIsLoadingDelete(false);
      }
    } catch (error) {}
  };
  return (
    <div className='group-single-filter-item'>
      {editState ? (
        <div className='pb-3'>
          <SelectFilterComponent
            item={item}
            modifyItem={modifyItem}
            addingState={true}
            scrollBarPosition={scrollBarPosition}
          />
        </div>
      ) : (
        <>
          {/* AE: When on list */}
          {index > 0 && (
            <p className='paragraph-p5 filter-item-and-text px-3 light-70-text'>
              <span className='py-1 px-3 font-weight-bold text-uppercase rounded border bg-light-blue-hovered'>
                and
              </span>
            </p>
          )}
          <div className='px-3 py-2 flex-centered justify-content-between '>
            <p className='mb-0 filter-item-single-text'>
              {category?.type === 'selectSentiment' && (
                <>
                  <span className='font-weight-bold'>{Array.isArray(value) ? <>{value[0]?.name}</> : value?.name}</span>{' '}
                  about <span className='font-weight-bold'>{sentimentTopic?.name}</span>
                </>
              )}
              {category?.type === 'picker' && (
                <>
                  <span className='font-weight-bold'>Vocal</span> about{' '}
                  <span className='font-weight-bold'>
                    {Array.isArray(value) ? <>{formatter.format(value?.map((item) => item.name))}</> : value?.name}{' '}
                  </span>
                  in the last {topicDate?.name}
                </>
              )}
              {category?.type !== 'picker' && category?.type !== 'selectSentiment' && (
                <>
                  <span className='font-weight-bold'>{category?.name}</span> {operator?.name}{' '}
                  <span className='font-weight-bold'>
                    {Array.isArray(value) ? <>{formatter.format(value?.map((item) => item.name))}</> : value?.name}
                  </span>
                </>
              )}
            </p>
            {filtersState && (
              <div className='flex-centered'>
                <span
                  className='pointer icon-edit-pencil-thick mr-3 paragraph-p2'
                  data-tooltip-id={'general-tooltip'}
                  data-tooltip-content={'Edit filter'}
                  onClick={() => {
                    setEditState(true);
                  }}
                />
                {isLoadingDelete ? (
                  <i className='fas fa-spinner fa-spin'></i>
                ) : (
                  <span
                    data-tooltip-id={'general-tooltip'}
                    data-tooltip-content={'Delete filter'}
                    onClick={deleteFilter}
                    className='pointer icon-delete paragraph-p3'
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export { SingleGroupFilter };
export default GroupFilters;
