const MembershipOfAPPGsFilters = [
  {
    name: 'APPGs',
    pages: ['InfluenceContacts', 'Stakeholders'],
    type: 'membership',
    subtype: 'MembershipOfAPPGs',
    searchState: 'SeneddOrHolyroodCondition',
    filterName: 'allPartyGroups',
    options: [],
    prefix: 'appg',
    optionSelected: '',
  },
];

export default MembershipOfAPPGsFilters;
