import React, { useEffect, useState, useContext, useRef } from 'react';
import SavedItemsComponent from './ui';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Login/Loading';
import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';
import { store } from '../Store';
import createNotification from '../Settings/Utilities/CreateNotification';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSaveItemsPageFunctions } from './SaveItemPopup';
import axios from 'axios';

const SavedItems = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { userSavedItemsFolders, saveItemPopup, currentFolderSavedItems } = state;
  const { savedItems, isLoadingFolder, isLoading } = saveItemPopup ?? {};
  const [filtersResources, setFiltersResources] = useState([]);

  const { generalApiCall } = useGeneralApiCall();
  const { changeSaveItemPopup, savedItemsSearchDescriptor, loadFolders, loadFolderItems } = useSaveItemsPageFunctions();

  const setSavedItems = (val) => {
    changeSaveItemPopup('savedItems', val);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const folderParam = getUrlParam('folder-id');

  const initialActions = useRef();
  const sourceRef = useRef(axios.CancelToken.source());

  initialActions.current = async (source) => {
    try {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'saveItemPopup',
        value: {
          ...saveItemPopup,
          isLoadingFolder: true,
          isLoading: true,
        },
      });
      await loadFolders(source);
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    initialActions.current(source);
    return () => {
      source.cancel('Saved items cancelled by the user');
    };
  }, []);

  const updateFolderForParameter = useRef();
  updateFolderForParameter.current = (source) => {
    try {
      let position = userSavedItemsFolders.findIndex((item) => item.id === parseInt(folderParam));
      if (position >= 0) {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'currentFolderSavedItems',
          value: userSavedItemsFolders[position !== undefined ? position : 0],
        });
        setFiltersResources([]);
        loadFolderItems(parseInt(folderParam), false, source);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    updateFolderForParameter.current(source);
    return () => {
      source.cancel('Saved items canceled by the user.');
    };
  }, [folderParam, userSavedItemsFolders]);

  //CREATE A FOLDER
  const createEditAFolder = async (props) => {
    const { e, name, id } = props;
    let el = e.target;
    el.disabled = true;
    let pathname = `/api/saved-items/${id ? 'update' : 'create'}-saved-item-folder`;
    let method = 'post';
    let requestProperties = {
      name,
    };
    if (id) {
      requestProperties = { ...requestProperties, id };
    }
    let results = await generalApiCall({ pathname, method, needsAuthentication: true, requestProperties });
    if (results) {
      let newFolders = [...userSavedItemsFolders];
      if (id) {
        let position = newFolders.findIndex((item) => item.id === id);
        newFolders[position].name = name;
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: newFolders });
      } else {
        newFolders = [...newFolders, results];
        dispatch({ type: 'MODIFY_SECTION', parameter: 'currentFolderSavedItems', value: results });
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: newFolders });
        await loadFolderItems(results.id);
      }
      createNotification('success', `Folder ${id ? 'edited' : 'created'} successfully`);
      return true;
    }
    el.disabled = false;
    return false;
  };

  const changeFolder = (folder) => {
    sourceRef.current.cancel('Mark item canceled by the user');
    navigate(`${location.pathname}${savedItemsSearchDescriptor(folder.id)}`);
  };

  const removeFromFolder = async (e, id) => {
    let el = e.target;
    el.disabled = true;
    let pathname = `/api/saved-items/saved-item/${id}`;
    let method = 'delete';
    let results = await generalApiCall({ pathname, method, needsAuthentication: true, returnCompleteRequest: true });
    if (results.status === 200) {
      let newItems = savedItems.slice();
      let position = newItems.findIndex((item) => item.id === id);
      newItems.splice(position, 1);
      setSavedItems(newItems);
      //update count number
      let newFolders = [...userSavedItemsFolders];
      let folderPosition = newFolders.findIndex((item) => item.id === currentFolderSavedItems.id);
      newFolders[folderPosition].itemCount -= 1;
      dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: newFolders });
      createNotification('success', `Removed from ${currentFolderSavedItems.name}`);
    } else {
      el.disabled = false;
    }
  };

  const deleteAFolder = async (e, id) => {
    if (userSavedItemsFolders.length > 1) {
      let el = e.target;
      el.disabled = true;
      let pathname = `/api/saved-items/saved-item-folder/${id}`;
      let method = 'delete';
      let results = await generalApiCall({ pathname, method, needsAuthentication: true, returnCompleteRequest: true });
      if (results.status === 200) {
        let newFolders = [...userSavedItemsFolders];
        let position = newFolders.findIndex((item) => item.id === id);
        newFolders.splice(position, 1);
        dispatch({ type: 'MODIFY_SECTION', parameter: 'userSavedItemsFolders', value: newFolders });
        let newCurrentFolder = newFolders[newFolders.length - 1];
        dispatch({ type: 'MODIFY_SECTION', parameter: 'currentFolderSavedItems', value: newCurrentFolder });
        loadFolderItems(newCurrentFolder.id);
        createNotification('success', 'Folder deleted successfully');
        return true;
      } else {
        el.disabled = false;
      }
    } else {
      createNotification('danger', 'You must have at least 1 folder');
    }
  };

  const finalHits = () => {
    return savedItems.map((item) => {
      let itemContent = { ...item.content };
      if (item.contentType === 'UserContent') {
        itemContent.link = `${itemContent.contentUrl}`;
        itemContent.organisation = itemContent[`creatorOrganisation`];
        itemContent.organisationWebsite = itemContent[`creatorOrganisationWebsite`];
        itemContent.imageUrl = itemContent[`creatorOrganisationLogo`];
      }
      return {
        ...itemContent,
        savedItemid: item.id,
      };
    });
  };

  return (
    <SavedItemsComponent
      isLoading={isLoading}
      folders={userSavedItemsFolders}
      createEditAFolder={createEditAFolder}
      currentFolder={currentFolderSavedItems}
      isLoadingFolder={isLoadingFolder}
      savedItems={savedItems}
      changeFolder={changeFolder}
      removeFromFolder={removeFromFolder}
      deleteAFolder={deleteAFolder}
      loadFolderItems={loadFolderItems}
      filtersResources={filtersResources}
      setFiltersResources={setFiltersResources}
      finalHits={finalHits}
    />
  );
};

export default withAuthenticationRequired(SavedItems, {
  onRedirecting: () => <Loading />,
});
