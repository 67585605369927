import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { store } from '../../Store';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord';
import DropdownComponent from '../../Settings/WebsiteFeeds/DropdownComponent';
import InputError from './InputError';
import useOrganizationInfoDescriptionFunctions from '../hooks/useOrganizationInfoDescriptionFunctions';
import dayjs from 'dayjs';
import createNotification from '../../Settings/Utilities/CreateNotification';
import parliamentarianUsers from '../sidebar/parliamentarianUsers';
import getUrlParam from '../utilities/getUrlParam';
import WebsiteSettingsTopics from '../../Settings/WebsiteFeeds/WebsiteSettingsTopics';
import ModalComponent from '../../Common/Modal';

var timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);

const ContentPopup = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, team, keywordsLists: stateKeywordsLists } = state;
  const { code, teamName } = activePlan;

  const { setShowPopup, selectedTopic: selectedTopicProp, keywordsLists, setTriggerLoadSummary } = props;

  const [selectedTopic, setSelectedTopic] = useState(selectedTopicProp);
  const [inputError, setInputError] = useState(null);
  const [action, setAction] = useState(null);
  const textAreaRef = useRef();

  const isParliamentarian = parliamentarianUsers(code);

  const [loadingUpdateDescription, setLoadingUpdateDescription] = useState(false);
  const [loadingInitialDescription, setLoadingInitialDescription] = useState(false);
  const [initialDescription, setInitialDescription] = useState(null);
  const [currentDescription, setCurrentDescription] = useState(null);

  const {
    removeAndUpdateOrganizationDescription,
    getClientDescription,
    removeOrganizationDescription,
    updateOrganizationInfoDescription,
  } = useOrganizationInfoDescriptionFunctions();

  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();

  const descriptionIsChanged =
    currentDescription !== initialDescription?.organisationDescription &&
    !(currentDescription === '' && initialDescription === null);

  const topicParam = parseFloat(getUrlParam('topic-id'));

  let alreadyHasDescription = initialDescription?.lastModified;

  const closeOnActionCondition = useMemo(
    () => (agencyUser && selectedTopic.id === topicParam) || !agencyUser,
    [keywordsLists, selectedTopic.id, topicParam]
  );

  const handleClick = async (action) => {
    let toggleHasClientOrganisationDescription = (id, val) => {
      let keywordsListsToState = stateKeywordsLists.map((item) => {
        if (item?.id === id) {
          return { ...item, hasClientOrganisationDescription: val };
        } else {
          return item;
        }
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'keywordsLists',
        value: keywordsListsToState,
      });
      setSelectedTopic({
        ...selectedTopic,
        hasClientOrganisationDescription: val,
      });
    };
    let text = currentDescription;
    let removeAction = async () => {
      setLoadingUpdateDescription(true);
      const response = await removeOrganizationDescription({
        value: text,
        keywordListId: agencyUser ? selectedTopic?.id : undefined,
      });
      setLoadingUpdateDescription(false);
      if (response) {
        createNotification('success', 'Description has been removed');
        if (agencyUser) {
          toggleHasClientOrganisationDescription(selectedTopic?.id, null);
        }
        setAction(null);
        if (closeOnActionCondition) {
          setTriggerLoadSummary(true);
          setShowPopup(false);
        }
      }
      return;
    };
    setAction(action);
    if (text?.length === 0) {
      removeAction();
      return;
    }
    setLoadingUpdateDescription(true);
    let response;
    if (alreadyHasDescription) {
      response = await removeAndUpdateOrganizationDescription({
        value: text,
        keywordListId: agencyUser ? selectedTopic?.id : undefined,
      });
    } else {
      response = await updateOrganizationInfoDescription({
        value: text,
        keywordListId: agencyUser ? selectedTopic?.id : undefined,
      });
    }

    setLoadingUpdateDescription(false);
    if (response) {
      createNotification(
        'success',
        `Description ${initialDescription?.authorFullName && initialDescription?.lastModified ? 'updated' : 'saved'}`
      );
      if (agencyUser) {
        toggleHasClientOrganisationDescription(selectedTopic?.id, true);
      }
      setAction(null);
      if (closeOnActionCondition) {
        setTriggerLoadSummary(true);
        setShowPopup(false);
      }
    }
  };

  let forCondition = useMemo(
    () => (agencyUser && keywordsLists?.length) || (!agencyUser && teamName?.length),
    [teamName, agencyUser, keywordsLists]
  );

  const getInitialDescription = useRef();
  getInitialDescription.current = async () => {
    const setTextAreaValue = (val) => {
      if (textAreaRef?.current) {
        setCurrentDescription(val);
        if (agencyUser) {
          setTimeout(() => {
            textAreaRef?.current.focus();
          }, 10);
        } else {
          textAreaRef?.current.focus();
        }
      }
    };
    if (agencyUser) {
      setLoadingInitialDescription(true);
      const descriptionRes = await getClientDescription({
        keywordListId: selectedTopic?.id,
      });
      if (descriptionRes?.organisationDescription) {
        setTextAreaValue(descriptionRes?.organisationDescription);
        setInitialDescription({
          authorFullName: descriptionRes.authorFullName,
          lastModified: descriptionRes.lastModified,
          organisationDescription: descriptionRes?.organisationDescription,
        });
      } else {
        setTextAreaValue('');
        setInitialDescription(null);
      }
      setLoadingInitialDescription(false);
    } else {
      setTextAreaValue(team?.organisationDescription ?? '');
      setInitialDescription({
        authorFullName: team.organisationDescriptionAuthorFullName,
        lastModified: team.organisationDescriptionLastModified,
        organisationDescription: team?.organisationDescription,
      });
    }
  };

  useEffect(() => {
    getInitialDescription.current();
    setInputError(null);
  }, [selectedTopic]);

  return (
    <div className={`tailor-summaries-modal ${agencyUser && keywordsLists.length >= 2 ? 'many-clients' : ''}`}>
      <p className='popup-header px-3 px-lg-5 flex-centered'>
        <span>
          Tailor your summaries {forCondition ? 'for' : ''}{' '}
          <span>{!agencyUser && teamName?.length ? teamName : ''}</span>
        </span>
        {agencyUser && (
          <div className='tailor-summaries-dropdown-component'>
            <DropdownComponent
              mainItem={() => <span className='hidden-lines hidden-one-line'>{selectedTopic?.name}</span>}
              hideArrowDisabled={keywordsLists.length === 1}
            >
              <WebsiteSettingsTopics
                selectedTopic={selectedTopic}
                setSelectedTopic={setSelectedTopic}
                agencyUser
                topicsVariant
                hideButtons
                keywordsListsToUseProp={keywordsLists}
                hideAddTopic
              />
            </DropdownComponent>
          </div>
        )}
      </p>

      <div className='separator' />
      <div className='popup-body p-3 px-lg-5 pb-lg-2'>
        <div>
          <p>
            Enter as much information as possible about {isParliamentarian ? '' : 'your'}{' '}
            {agencyUser || newAgencyPlan
              ? 'client and their work'
              : `${isParliamentarian ? '' : 'organisation and your'} work`}
            . This text will be saved in order to identify relevance of updates across the platform. You can edit this
            text at any time.
          </p>
          <div className='position-relative'>
            <textarea
              ref={textAreaRef}
              value={currentDescription}
              rows={4}
              className='input-empty w-100 border border-grey rounded-sm p-3'
              style={{ resize: 'none' }}
              onChange={(e) => {
                setCurrentDescription(e.target.value);
                setInputError(null);
              }}
              disabled={loadingInitialDescription}
            ></textarea>
          </div>
          <div
            className={`tailor-summary-action-container flex-centered justify-content-${
              inputError || initialDescription?.lastModified ? 'between' : 'end'
            }  mt-2`}
          >
            {inputError ? (
              <InputError errText={inputError} />
            ) : (
              <>
                {initialDescription?.lastModified && (
                  <p className='mb-0 pt-3 last-edited-by-subtext'>
                    <i>
                      Last edited by {initialDescription?.authorFullName || 'PolicyMogul'} on{' '}
                      {dayjs(initialDescription?.lastModified).tz('Europe/London', true).format('DD MMMM, YYYY')}
                    </i>
                  </p>
                )}
              </>
            )}

            <div className='flex-centered pt-1'>
              <button
                className={`action-button general-button px-3 filter-action-button update-button ${
                  agencyUser ? 'wide' : ''
                }`}
                onClick={() => {
                  handleClick('show');
                }}
                disabled={loadingUpdateDescription || loadingInitialDescription || !descriptionIsChanged}
              >
                {loadingUpdateDescription && action === 'show' && <i className='fas fa-spinner fa-spin mx-2' />}
                <span>
                  {initialDescription?.lastModified ? 'Update' : 'Save'} description
                  {agencyUser ? ` for ${selectedTopic.name}` : ''}
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className='mb-2 flex-centered justify-content-end'>
          <button className='general-button delete-button px-4 mr-3 py-1' onClick={() => setShowPopup(false)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const TailorSummaryModal = (props) => {
  return (
    <ModalComponent maxWidth={850} isOpen={props.showPopup} setIsOpen={props.setShowPopup}>
      <ContentPopup {...props} />
    </ModalComponent>
  );
};

export default TailorSummaryModal;
