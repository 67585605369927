import React, { useContext, useRef, useState } from 'react';
import { store } from '../../Store.js';
import { useAuth0 } from '@auth0/auth0-react';
import useIsMobile from '../utilities/useIsMobile.js';
import AddTopicButton from '../Navigation/AddTopicButton.js';
import useOutsideHoverElement from '../hooks/useOutsideHoverElement.js';
import createUtmParameters from '../helpers/createUtmParameters.js';
import axios from 'axios';
import generalApiCall from '../apiCalls/generalApiCall.js';
import { loadKeyWords } from '../utilities/loadKeyWords.js';
import TransformTopicsList from './TransformTopicsList.js';
import useSearchBarUiFunctions, { useChangeKeywordsListsOrder } from '../hooks/useSearchBarUiFunctions.js';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter.js';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord.js';
import { Link, useLocation, useParams } from 'react-router-dom';
import useGetAccessToken from '../apiCalls/useGetAccessToken.js';

const NavigationKeywordsLists = (props) => {
  const { keywordsLists, showDropdownContent, inputSearch, maxHeightContainer } = props;
  const isMobile = useIsMobile();

  const keywordListsElement = useRef(null);
  const scrollContainerElement = useRef(null);
  const topicsListElement = useRef(null);

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({ keywordsLists });
  const { newAgencyPlan } = useTopicOrClientWord();
  const { startMoveLists, listPosition, setStartMoveLists, setListPosition, scrollList } = useSearchBarUiFunctions({
    inputSearch,
    keywordsLists,
    keywordListsElement,
    scrollContainerElement,
    topicsListElement,
    showDropdownContent,
  });

  const { isAuthenticated } = useAuth0();

  const finalKeywordsLists = keywordsLists !== null ? changeKeywordsListsOrder() : [];
  const unselectTopic = () => {
    setStartMoveLists(false);
  };
  useOutsideHoverElement(topicsListElement, unselectTopic);

  const agencyPlanCondition = newAgencyPlan && keywordsLists?.length > 0;
  return (
    <>
      {isAuthenticated && (
        <div
          ref={topicsListElement}
          onKeyDown={(e) => {
            scrollList(e);
          }}
        >
          <div
            className='scroll-container hover-container search-bar-lists'
            ref={scrollContainerElement}
            style={{ maxHeight: `${maxHeightContainer}px` }}
          >
            <ul className={`policy-list px-0 mb-0 hover-content`} ref={keywordListsElement}>
              {finalKeywordsLists.map((item, index) => (
                <NavigationKeywordList
                  {...props}
                  key={`keywordlist${item.id === null ? 'master-topic' : item.id}-${index}`}
                  listPosition={listPosition}
                  setListPosition={setListPosition}
                  startMoveLists={startMoveLists}
                  setStartMoveLists={setStartMoveLists}
                  item={item}
                  index={index}
                  scrollContainerElement={scrollContainerElement}
                  finalKeywordsLists={finalKeywordsLists}
                  agencyPlanCondition={agencyPlanCondition}
                />
              ))}
            </ul>
          </div>
          {!agencyPlanCondition && (
            <AddTopicButton keywordsLists={keywordsLists} setStartMoveLists={setStartMoveLists} />
          )}
        </div>
      )}
      {!isAuthenticated && (
        <>
          <button
            className='general-button add-keyword-list-button'
            onClick={() => {
              window.location.assign(`/register?reg_source=searchbar-add-topic${createUtmParameters('&')}`);
            }}
          >
            <span className='icon-square-plus' /> Create a topic
            {!isMobile && (
              <span
                className='pricing-feature-more create-topic-more-feature'
                data-tooltip-id='general-tooltip'
                data-tooltip-html={
                  'Topics allow you to save searches<br/> for multiple keywords, and to enable<br/> tailored email alerts'
                }
                data-tooltip-place={'right'}
              >
                More information
              </span>
            )}
          </button>
        </>
      )}
    </>
  );
};

const NavigationKeywordList = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { team, search, keywordsLists: stateKeywordLists, activePlan } = state;
  const { list } = search;
  const { myself } = team;
  const isMobile = useIsMobile();
  const location = useLocation();
  const params = useParams();
  const {
    listPosition,
    startMoveLists,
    index,
    setFilteringKeywordLists,
    item,
    searchFunction,
    setStartMoveLists,
    setListPosition,
    scrollContainerElement,
    finalKeywordsLists,
    agencyPlanCondition,
  } = props;
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const { getAccessToken } = useGetAccessToken();

  const { referenceSection } = useMainSectionParameter(params);

  let hoverHighlightedPosition = index;
  let masterTopic = item.id === null;

  const applySearch = () => {
    searchFunction(item);
    dispatch({ type: 'MODIFY_SECTION', parameter: 'unseenNumbersCurrentList', value: {} });
    if (setFilteringKeywordLists) {
      setFilteringKeywordLists(
        state.keywordsLists.map((item) => {
          return { ...item, show: true };
        })
      );
    }
    scrollContainerElement.current.scrollTop = 0;
  };

  const setTopicAsDefault = async () => {
    applySearch();
    const token = await getAccessToken();
    let pathname = '/api/user/set-default-keyword-list';
    let method = 'post';
    let requestProperties = {
      keywordListId: item.id,
    };
    let requestHeaders = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token,
    };
    let returnCompleteRequest = true;
    let results = await generalApiCall({ pathname, method, requestProperties, requestHeaders, returnCompleteRequest });
    if (results.status === 200) {
      let keywordsListsResults = await loadKeyWords(getAccessToken, source);
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'keywordsLists',
        value: TransformTopicsList(keywordsListsResults, activePlan, item.id),
      });
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'activePlan',
        value: { ...activePlan, defaultKeywordListId: item.id },
      });
    }
  };

  const notShowListsFunctions =
    location.pathname.includes('/stakeholder-mapping') ||
    location.pathname.includes('/policymakers') ||
    referenceSection;

  const showConditional = () => {
    if (masterTopic) {
      return notShowListsFunctions ? false : item.show;
    } else {
      return item.show;
    }
  };

  const { transformTopicOrClientWord, agencyUser } = useTopicOrClientWord();

  return (
    <div
      className={`${startMoveLists && listPosition === hoverHighlightedPosition ? 'dropdown-keywordlist-highlighted' : ''} ${masterTopic ? 'dropdown-keywordlist-master ' : ''} ${item.defaultView ? ' border-bottom mb-0' : 'keywordlist-common-view'} `}
      style={{ display: showConditional() ? 'block' : 'none' }}
      onClick={() => {
        applySearch();
      }}
      onMouseOver={() => {
        if (!isMobile) {
          if (!startMoveLists) {
            setStartMoveLists(true);
          }
          setListPosition(hoverHighlightedPosition);
        }
      }}
    >
      {stateKeywordLists.length > 1 && item.defaultView && !notShowListsFunctions && (
        <p className='main-disabled-text text-uppercase paragraph-p6 mb-0 default-view-title font-weight-bold'>
          Your default view
        </p>
      )}
      <li
        className={`dropdown-keywordlist dropdown-keywordlist-with-actions d-flex ${item.description ? 'dropdown-keywordlist-description' : ''}${agencyPlanCondition && index === finalKeywordsLists?.length - 1 ? ' pb-3' : ''}`}
      >
        <span className={`icon-topics-${masterTopic ? 'master' : 'thin'}`} />
        <div className='flex-grow-1 pr-4'>
          <p className={'hidden-lines hidden-one-line dropdown-name'}> {item.name}</p>
          {masterTopic && (
            <p className='dropdown-description'>
              Shows political developments {agencyUser ? 'relevant' : 'related'} to all your{' '}
              {transformTopicOrClientWord({ plural: true })}
            </p>
          )}
          {item.description && <p className='dropdown-description description-field'>{item.description}</p>}
          {list?.id === item.id && <span className='icon-tick topic-selected-mark' />}
        </div>
        <div className='dropdown-list-actions'>
          {!item.defaultView && stateKeywordLists.length > 1 && !notShowListsFunctions && (
            <TopicDefaultComponent setTopicAsDefault={setTopicAsDefault} team={team} />
          )}
          {(myself?.role === 'Admin' || myself?.role === 'Owner' || item?.isMyList) && item.id !== null && (
            <Link
              to={{
                pathname: `/settings/${transformTopicOrClientWord({ plural: true })}/${item.id}`,
                search: `returnUrl=${encodeURIComponent(location.pathname)}`,
              }}
              state={{ prevPath: location.pathname }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <EditTopicComponent />
            </Link>
          )}
        </div>
      </li>
    </div>
  );
};

const TopicDefaultComponent = (props) => {
  const { setTopicAsDefault, team } = props;
  const { newAgencyPlan } = useTopicOrClientWord();
  const element = useRef();
  const content = useRef();
  const { top, left } = element?.current?.getBoundingClientRect() ?? {};
  const { height, width } = content?.current?.getBoundingClientRect() ?? {};
  const [isOpen, setIsOpen] = useState(false);

  return (
    <button
      className='general-button default-view-button flex-centered'
      ref={element}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setTopicAsDefault();
      }}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <span className='icon-default' />
      <span
        ref={content}
        className='header-tooltip d-block paragraph-p3'
        style={{
          position: 'fixed',
          top: `${top - (height + 5)}px`,
          left: `${left - width / 2}px`,
          opacity: isOpen ? 1 : 0,
          visibility: isOpen ? 'visible' : 'hidden',
        }}
      >
        Make this your default view {newAgencyPlan ? `for ${team?.teamName}` : ''}
      </span>
    </button>
  );
};

const EditTopicComponent = (props) => {
  const element = useRef();
  const content = useRef();
  const { top, left } = element?.current?.getBoundingClientRect() ?? {};
  const { height, width } = content?.current?.getBoundingClientRect() ?? {};
  const [isOpen, setIsOpen] = useState(false);
  const { topicOrClientWord, agencyUser } = useTopicOrClientWord();
  return (
    <>
      <button
        className='general-button dropdown-edit-keyword-list flex-centered'
        ref={element}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <span className='icon-edit-pencil' />
        <span
          ref={content}
          className='header-tooltip d-block paragraph-p3'
          style={{
            position: 'fixed',
            top: `${top - (height + 5)}px`,
            left: `${left - width / 2}px`,
            opacity: isOpen ? 1 : 0,
            visibility: isOpen ? 'visible' : 'hidden',
          }}
        >
          Edit this {topicOrClientWord} {agencyUser ? "'s keywords" : ''}
        </span>
      </button>
    </>
  );
};

export default NavigationKeywordsLists;
