import { Portal } from 'react-portal';
import { AddTagsContent } from '../../../Influence/CrmContacts/AddTagsModal/AddTagsModal';
import useAddTagsToStakeholder from '../useAddTagsToStakeholder';
import useContactsFunctionForStakeholders from '../useContactsFunctionForStakeholder';
import useValidateContactsOversubscribed from '../../../utilities/useValidateContactsOversubscribed';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';
import { useContext } from 'react';
import { store } from '../../../../Store';
import ModalComponent from '../../../../Common/Modal';

const ContentPopup = (props) => {
  const { setShowPopup, StakeholderInformation, customContact } = props;
  const { tags, setTags, isLoading, addTagsToStakeholder } = useAddTagsToStakeholder({ customContact });

  return (
    <div className='pt-4'>
      <AddTagsContent
        titleText={StakeholderInformation?.officalName ?? StakeholderInformation?.title}
        tags={tags}
        setTags={setTags}
        isLoading={isLoading}
        addTagsToContacts={addTagsToStakeholder}
        closeModal={() => setShowPopup(false)}
        contactsCount={1}
        autofocus
        searchIcon
      />
    </div>
  );
};

const StakeholderTagsPopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <Portal>
      <ModalComponent isOpen={showPopUp} setIsOpen={setShowPopup}>
        <ContentPopup {...props} />
      </ModalComponent>
    </Portal>
  );
};

const ContentStakeholderTagsAddContactPopup = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const { StakeholderInformation, setShowPopup } = props;
  const { addOrDeleteContact } = useContactsFunctionForStakeholders();
  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  const { newAgencyPlan } = useTopicOrClientWord();

  return (
    <div className='pt-5 pb-4 text-center'>
      <p className='pt-2'>Add this contact to your contact list to tag them</p>
      <button
        onClick={async (e) => {
          await validateContactsOversubscribed(
            async () =>
              await addOrDeleteContact({
                e,
                contactId: StakeholderInformation?.id,
                callback: () => setShowPopup(false),
              }),
            'add_contact',
            1
          );
          setShowPopup(false);
        }}
        className='general-button action-button general-spacing-button px-4 mb-3'
      >
        {newAgencyPlan ? `Add to contact list for ${team?.teamName}` : 'Add to my contacts'}
      </button>
    </div>
  );
};

const StakeholderTagsAddContactPopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <Portal>
      <ModalComponent maxWidth={450} isOpen={showPopUp} setIsOpen={setShowPopup}>
        <ContentStakeholderTagsAddContactPopup {...props} />
      </ModalComponent>
    </Portal>
  );
};
export { StakeholderTagsAddContactPopup };
export default StakeholderTagsPopup;
