import React, { useState } from 'react';
import ModalComponent from '../../../Common/Modal';

const DeleteInfluenceItemPopUp = (props) => {
  const { deleteFunction, type, setShowDeletePopUp, showDeletePopUp } = props;
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  return (
    <ModalComponent maxWidth={500} isOpen={showDeletePopUp} setIsOpen={setShowDeletePopUp}>
      <div className='py-4 delete-keywordcategory-modal'>
        <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2'>
          <div className='text-center'>
            <p>
              Are you sure you want to delete this {type === 'policy-asks' ? 'policy ask' : type.replace('-', ' ')}?
            </p>

            <div className='my-4'>
              <button
                className='general-button delete-button px-4 mr-3 py-1'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeletePopUp(false);
                }}
              >
                Cancel
              </button>
              <button
                className='general-button danger-button px-4 py-2'
                disabled={isLoadingButton}
                onClick={async () => {
                  setIsLoadingButton(true);
                  deleteFunction();
                }}
              >
                {`Delete ${type === 'policy-asks' ? 'policy ask' : type.replace('-', ' ')}`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default DeleteInfluenceItemPopUp;
