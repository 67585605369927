import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { store } from '../../../../Store';
import axios from 'axios';
import CrmContactItem from './CrmContactItem/CrmContactItem';
import CrmContactsTableSkeleton, { CrmContactItemSkeleton } from './CrmContactsSkeleton';
import { useCallback } from 'react';
import { preventHistoryBack } from '../../../helpers/preventHistoryBack';
import useGetSectionFilters from '../../../Filters/useGetSectionFilters';
import EmptyMessage from '../../../components/EmptyMessage';
import isDummyTableItem from '../../../helpers/isDummyTableItem';
import { loadMoreTableRowsDependOnScreen } from '../../../helpers/loadMoreTableRowsDependOnScreen';
import addMetaTags from '../../../utilities/addMetaTags';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TableComponent from '../../tableComponents/TableComponent';
import useSelectCrmContacts from './useSelectCrmContacts';
import useGeneralApiCall from '../../../apiCalls/useGeneralApiCall';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';
import NoContactsGroupEmptyMessage from './NoContactsGroupEmptyMessage';
import WaitingAnimationScreen from '../../../StakeHolders/WaitingAnimationScreen';
import { useFilters } from '../../../Filters/FiltersContext';

const CrmContactsTable = ({
  selectedCrmContacts,
  setSelectedCrmContacts,
  setTotalHits,
  isLoadingState,
  setShowAddTagsModal,
}) => {
  const { isLoading, setIsLoading } = isLoadingState ?? {};
  const params = useParams();
  const { groupId } = params;

  const columns = [
    {
      accessorKey: 'checkbox',
      header: '',
      ref: useRef(),
      type: 'checkbox',
    },
    {
      accessorKey: 'lastName',
      header: 'Name',
      ref: useRef(),
    },
    {
      accessorKey: 'championStatus',
      header: 'Champion status',
      ref: useRef(),
    },
    {
      accessorKey: 'role',
      header: 'Role',
      ref: useRef(),
    },
    {
      accessorKey: 'description',
      header: 'Description',
      ref: useRef(),
    },
    {
      accessorKey: 'email',
      header: 'Email',
      ref: useRef(),
    },
    {
      accessorKey: 'tags',
      header: 'Tags',
      ref: useRef(),
    },
    /* {
      accessorKey: 'createdAtUtc',
      header: `Date added`,
      ref: useRef(),
    }, */
    {
      accessorKey: 'salutation',
      header: 'Salutation',
      ref: useRef(),
    },
  ];

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    contentResults,
    activeResults,
    addedCustomContacts,
    crmContactsTableOnSort,
    removedContactsQntty,
    group,
    contactOnGroupUpdated,
    team,
  } = state;

  const { getSectionFilters } = useGetSectionFilters('InfluenceContacts');
  const { contentFilters } = useFilters();

  const sectionFilters = getSectionFilters();
  const { hash, filters } = sectionFilters;

  const { CrmContacts } = contentResults[activeResults];
  const [currentlyProcessing, setCurrentlyProcessing] = useState({
    attempt: 0,
    state: false,
  });
  const [scrollTopContainer, setScrollTopContainer] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });
  const [activeIndex, setActiveIndex] = useState(null);
  const [pageSize, setPageSize] = useState(11);
  const [scrollPositionX, setScrollPositionX] = useState(crmContactsTableOnSort?.scrollPositionX);
  const [pageIsLoading, setPageIsLoading] = useState(null);
  const rowRef = useRef();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const location = useLocation();
  const navigate = useNavigate();
  const scrollCondition = CrmContacts && CrmContacts.scrollPosition;

  const query = new URLSearchParams(location.search);
  const sort = query.get('sort') ? query.get('sort').split('_') : null;
  const searchQuery = query.get('search') ? decodeURIComponent(query.get('search')) : '';

  const { selectedIds, selectedAll, selectedAllVisible } = selectedCrmContacts;
  const { generalApiCall } = useGeneralApiCall();
  const { newAgencyPlan } = useTopicOrClientWord();

  const initialCall = useRef();
  initialCall.current = (source) => {
    setCurrentlyProcessing({ attempt: 0, state: false });
    const loadResults = async () => {
      try {
        await createResults({ source });
      } catch (error) {
        console.error(error);
      }
    };
    if (!scrollCondition) {
      loadResults();
    } else {
      setIsLoading(false);
      //AE: Necessary to have a setTimeOut to update the scrollPosition and allow continue using the list correctly. (Without this if you clear the search or change the topic it will not work.)
      setTimeout(() => {
        let CrmContactsWithoutScroll = {
          ...CrmContacts,
          scrollPosition: null,
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: {
            ...contentResults,
            [activeResults]: {
              CrmContacts: CrmContactsWithoutScroll,
            },
          },
        });
      }, 300);
    }
  };
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setScrollTopContainer({
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
    });
    initialCall.current(source);

    return () => {
      source.cancel('CrmContacts canceled by the user.');
    };
  }, [
    hash,
    searchQuery,
    contentFilters,
    addedCustomContacts.length,
    removedContactsQntty,
    crmContactsTableOnSort,
    group,
    contactOnGroupUpdated,
    team,
  ]);

  useEffect(() => {
    if (!isLoading && scrollPositionX) {
      scrollBarRef.current.scrollLeft = scrollPositionX;
    }
  }, [isLoading, CrmContacts]);

  async function createResults({ pageNumber, processing, source: sourceProp }) {
    if (pageNumber) {
      setPageIsLoading(pageNumber);
    }
    try {
      if (!pageNumber && !processing) {
        setIsLoading(true);
      }
      const pathname = `/api/crm-contact/${groupId ? 'preview-saved-crm-group' : 'query'}`;
      let requestProperties = {
        pageSize: pageSize,
        pageNumber: pageNumber ?? 1,
      };
      if (groupId) {
        requestProperties = {
          ...requestProperties,
          id: parseInt(groupId),
        };
      } else {
        requestProperties = {
          ...requestProperties,
          filters: filters,
          query: searchQuery,
        };
      }

      if (sort) {
        if (groupId) {
          requestProperties = {
            ...requestProperties,
            sorts: [{ field: sort[0], order: sort[1] }],
          };
        } else {
          requestProperties = {
            ...requestProperties,
            sort: { field: sort[0], order: sort[1] },
          };
        }
      }

      let results = await generalApiCall({
        pathname,
        method: 'post',
        requestProperties,
        needsAuthentication: true,
        requestSource: sourceProp ?? source,
        notShowErrorMessage: true,
      });

      if (!!results) {
        let contactsIds = pageNumber ? CrmContacts.contactsIds : [];
        let hitsResults = pageNumber ? CrmContacts.hits : [];

        if (results.hits !== null) {
          contactsIds = contactsIds.concat(results.contactsIds);
          hitsResults = hitsResults.concat(results.hits);
        }
        let currentId = results.hits !== null && results.hits.length > 0 ? results.hits[0].id : 0;
        let ResultsToState = {
          ...results,
          currentId: currentId,
          contactsIds: contactsIds,
          currentPositionId: contactsIds.indexOf(currentId),
          hits: hitsResults,
          thereIsMoreContent: results.hits !== null ? results.hits.length >= pageSize : false,
          readyCrmContactsFilters: filters?.length > 0 || !!searchQuery,
        };

        let CrmContactsResults = {
          ...contentResults,
          [activeResults]: {
            ...contentResults[activeResults],
            CrmContacts: ResultsToState,
          },
        };
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'contentResults',
          value: CrmContactsResults,
        });

        setTotalHits(ResultsToState?.totalHits);
        setCurrentlyProcessing({ attempt: currentlyProcessing.attempt + 1, state: results?.crmRefreshInProgress });
        setIsLoading(false);
        setPageIsLoading(null);
      }
    } catch (error) {}
  }

  //SET FILTERS TO URL
  useEffect(() => {
    if (crmContactsTableOnSort?.sort?.length) {
      query.set('sort', crmContactsTableOnSort?.sort);
    }
    let urlToHistory = `${location.pathname}?${query.toString()}${hash !== '' ? `#${hash}` : ''}`;
    if (!groupId) {
      let title = `Directory - Influence - PolicyMogul`;
      addMetaTags({ title, hash, location, dispatch });
    }
    setTimeout(() => {
      navigate(urlToHistory, { replace: true });
    }, 0);
  }, [hash, crmContactsTableOnSort]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const callAgainResults = () => {
      setTimeout(async () => {
        try {
          await createResults({ processing: true, source });
        } catch (error) {
          console.error(error);
        }
      }, 5000);
    };
    const { state } = currentlyProcessing;
    if (state === true) {
      callAgainResults();
    }
    return () => {
      if (currentlyProcessing.state && !!source) {
        source.cancel('New call canceled by the user.');
      }
    };
  }, [currentlyProcessing]);

  //LOAD ONSCROLL
  const load_more_results = async () => {
    if (CrmContacts !== undefined) {
      let { thereIsMoreContent, pageNumber } = CrmContacts;
      if (thereIsMoreContent) {
        await createResults({ pageNumber: pageNumber + 1 });
      }
    }
  };

  const scrollBarRef = useRef(null);
  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    setScrollTopContainer({ scrollTop, scrollHeight, clientHeight });
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom && pageIsLoading === null) {
      setScrollPositionX(0);
      load_more_results();
    }
  };

  const isDummyItem = useCallback((props) => isDummyTableItem(props), [scrollTopContainer]);

  let hitsToRender = CrmContacts?.hits ?? [];

  const { handleCheck } = useSelectCrmContacts();

  const contactIds = CrmContacts?.hits.map((item) => item.id);
  const isSelected = selectedIds.length > 0 || selectedAll || selectedAllVisible.length > 0;
  //PREVENT HISTORY BACK ON TRACKPAD SCROLL
  useEffect(() => {
    if (scrollBarRef.current) {
      scrollBarRef.current.addEventListener('mousewheel', (e) => preventHistoryBack(e, scrollBarRef.current));
      loadMoreTableRowsDependOnScreen({
        containerHeight: scrollBarRef.current.clientHeight,
        cellHeight: 62,
        headerHeight: 50,
        setPageSize,
        pageSize,
      });
    }
  }, [scrollBarRef.current]);

  useEffect(() => {
    if (pageSize > 11) {
      load_more_results();
    }
  }, [pageSize]);

  return (
    <div className={`row mx-0 h-100 ${!isLoading && !CrmContacts?.totalHits ? 'pr-md-4 pr-3' : ''}`}>
      <div className='h-100 w-100' style={isLoading ? { overflow: 'hidden' } : null}>
        {isLoading ? (
          <CrmContactsTableSkeleton />
        ) : (
          <>
            {!CrmContacts?.totalHits && !currentlyProcessing?.state ? (
              <>
                <div className='pt-2'>
                  <div className='contact-list-scrollbar-table-no-data h-100'>
                    {!query.get('search') && !filters.length ? (
                      <>
                        {groupId ? (
                          <NoContactsGroupEmptyMessage />
                        ) : (
                          <div
                            className='crm-table-no-contacts w-100'
                            style={{
                              paddingLeft: 0,
                              paddingRight: 0,
                              paddingTop: '52px',
                              gap: '5px',
                              backgroundColor: 'rgba(0, 148, 204, 0.04)',
                            }}
                          >
                            <p className='crm-table-no-contacts-title pt-2'>
                              Your contact list {newAgencyPlan ? ` for ${team?.teamName}` : ''} is empty
                            </p>
                            <p className='crm-table-no-contacts-description'>
                              Once you or your teammates add contacts, you’ll be able to add them to email campaigns and
                              take other actions
                            </p>
                            <div className='no-contacts-actions-container w-100'>
                              <div className='w-100 h-100'>
                                <img
                                  className='w-100 rounded-top pt-4'
                                  src={`${process.env.REACT_APP_CDNURL}/images/no-contacts-img.jpg`}
                                  alt='No contacts'
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className='w-100'>
                        <EmptyMessage contactsTable emptyMessageResourceName={'contacts'} />
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {currentlyProcessing.state ? (
                  <WaitingAnimationScreen waitingMessage={'your contacts'} />
                ) : (
                  <div className='contact-list-scrollbar-table-wrapper'>
                    <div className='stakeholder-list-content-scrollbar' />
                    <div className='contact-list-scrollbar-table' onScroll={handleScroll} ref={scrollBarRef}>
                      {hitsToRender.length > 0 && (
                        <TableComponent
                          setScrollPositionX={setScrollPositionX}
                          scrollBarRef={scrollBarRef}
                          columns={columns}
                          additionalClass={'crm-table-contacts'}
                          selectedState={{ selectedAll, isSelected, handleCheck }}
                          ascAbrev={'asc'}
                          descAbrev={'desc'}
                          activeIndexState={{ activeIndex, setActiveIndex }}
                          contactsTable={true}
                          stateProperty={'crmContactsTableOnSort'}
                        >
                          {hitsToRender.map((item, index, array) => {
                            return (
                              <CrmContactItem
                                ref={index === 1 ? rowRef : undefined}
                                array={array}
                                item={item}
                                key={`item${item.contactId}-${index}`}
                                index={index}
                                setSelectedCrmContacts={setSelectedCrmContacts}
                                selectedCrmContacts={selectedCrmContacts}
                                contactIds={contactIds}
                                hitsToRender={hitsToRender}
                                dummyItem={
                                  CrmContacts.totalHits > 50
                                    ? isDummyItem({
                                        index,
                                        itemHeight: 67,
                                        cachedInvisibleItems: 1,
                                        scrollTopContainer,
                                        pageSize,
                                      })
                                    : false
                                }
                                setShowAddTagsModal={setShowAddTagsModal}
                                scrollBarRef={scrollBarRef}
                                activeIndex={activeIndex}
                              />
                            );
                          })}
                          {CrmContacts?.thereIsMoreContent && <CrmContactItemSkeleton />}
                        </TableComponent>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CrmContactsTable;
