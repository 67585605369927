import { useContext } from 'react';
import { store } from '../../../Store';
import RegionFilters from '../../Filters/FiltersData/RegionFilters';
import { PartyOptions, PositionOptions, RoleOptions } from '../../Filters/FiltersData/StakeholdersFilters';
import { ChampionStatusOptions } from '../tableComponents/ChampionStatus';
import { useChangeKeywordsListsOrder } from '../../hooks/useSearchBarUiFunctions';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
const useGroupFiltersData = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists } = state;
  const { newAgencyPlan, agencyUser } = useTopicOrClientWord();
  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({
    keywordsLists: keywordsLists?.filter((item) => item?.id !== null),
  });

  const GroupsFiltersData = {
    categories: [
      {
        name: 'Vocal about...',
        filterName: 'topicMatchedKeywordListIds',
        subtext: `(e.g ${changeKeywordsListsOrder()?.[0]?.name ?? 'immigration'})`,
        type: 'picker',
        placeholder: 'Select a topic',
      },
      {
        name: 'Role',
        filterName: 'categories',
        options: RoleOptions,
        type: 'select',
        subtext: '(e.g. MPs, peers, special advisers)',
        placeholder: 'Select roles',
      },
      {
        name: 'Position',
        filterName: 'positionCategories',
        options: PositionOptions,
        type: 'select',
        subtext: '(e.g. Cabinet, Shadow Cabinet)',
        placeholder: 'Select positions',
      },
      {
        name: 'Nation',
        filterName: 'categories',
        type: 'select',
        subtext: '(e.g. UK, Wales, Scotland)',
        placeholder: 'Select nations',
        options: [
          {
            name: 'Westminster',
            filterValue: ['MP', 'Lord', 'Special Adviser'],
            labelClassName: 'label-country-sort uk-flag',
          },
          {
            name: 'Senedd',
            filterValue: 'MS',
            labelClassName: 'label-country-sort label-wales',
          },
          {
            name: 'Holyrood',
            filterValue: 'MSP',
            labelClassName: 'label-country-sort label-scotland',
          },
        ],
      },
      {
        name: 'Party',
        options: PartyOptions,
        filterName: 'party',
        type: 'select',
        subtext: '(e.g. Labour, Conservative)',
        placeholder: 'Select parties',
      },
      {
        name: 'Champion status',
        filterName: 'championStatus',
        options: ChampionStatusOptions?.filter((item) => item?.name !== 'No status'),
        type: 'select',
        subtext: '(e.g. promoter, detractor)',
        placeholder: 'Select champion status',
      },
      {
        name: 'Sentiment',
        filterName: 'topicMatchedKeywordListIds',
        subtext: `(e.g positive about ${changeKeywordsListsOrder()?.[0]?.name ?? 'immigration'})`,
        type: 'selectSentiment',
        placeholder: 'Select a topic',
        options: [{ name: 'Positive' }, { name: 'Negative' }, { name: 'Mixed' }, { name: 'Neutral' }],
      },
      {
        name: 'Tags',
        type: 'tags',
        filterName: 'tags',
      },
      {
        name: 'Region',
        filterName: 'region',
        options: RegionFilters?.[0]?.options,
        type: 'select',
        placeholder: 'Select regions',
      },
      {
        name: 'Select Committee',
        type: 'membership',
        subtype: 'MembershipOfSelectCommittees',
        filterName: 'selectCommittees',
      },
      {
        name: 'Lords Select Committee',
        type: 'membership',
        subtype: 'MembershipOfLordsSelectCommittees',
        filterName: 'lordsSelectCommittees',
      },
      {
        name: 'APPG',
        type: 'membership',
        subtype: 'MembershipOfAPPGs',
        filterName: 'allPartyGroups',
      },
    ],
    operators: [
      /* { name: 'is equal to', value: 'str_eq', type: 'single' },
      { name: 'is not equal to', value: 'str_not_eq', type: 'single' }, */
      {
        name: 'is equal to any of',
        format: () => <>is equal to any of</>,
        value: 'str_eq',
        type: 'multiselect',
      },
      {
        name: 'is not equal to any of',
        format: () => <>is not equal to any of</>,
        value: 'str_not_eq',
        type: 'multiselect',
      },
      {
        name: 'is known',
        format: () => <>is known</>,
        value: 'exists',
        type: 'not_value_filter',
      },
      {
        name: 'is not known',
        format: () => <>is not known</>,
        value: 'not_exists',
        type: 'not_value_filter',
      },
    ],
    topicDates: [
      {
        name: '365 days',
        filterValue: '12mo',
      },
      {
        name: '90 days',
        filterValue: '3mo',
      },
      {
        name: '30 days',
        filterValue: '1mo',
      },
      /* {
        name: '7 days',
        filterValue: '1w',
      }, */
    ],
  };

  const getPropByName = (key, value) => {
    return GroupsFiltersData[key].find((item) => item.name === value);
  };

  const MPsFilter = {
    category: getPropByName('categories', 'Role'),
    operator: getPropByName('operators', 'is equal to any of'),
    sentimentTopic: null,
    value: [
      {
        name: 'MPs',
        filterValue: 'MP',
        hash: 'mps',
      },
    ],
  };

  const NationFilter = {
    category: getPropByName('categories', 'Nation'),
    operator: getPropByName('operators', 'is equal to any of'),
    sentimentTopic: null,
    topicDate: getPropByName('topicDates', '365 days'),
    value: [getPropByName('categories', 'Nation').options[0]],
    expandedFilter: true,
  };

  const groupsPreselectedFiltersData = [
    {
      label: 'All MPs',
      plainDescription: 'Shows all MPs in the current Parliament',
      description: (
        <span>
          Shows all MPs in the <span className='nowrap-item'>current Parliament</span>
        </span>
      ),
      filters: [MPsFilter],
    },
    {
      label: 'Vocal about...',
      description: 'Shows policymakers who are vocal about any topic',
      filters: [
        {
          category: getPropByName('categories', 'Vocal about...'),
          operator: getPropByName('operators', 'is equal to any of'),
          sentimentTopic: null,
          topicDate: getPropByName('topicDates', '365 days'),
          expandedFilter: true,
        },
        NationFilter,
      ],
    },
    {
      label: `${agencyUser || newAgencyPlan ? 'Client' : 'Our'} champions`,
      description: `Shows all policymakers you have manually tagged as ‘Promoters’ ${agencyUser || newAgencyPlan ? 'for this client' : ''}`,
      type: 'champions',
      filters: [
        {
          category: getPropByName('categories', 'Champion status'),
          operator: getPropByName('operators', 'is equal to any of'),
          sentimentTopic: null,
          value: {
            name: 'Promoter',
            color: '#d9ead3',
          },
        },
      ],
    },
    {
      label: 'Positive about...',
      description: 'Shows policymakers who have positive sentiment in relation to any topic',
      filters: [
        {
          category: getPropByName('categories', 'Sentiment'),
          operator: getPropByName('operators', 'is equal to any of'),
          topicDate: getPropByName('topicDates', '365 days'),
          value: {
            name: 'Positive',
          },
          expandedFilter: true,
        },
        NationFilter,
      ],
    },
    {
      label: 'Negative about...',
      description: 'Shows policymakers who have negative sentiment in relation to any topic',
      filters: [
        {
          category: getPropByName('categories', 'Sentiment'),
          operator: getPropByName('operators', 'is equal to any of'),
          topicDate: getPropByName('topicDates', '365 days'),
          value: {
            name: 'Negative',
          },
          expandedFilter: true,
        },
        NationFilter,
      ],
    },
    {
      label: 'Labour MPs',
      description: (
        <span>
          Shows all <span className='nowrap-item'>Labour MPs</span> in{' '}
          <span className='nowrap-item'>the current Parliament</span>
        </span>
      ),
      plainDescription: 'Shows all current Labour MPs',
      filters: [
        MPsFilter,
        {
          category: getPropByName('categories', 'Party'),
          operator: getPropByName('operators', 'is equal to any of'),
          sentimentTopic: null,
          value: [
            {
              name: 'Labour',
            },
          ],
        },
      ],
    },
    {
      label: 'Conservative MPs',
      description: (
        <span>
          Shows all <span className='nowrap-item'>Conservative MPs</span>{' '}
          <span className='nowrap-item'>in the current Parliament</span>
        </span>
      ),
      plainDescription: 'Shows all current Conservative MPs',
      filters: [
        MPsFilter,
        {
          category: getPropByName('categories', 'Party'),
          operator: getPropByName('operators', 'is equal to any of'),
          sentimentTopic: null,
          value: [
            {
              name: 'Conservative',
            },
          ],
        },
      ],
    },
    {
      label: 'All MSPs',
      description: 'Shows all current Members of the Scottish Parliament',
      filters: [
        {
          category: getPropByName('categories', 'Role'),
          operator: getPropByName('operators', 'is equal to any of'),
          sentimentTopic: null,
          value: [
            {
              name: 'MSPs',
              filterValue: 'MSP',
              hash: 'msp',
              optionConditionToShow: [
                {
                  keywordLists: ['all'],
                  locations: ['/stakeholder-mapping', '/policymakers'],
                },
                {
                  locations: ['/search-results/stakeholders', '/influence/directory'],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'All MSs',
      description: 'Shows all Members of the Senedd in the Welsh Parliament',
      filters: [
        {
          category: getPropByName('categories', 'Role'),
          operator: getPropByName('operators', 'is equal to any of'),
          sentimentTopic: null,
          value: [
            {
              name: 'MSs',
              filterValue: 'MS',
              hash: 'mss',
              optionConditionToShow: [
                {
                  keywordLists: ['all'],
                  locations: ['/stakeholder-mapping', '/policymakers'],
                },
                {
                  locations: ['/search-results/stakeholders', '/influence/directory'],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  let categoriesWithRequiredTopicInput = ['Vocal about...', 'Sentiment'];

  return { GroupsFiltersData, groupsPreselectedFiltersData, categoriesWithRequiredTopicInput };
};

export default useGroupFiltersData;
