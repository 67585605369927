import React, { useState, useRef, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { net_api_url, app_url, store } from '../Store.js';
import axios from 'axios';
import createNotification from '../Settings/Utilities/CreateNotification.js';
import { useNavigate } from 'react-router-dom';
import useOutsideElement from '../Dashboard/utilities/useOutsideElement';
import CouponBanner from './CouponBanner.js';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import useIsMobile from '../Dashboard/utilities/useIsMobile.js';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord.js';
import uppercaseFirstLetter from '../Dashboard/utilities/uppercaseFirstLetter.js';
import TotalPlanTable from './TotalPlanTable.js';
import useUpdatePaidSubscriptions from '../Settings/Billing/useUpdatePaidSubscriptions.js';
import thousandCommas from '../Dashboard/helpers/thousandCommas.js';
import { loadStripe } from '@stripe/stripe-js';
import useGetAccessToken from '../Dashboard/apiCalls/useGetAccessToken.js';
import ModalComponent from '../Common/Modal.js';

const PricingPopUpData = {
  Free: {
    loseFeatures: [
      () => <span>Email alerts</span>,
      () => <span>Unlimited keywords</span>,
      () => <span>Advanced search</span>,
      () => <span>Website feeds</span>,
    ],
    keepFeatures: ['Email alerts', 'Unlimited keywords', 'Phone and email support'],
    nextPlan: 'Pro',
  },
  Starter: {
    priceMonthly: 245,
    priceAnnually: 195,
    loseFeatures: [
      () => (
        <span>
          Multiple users
          <span
            className='icon-info d-inline-block-centered ml-2 pointer'
            data-tooltip-id='popup-tooltip'
            data-tooltip-class-name={'general-tooltip pricing-feature-tooltip downgrade-popup-tooltip'}
            data-tooltip-content={
              'The Starter plan includes access for one person. There is no limit to how many users from your team can access your account on the Pro plan'
            }
          />
        </span>
      ),
      () => (
        <span>
          Multiple topics{' '}
          <span
            className='icon-info d-inline-block-centered ml-2 pointer'
            data-tooltip-id='popup-tooltip'
            data-tooltip-class-name={'general-tooltip pricing-feature-tooltip downgrade-popup-tooltip'}
            data-tooltip-content={
              'The Starter plan includes one topic to monitor, with up to 10 keywords. On the Pro plan, three topics are included by default, with unlimited keywords.'
            }
          />
        </span>
      ),
      () => (
        <span>
          AI summaries{' '}
          <span
            className='icon-info d-inline-block-centered ml-2 pointer'
            data-tooltip-id='popup-tooltip'
            data-tooltip-class-name={'general-tooltip pricing-feature-tooltip downgrade-popup-tooltip'}
            data-tooltip-content={
              'The Starter plan does not include AI summaries. On the Pro plan, each update on the platform will be summarised automatically with the latest AI technology, based on your organisation and work'
            }
          />
        </span>
      ),
      () => (
        <span>
          Advanced rules{' '}
          <span
            className='icon-info d-inline-block-centered ml-2 pointer'
            data-tooltip-id='popup-tooltip'
            data-tooltip-class-name={'general-tooltip pricing-feature-tooltip downgrade-popup-tooltip'}
            data-tooltip-content={`Use Boolean logic to hone in on the results that matter to you, and avoid information overload`}
          />
        </span>
      ),
      () => (
        <span>
          Website feeds{' '}
          <span
            className='icon-info d-inline-block-centered ml-2 pointer'
            data-tooltip-id='popup-tooltip'
            data-tooltip-class-name={'general-tooltip pricing-feature-tooltip downgrade-popup-tooltip'}
            data-tooltip-content={`By switching to the Pro plan, you'll be able to embed real-time feeds of political updates relevant to any topic, on any website or intranet page`}
          />
        </span>
      ),
    ],
    keepFeatures: ['Team access', 'Twitter monitoring', 'Phone support'],
    nextPlan: 'Pro',
    topics: 1,
    contacts: 50,
  },
  Pro: {
    priceMonthly: 595,
    priceAnnually: 495,
    loseFeatures: [
      () => (
        <span>
          Dynamic groups{' '}
          <span
            className='icon-info d-inline-block-centered ml-2 pointer'
            data-tooltip-id='popup-tooltip'
            data-tooltip-class-name={'general-tooltip pricing-feature-tooltip downgrade-popup-tooltip'}
            data-tooltip-content={`Dynamic groups automatically update based on the criteria you set, adding or removing stakeholders as they meet those conditions`}
          />
        </span>
      ),
      () => (
        <span>
          Analytics{' '}
          <span
            className='icon-info d-inline-block-centered ml-2 pointer'
            data-tooltip-id='popup-tooltip'
            data-tooltip-class-name={'general-tooltip pricing-feature-tooltip downgrade-popup-tooltip'}
            data-tooltip-content={`By switching to the Mogul plan, you'll get access to real-time analytics for each of your topics. Graphs can be edited, exported, and added to reports on our Reports feature`}
          />
        </span>
      ),
      () => (
        <span>
          Reports{' '}
          <span
            className='icon-info d-inline-block-centered ml-2 pointer'
            data-tooltip-id='popup-tooltip'
            data-tooltip-class-name={'general-tooltip pricing-feature-tooltip downgrade-popup-tooltip'}
            data-tooltip-content={`By switching to the Mogul plan, you'll get access to our Reports feature, which lets you create reports with the latest political updates, relevant policymakers and up-to-date analytics on any issue`}
          />
        </span>
      ),
      () => (
        <span>
          Account manager{' '}
          <span
            className='icon-info d-inline-block-centered ml-2 pointer'
            data-tooltip-id='popup-tooltip'
            data-tooltip-class-name={'general-tooltip pricing-feature-tooltip downgrade-popup-tooltip'}
            data-tooltip-content={`By switching to the Mogul plan, you'll get access to to a dedicated account manager who will be available to answer questions and work closely with you to ensure you are making the most out of the platform. Of course all of our subscriptions come with access to our customer support team`}
          />
        </span>
      ),
    ],
    keepFeatures: ['MPs, peers and others', 'Stakeholder exports', 'Historical content', 'Dedicated account manager'],
    nextPlan: 'Mogul',
    topics: 3,
    contacts: 650,
  },
  Mogul: {
    agencyPriceMonthly: 295,
    agencyPriceAnnually: 250,
    priceMonthly: 845,
    priceAnnually: 695,
    categoryPriceMonthly: 125,
    categoryPriceAnnually: 95,
    contactPriceMonthly: 19,
    contactPriceAnnually: 19,
    agencyCategoryPriceMonthly: 295,
    agencyCategoryPriceAnnually: 250,
    loseFeatures: [() => <span>Unlimited topics</span>, () => <span>Hand-picked key updates</span>],
    keepFeatures: ['Unlimited topics', 'Hand-picked key updates'],
    topics: 3,
    contacts: 1500,
  },
};

const PopUpCheckout = (props) => {
  const {
    targetPlan,
    period,
    setPlanToCheckout,
    setSelectedPlanInformation,
    activePlanName,
    selectedPlanInformation,
    showPopUpCheckout,
    setShowPopUpCheckout,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const PricingDataTarget = PricingPopUpData[targetPlan];
  const [showCheckout, setShowCheckout] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { getAccessToken } = useGetAccessToken();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const NextPlanPricingMessage = (plan) => {
    switch (plan) {
      case 'Starter':
        return (
          <span>
            only <strong>£{PricingPopUpData['Starter'].priceMonthly}*</strong>/month
          </span>
        );
      case 'Pro':
        return (
          <span>
            from <strong>£{PricingPopUpData['Pro'].priceAnnually}</strong>/month
          </span>
        );
      case 'Mogul':
        return (
          <span>
            from <strong>£{PricingPopUpData['Mogul'][`priceAnnually`]}</strong>/month
          </span>
        );
      default:
        break;
    }
  };

  const NextPlanButtonText = (plan) => {
    switch (plan) {
      case 'Starter':
        return 'Try Starter for 1 month';
      case 'Pro':
        return `${activePlanName === 'Pro' ? 'Extend Pro' : 'Try the Pro plan'}`;
      case 'Mogul':
        return `${activePlanName === 'Mogul' ? 'Extend Mogul' : 'Try the Mogul plan'}`;
      default:
        break;
    }
  };

  const returnToPreviousState = () => {
    setSelectedPlanInformation({ ...selectedPlanInformation, periodPopup: selectedPlanInformation.period });
    setShowCheckout(false);
    setShowPopUpCheckout(false);
  };

  const DowngradeToFree = async () => {
    try {
      let token = await getAccessToken();
      await axios.post(
        `${net_api_url}/api/subscription/set-to-free-plan`,
        {
          userId: user.sub,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          cancelToken: source.token,
        }
      );
      createNotification('success', `Successfully downgraded`);
      return navigate('/settings/check-plan');
    } catch (error) {
      createNotification('danger', `An error ocurred. ${error.response.data}`);
      console.error(error);
    }
  };

  let downgradeMessageConditional = false;

  if (!showCheckout) {
    if (activePlanName === 'Enterprise') {
      downgradeMessageConditional = true;
    } else if (activePlanName === 'Mogul' && targetPlan !== 'Mogul') {
      downgradeMessageConditional = true;
    } else if (activePlanName === 'Pro' && !(targetPlan === 'Mogul' || targetPlan === 'Pro')) {
      downgradeMessageConditional = true;
    } else if (activePlanName === 'Starter' && targetPlan === 'Free') {
      downgradeMessageConditional = true;
    }
  }

  const downgradeAction = useRef(downgradeMessageConditional);
  const isMobile = useIsMobile();

  return (
    <>
      <ModalComponent
        maxWidth={downgradeMessageConditional ? 1051 : 756}
        isOpen={showPopUpCheckout}
        setIsOpen={returnToPreviousState}
      >
        <div className={`popupcheckout-modal ${downgradeMessageConditional ? '' : 'py-4 py-lg-3'}`}>
          <div
            className={`${downgradeMessageConditional ? '' : 'px-4 py-3 px-lg-5 pt-lg-5 pb-lg-2'} pricing-confirmation-general-container`}
          >
            {downgradeMessageConditional && (
              <>
                <div className='bg-main-white rounded-lg text-center relative'>
                  <div className='d-lg-flex'>
                    <div
                      className='bg-main-blue text-white p-4 p-lg-5 text-left d-none d-lg-block rounded-left'
                      style={{ minWidth: '350px' }}
                    >
                      <h3 className='title-h3 mb-5 report-prospecting-title'>
                        <img
                          src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
                          alt='Logo'
                          className='policy-logo mr-2'
                        />
                        {PricingDataTarget.nextPlan} plan
                      </h3>
                      <ul className='policy-list text-left title-h5-m statistics-popup-list px-0 py-5 mb-5'>
                        {PricingDataTarget.loseFeatures.map((item, index) => (
                          <li className='mb-4 nowrap-item pl-0' key={`listkeep${index}`}>
                            <span className='title-h3 mr-3 icon-small-check' />{' '}
                            {typeof item === 'function' ? item() : item}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className='flex-grow-1 text-left py-5 px-3 px-xxl-5 downgrade-popup-features-container'>
                      <div className='pl-3 py-3'>
                        <h4 className='title-h2-bold mb-5'>You've chosen our {targetPlan} plan</h4>
                        <div className='title-h5-m main-subtle-text'>
                          {targetPlan === 'Starter' ? (
                            <>
                              <p>
                                Our Starter plan is a great fit for one-man bands, campaigners and those dipping their
                                toe into the world of public affairs.
                              </p>

                              <p>
                                You may prefer to consider our Pro plan, which includes multiple users and topics to
                                monitor, AI summaries, greater reach amongst policymakers, and more.
                              </p>

                              <p>
                                We’re looking forward to supporting you on whichever plan you feel is best suited to
                                your needs.
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                Our Pro plan is a great fit for small teams who want to power up their public affairs
                                work.
                              </p>

                              <p>
                                You may prefer to consider our Mogul plan, which includes access to our analytics
                                platform, report-creator, and more.
                              </p>

                              <p>
                                We’re looking forward to supporting you on whichever plan you feel is best suited to
                                your needs.
                              </p>
                            </>
                          )}
                        </div>

                        <div className='text-center text-lg-right mt-4 mt-lg-0 w-100 pt-5 pb-3 nowrap-item'>
                          <button
                            className='downgrade-pricing-button btn btn-general outline-active-button rounded-button px-4 px-xxl-5 py-2'
                            onClick={async () => {
                              downgradeAction.current = true;
                              if (targetPlan === 'Free') {
                                setIsLoading(true);
                                await DowngradeToFree();
                                setIsLoading(false);
                              } else {
                                setPlanToCheckout(targetPlan);
                                setShowCheckout(true);
                              }
                            }}
                          >
                            {isLoading ? <i className='fas fa-spinner fa-spin'></i> : `Downgrade to ${targetPlan}`}
                          </button>

                          <button
                            className='downgrade-pricing-button btn btn-general action-button rounded-button px-4 px-xxl-5 py-2 mt-2 mt-lg-0 ml-lg-2 ml-xxl-3'
                            onClick={() => {
                              if (PricingDataTarget.nextPlan === 'Starter') {
                                setSelectedPlanInformation({ ...selectedPlanInformation, periodPopup: 'monthly' });
                              }
                              downgradeAction.current = false;
                              setPlanToCheckout(PricingDataTarget.nextPlan);
                              setShowCheckout(true);
                            }}
                          >
                            {NextPlanButtonText(PricingDataTarget.nextPlan)}{' '}
                            {NextPlanPricingMessage(PricingDataTarget.nextPlan)}{' '}
                            <span className='icon-long-arrow-right ml-2 paragraph-p4' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!downgradeMessageConditional && (
              <div className={`pricing-confirmation-container billing-information px-lg-3`}>
                <h2 className='text-center mb-5 title-h3'>
                  You’ve chosen the <strong>{targetPlan}</strong> plan, billed <strong>{period}</strong>
                </h2>
                <PricingCheckout
                  {...props}
                  setShowCheckout={setShowCheckout}
                  downgrade={downgradeAction.current}
                  returnToPreviousState={returnToPreviousState}
                />
              </div>
            )}
          </div>
        </div>
      </ModalComponent>
      <ReactTooltip
        id='popup-tooltip'
        effect={'solid'}
        type={'info'}
        className={'general-tooltip pricing-feature-tooltip '}
        backgroundColor={'#00122B'}
        arrowColor={'transparent'}
        place={'bottom'}
        globalEventOff={isMobile ? 'click' : undefined}
      />
    </>
  );
};

const PricingCheckout = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activeSubscriptions, crmContactsQtty, activePlan: activePlanFromState, keywordsLists } = state;
  const {
    totalCrmContacts,
    maxListsPerTeam: mainMaxListsPerTeam,
    subscriptionStatus,
    includedCrmContacts: mainIncludeCrmContacts,
    billingPlanName,
    planName,
  } = activeSubscriptions;
  const userSubscribed = subscriptionStatus === 'Subscribed' || subscriptionStatus === 'SubscribedOverLimit';

  const showFreePlan = process.env.REACT_APP_SHOW_FREE_PLAN.toLowerCase() !== 'false';
  const {
    targetPlan,
    period,
    setPlanToCheckout,
    setSelectedPlanInformation,
    setShowCheckout,
    selectedPlanInformation,
    activeTab,
    returnToPreviousState,
  } = props;
  const { periodPopup } = selectedPlanInformation;

  const { agencyUser } = useTopicOrClientWord();
  const defaultCategories = agencyUser || targetPlan === 'Starter' ? 1 : 3;
  const keywordsListsWithoutMasterTopic = keywordsLists.filter((item) => item.id !== null);
  const keywordsListsForTheUser =
    keywordsListsWithoutMasterTopic.length > defaultCategories
      ? keywordsListsWithoutMasterTopic.length
      : defaultCategories;

  const maxListsPerTeam = targetPlan !== planName ? PricingPopUpData[targetPlan].topics : mainMaxListsPerTeam;
  const includedCrmContacts = targetPlan !== planName ? PricingPopUpData[targetPlan].contacts : mainIncludeCrmContacts;

  const [keywordsCategories, setKeywordsCategories] = useState(
    maxListsPerTeam > keywordsListsForTheUser ? maxListsPerTeam : keywordsListsForTheUser
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState(totalCrmContacts > 0 ? totalCrmContacts : 50);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setKeywordsCategories(
      maxListsPerTeam > keywordsListsForTheUser || targetPlan === 'Starter' ? maxListsPerTeam : keywordsListsForTheUser
    );
    setSelectedContacts(targetPlan !== planName ? includedCrmContacts : totalCrmContacts > 0 ? totalCrmContacts : 5);
  }, [targetPlan]);

  const createPlans = () => {
    if (showFreePlan) {
      return ['Pro', 'Mogul'];
    } else if (activeTab !== 'companies') {
      return ['Mogul'];
    } else {
      return ['Starter', 'Pro', 'Mogul'];
    }
  };
  const plans = createPlans();

  const { getAccessToken } = useGetAccessToken();
  const { updatePaidSubscriptionCall } = useUpdatePaidSubscriptions();

  const callCheckOut = async (planPrice, quantity, additionalCrmContacts) => {
    try {
      let token = await getAccessToken();
      let requestOptions = {
        cancelUrl: window.location.href,
        successUrl: `${app_url}/settings/check-plan`,
        planPrice: planPrice,
      };
      //let additionalContacts = additionalCrmContacts - 50;
      if (additionalCrmContacts > includedCrmContacts) {
        requestOptions = { ...requestOptions, additionalCrmContacts: additionalCrmContacts - includedCrmContacts };
      }

      let mogulOptions = quantity ? { ...requestOptions, quantity } : requestOptions;
      let response = await axios.post(`${net_api_url}/api/payment/create-checkout-session`, mogulOptions, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: source.token,
      });
      let responseData = await response.data;
      if (responseData) {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPEKEY);
        await stripe.redirectToCheckout({ sessionId: responseData.stripeSessionId });
      }
    } catch (error) {
      createNotification('danger', `An error ocurred.`);
    }
  };

  const createUserTypePrefix = () => {
    if (activeTab === 'agencies') {
      return 'Agency';
    } else if (activeTab === 'membership') {
      return 'MembershipOrganisation';
    } else {
      return '';
    }
  };

  const createConditionToAmend = () => {
    let condition;
    if (userSubscribed && planName === targetPlan) {
      condition = !billingPlanName.includes(period);
    } else {
      condition = true;
    }
    return condition;
  };

  const conditionToAmend = createConditionToAmend();
  const contactsTopicsPlans = targetPlan !== 'Enterprise';

  return (
    <>
      <CouponBanner />
      <div className='row mx-0 pt-lg-1'>
        <div className='col-lg-7 px-0 pr-lg-5 lose-features'>
          <div className='mb-5'>
            <div className='pl-lg-0 pr-lg-2 px-3 px-lg-0'>
              <p className='mb-0 plan-confirmation-label'>Plan</p>
            </div>
            <div className='mt-2 px-lg-0 px-3'>
              <SelectPlan
                plans={plans}
                targetPlan={targetPlan}
                setShowCheckout={setShowCheckout}
                setPlanToCheckout={setPlanToCheckout}
              />
            </div>
          </div>
          <div className='my-5'>
            <div className='pl-lg-0 pr-lg-2 px-3 px-lg-0'>
              <p className='mb-0 plan-confirmation-label'>Billing period</p>
            </div>
            <div className='mt-2 px-lg-0 px-3'>
              <SelectPeriod
                setSelectedPlanInformation={setSelectedPlanInformation}
                selectedPlanInformation={selectedPlanInformation}
                period={period}
              />
            </div>
          </div>
          {contactsTopicsPlans && conditionToAmend && (
            <NumberOfItemsController
              keywordsCategories={keywordsCategories}
              defaultCategories={defaultCategories}
              setKeywordsCategories={setKeywordsCategories}
              selectedContacts={selectedContacts}
              setSelectedContacts={setSelectedContacts}
              targetPlan={targetPlan}
              defaultContacts={includedCrmContacts > 0 ? includedCrmContacts : 50}
            />
          )}
        </div>
        <div className='col-lg-9 pl-3 pr-0 pl-lg-5'>
          <div className='table-responsive pricing-table'>
            <TotalPlanTable
              keywordsCategories={keywordsCategories}
              defaultCategories={defaultCategories}
              targetPlan={targetPlan}
              period={period}
              contactsSelected={contactsTopicsPlans ? selectedContacts : null}
              defaultContacts={includedCrmContacts > 0 ? includedCrmContacts : 50}
            />

            <button
              className={`btn btn-general action-button px-${userSubscribed ? '4' : '5'} py-2 mt-3`}
              onClick={async () => {
                setIsLoading(true);
                const planPrice = `${createUserTypePrefix()}${targetPlan}${uppercaseFirstLetter(periodPopup)}`;
                if (userSubscribed) {
                  let response = await updatePaidSubscriptionCall({
                    topicsSelected: keywordsCategories,
                    contactsSelected: selectedContacts,
                    planPrice,
                    includedCrmContacts,
                  });
                  if (response) {
                    dispatch({ type: 'MODIFY_SECTION', parameter: 'activeSubscriptions', value: response[0] });
                    dispatch({
                      type: 'MODIFY_SECTION',
                      parameter: 'activePlan',
                      value: {
                        ...activePlanFromState,
                        totalCrmContacts: response[0]?.totalCrmContacts,
                        enableCrm: response[0]?.enableCrm,
                      },
                    });
                    dispatch({
                      type: 'MODIFY_SECTION',
                      parameter: 'contactsOversubscribed',
                      value: response[0]?.totalCrmContacts - crmContactsQtty,
                    });
                    createNotification('success', 'Plan updated successfully');
                    returnToPreviousState();
                  }
                } else {
                  let quantity = contactsTopicsPlans ? keywordsCategories : false;
                  await callCheckOut(planPrice, quantity, contactsTopicsPlans ? selectedContacts : null);
                }
                setIsLoading(false);
              }}
              disabled={!conditionToAmend}
            >
              {isLoading ? (
                <i className='fas fa-spinner fa-spin'></i>
              ) : (
                `${userSubscribed ? `Continue with plan amendment` : 'Continue to checkout'}`
              )}
            </button>
          </div>
          <p></p>
        </div>
      </div>
    </>
  );
};

const NumberOfItemsController = (props) => {
  const { keywordsCategories, defaultCategories, setKeywordsCategories, targetPlan } = props;
  const { agencyUser, transformTopicOrClientWord } = useTopicOrClientWord();

  return (
    <>
      <div className='mt-5 align-items-center px-3 px-lg-0'>
        <p className='mb-0 plan-confirmation-label'>
          Number of {transformTopicOrClientWord({ plural: true })}
          {!agencyUser && (
            <span
              className='pricing-feature-more'
              data-tooltip-id='popup-tooltip'
              data-tooltip-html={`Topics allow you to monitor areas of interest<br/> separately, and receive tailored email alerts for <br/>each one. ${targetPlan === 'Starter' ? 'The Starter plan is limited to one<br/> topic, with ten keywords' : 'Three topics are included by default'}.`}
            >
              More information
            </span>
          )}
        </p>
        <div className='mt-2 mb-4'>
          <AddOrRemoveItemsController
            value={keywordsCategories}
            defaultValue={defaultCategories}
            setValue={setKeywordsCategories}
            disabled={targetPlan === 'Starter'}
          />
        </div>
      </div>
    </>
  );
};

const SelectPeriod = (props) => {
  const { setSelectedPlanInformation, selectedPlanInformation, period } = props;
  const [showOptions, setShowOptions] = useState(false);
  const periodElement = useRef(null);
  const hidePeriod = () => {
    setShowOptions(false);
  };
  useOutsideElement(periodElement, hidePeriod);
  return (
    <>
      <div className='position-relative' ref={periodElement}>
        <p
          className={`mb-0 hour-select px-3 ${showOptions ? 'hour-select-opendropdown' : ''}`}
          onClick={() => {
            setShowOptions(!showOptions);
          }}
        >
          {' '}
          {uppercaseFirstLetter(period)}
        </p>

        {showOptions && (
          <>
            <div className='frequency-dropdown'>
              <p
                className={`mb-0 frequency-item px-3 py-2 ${period === 'monthly' ? 'frequency-item-selected' : ''}`}
                onClick={() => {
                  setShowOptions(false);
                  setSelectedPlanInformation({ ...selectedPlanInformation, periodPopup: 'monthly' });
                }}
              >
                {`Monthly`}
              </p>
              <p
                className={`mb-0 frequency-item px-3 py-2 ${period === 'annually' ? 'frequency-item-selected' : ''}`}
                onClick={() => {
                  setShowOptions(false);
                  setSelectedPlanInformation({ ...selectedPlanInformation, periodPopup: 'annually' });
                }}
              >
                {`Annually`}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const SelectPlan = (props) => {
  const { plans, targetPlan, setShowCheckout, setPlanToCheckout } = props;
  const [showPlan, setShowPlan] = useState(false);
  const planElement = useRef(null);
  const hidePeriod = () => {
    setShowPlan(false);
  };
  useOutsideElement(planElement, hidePeriod);
  return (
    <div className={'position-relative'} ref={planElement}>
      <p
        className={`mb-0 hour-select px-3 ${showPlan ? 'hour-select-opendropdown' : ''}`}
        onClick={() => {
          setShowPlan(!showPlan);
        }}
      >
        {targetPlan} plan
      </p>
      {showPlan && (
        <>
          <div className='frequency-dropdown'>
            {plans.map((option) => (
              <p
                key={`item-${option}`}
                className={`mb-0 frequency-item px-3 py-2 ${targetPlan === option ? 'frequency-item-selected' : ''}`}
                onClick={() => {
                  hidePeriod();
                  setShowCheckout(true);
                  setPlanToCheckout(option);
                }}
              >
                {option} plan
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const AddOrRemoveItemsController = (props) => {
  const { topicOrClientWord } = useTopicOrClientWord();
  const {
    value,
    defaultValue,
    setValue,
    nameOfItem = `a ${topicOrClientWord}`,
    tooltipPlace = 'bottom',
    disabled = false,
    increaseValue = 1,
  } = props;
  const enableMinus = value > defaultValue;

  return (
    <div className='categories-buttons-container'>
      <button
        className='general-button categories-button'
        data-tooltip-id='popup-tooltip'
        data-tooltip-content={`Remove ${nameOfItem}`}
        data-tooltip-place={tooltipPlace}
        onClick={() => {
          if (enableMinus) {
            setValue(value - increaseValue);
          }
        }}
        disabled={disabled || !enableMinus}
      >
        -
      </button>
      <span className='category-buttons-main-text'>
        <strong>{thousandCommas(value)}</strong>
      </span>
      <button
        className='general-button categories-button'
        data-tooltip-id='popup-tooltip'
        data-tooltip-content={`Add ${nameOfItem}`}
        data-tooltip-place={tooltipPlace}
        onClick={() => {
          setValue(value + increaseValue);
        }}
        disabled={disabled}
      >
        +
      </button>
    </div>
  );
};

export { PricingPopUpData, AddOrRemoveItemsController };
export default PopUpCheckout;
