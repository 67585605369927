import normalize from '../../../../utilities/normalizeString';
import thousandCommas from '../../../../helpers/thousandCommas';

const EmailRecipientsSideBar = ({ activeOption, setActiveOption, sidebarMapping, qntty }) => {
  const renderLink = (item) => {
    return (
      <div
        className={`recipient-sidebar-item ${activeOption === item ? 'recipient-sidebar-item-active' : ''}`}
        key={normalize(item)}
        onClick={() => setActiveOption(item)}
      >
        <span>{item}</span>
        <span className='recipient-sidebar-item-qntty'>
          {qntty(item).length ? thousandCommas(qntty(item).length) : 0}
        </span>
      </div>
    );
  };

  return (
    <div className={`pt-lg-1 mt-3`}>
      <ul className='px-0 mb-0 overflow-hidden sidenav-list'>
        {Object.keys(sidebarMapping).map((item) => {
          return renderLink(item);
        })}
      </ul>
    </div>
  );
};

export default EmailRecipientsSideBar;
