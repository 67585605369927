import React from 'react';
import getUrlParam from '../../utilities/getUrlParam';
import { Link, useLocation } from 'react-router-dom';
const InfluenceHeader = (props) => {
  const returnUrl = getUrlParam('returnUrl');
  const location = useLocation();

  const urlLink = () => {
    return `${!!returnUrl ? decodeURIComponent(returnUrl) : `/influence/${location?.state?.influencePage ? 'ai-assistant' : ''}`}`;
  };
  return (
    <>
      <div className='py-3 px-3 px-lg-0 settings-header'>
        <div className='mx-0 justify-content-between align-items-center row mx-auto main-content-wrapper w-100'>
          <div className='pl-md-5 pl-3 show-lg side-left-element'>
            <div className='d-flex position-relative'>
              <Link to={urlLink()} className='settings-logo d-inline-block' />
              <Link to={urlLink()} className='close-name-settings d-inline-block'>
                Close
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfluenceHeader;
