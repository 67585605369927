import React, { useContext } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { store } from '../../Store.js';
import axios from 'axios';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord.js';
import useSaveFunctions from './SaveFunctions';

const OverLimitBanner = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions, team, remainingKeywords, keywordsLists, listPosition, InitialKeywordsState } = state;
  const { enableHandpickedContent, maxNumKeywordsPerTeam } = activeSubscriptions;
  const { myself } = team ?? {};
  const location = useLocation();
  const pricingUrl = `/pricing?returnUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`;
  const overLimitConditional = remainingKeywords < 0;
  const extraWords = -remainingKeywords;
  const navigate = useNavigate();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const currentList = listPosition === null ? {} : keywordsLists[listPosition];
  const { keywords, id, name, isMyList } = currentList;

  const { callCreateKeywordList, callUpdateKeywordList } = useSaveFunctions({
    source: source,
    redirectToHome: false,
  });

  const initialTopic = InitialKeywordsState[listPosition];
  const currentTopic = keywordsLists[listPosition];
  const haveChanges = JSON.stringify(initialTopic) === JSON.stringify(currentTopic) ? false : true;
  const redirectUpgrade = async (e) => {
    e.preventDefault();
    if (haveChanges && keywords.length > 0 && name !== '') {
      if (id !== '') {
        await callUpdateKeywordList();
      } else {
        await callCreateKeywordList();
      }
      navigate(pricingUrl);
    } else {
      navigate(pricingUrl);
    }
  };
  const params = useParams();
  const { list } = params;
  const listConditional = list !== '' && list !== undefined;

  const BlockLink = () => {
    return (
      <>
        {myself?.role === 'Member' && maxNumKeywordsPerTeam !== null && (
          <>
            <Link className={'block-link block-link-header'} to={'#'} onClick={(e) => e.preventDefault()}>
              Upgrade for more <span className='upgrade-message'>To upgrade contact your account Owner.</span>
            </Link>
          </>
        )}
      </>
    );
  };

  const teamMemberCondition = !isMyList && myself?.role === 'Member' && listConditional;
  const { topicOrClientWord } = useTopicOrClientWord();

  return (
    <>
      {maxNumKeywordsPerTeam !== null && listConditional && (
        <div
          className={`mt-lg-4 p-3 px-lg-4 upgrade-more-message ${overLimitConditional ? 'over-limit-message' : ''}`}
          style={{
            backgroundColor: overLimitConditional ? '#001C35' : remainingKeywords > 2 ? '#0094cc' : '#F2A805',
          }}
        >
          {overLimitConditional && (
            <p className='mb-0 settings-text'>
              Please <span>remove {`${extraWords} keyword${extraWords !== 1 ? 's' : ''}`}</span> to comply with your
              plan’s limitations. For more keywords,{' '}
              {myself?.role !== 'Member' && (
                <Link className={'light-link'} to={pricingUrl} onClick={redirectUpgrade}>
                  upgrade your subscription
                </Link>
              )}
              <BlockLink />
            </p>
          )}
          {remainingKeywords >= 0 && remainingKeywords !== 'unlimited' && !overLimitConditional && (
            <p className='mb-0 settings-text'>
              {`Your organisation has ${remainingKeywords}/${maxNumKeywordsPerTeam} remaining keyword${remainingKeywords !== 1 ? 's' : ''}. `}
              {myself?.role !== 'Member' && (
                <Link className={'light-link'} to={pricingUrl} onClick={redirectUpgrade}>
                  Upgrade for more
                </Link>
              )}
              <BlockLink />
            </p>
          )}
        </div>
      )}
      {teamMemberCondition ? (
        <div className={`mt-lg-4 p-3 px-lg-4 upgrade-more-message`}>
          <p className='mb-0 settings-text'>
            You can set email alerts for this {topicOrClientWord}, but only account Owners or Admins can edit its name
            or keywords
          </p>
        </div>
      ) : (
        <>
          {enableHandpickedContent && listConditional && (
            <div
              className={`mt-lg-4 p-3 px-lg-4 upgrade-more-message hand-picked-banner `}
              style={{
                backgroundColor: '#0094cc',
              }}
            >
              <p className='mb-0'>
                Some updates you receive will be hand-picked by PolicyMogul. Please{' '}
                <span className='contact-button-launch-messenger link-text'>inform us</span> of significant edits you
                make to this page
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OverLimitBanner;
