import React, { useContext, useEffect, useRef, useState } from 'react';
import useHiddenItemsFunctions from '../../Dashboard/hooks/useHiddenItemsFunctions';
import { store } from '../../Store';
import useIsMobile from '../../Dashboard/utilities/useIsMobile';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';

function HideTopicCheckbox({ keywordsLists, item, topicsPage, rememberState }) {
  const [changingState, setChangingState] = useState(false);
  const { updateHiddenItemState } = useHiddenItemsFunctions();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { team, search } = state;
  const { myself } = team ?? {};

  const isComponentMounted = useRef();

  const isMobile = useIsMobile();
  const { topicOrClientWord } = useTopicOrClientWord();
  useEffect(() => {
    isComponentMounted.current = true;
    return function () {
      isComponentMounted.current = false;
    };
  }, []);

  const switchVisability = async (itemArg) => {
    if (!changingState) {
      setChangingState(true);
      let newState = itemArg.myState === 'Enabled' ? 'Hidden' : 'Enabled';
      let updated = await updateHiddenItemState({
        state: newState,
        userId: parseInt(myself?.id),
        listId: itemArg.id,
        showSuccsessMessage: true,
      });
      if (isComponentMounted.current) {
        if (updated) {
          let newKeywordLists = keywordsLists.map((listItem) => {
            if (listItem.id === itemArg.id) {
              if (rememberState) {
                return {
                  ...listItem,
                  myState: newState,
                  rememberedState: listItem.rememberedState ?? listItem.myState,
                };
              } else {
                return { ...listItem, myState: newState };
              }
            }
            return listItem;
          });
          let filteredKeywordsLists = newKeywordLists.filter((item) => item.myState === 'Enabled' && item.id !== null);
          if (filteredKeywordsLists.length <= 1) {
            //REMOVE MASTER TOPIC AND SET FIRST LIST
            dispatch({
              type: 'MODIFY_SECTION',
              parameter: 'search',
              value: {
                ...search,
                list: filteredKeywordsLists[0],
              },
            });
          }

          dispatch({ type: 'MODIFY_SECTION', parameter: 'readyKeywordLists', value: false });
          dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: newKeywordLists });
          dispatch({ type: 'MODIFY_SECTION', parameter: 'InitialKeywordsState', value: newKeywordLists });
        }
        setChangingState(false);
      }
    }
  };
  return (
    <div
      className={`position-relative flex-centered ${
        topicsPage && !isMobile ? 'mr-2 mr-lg-3 hide-topic-btn' : 'mt-3 mb-2'
      } `}
    >
      {topicsPage ? (
        <>
          <label
            htmlFor={`hideTopic-${item.id}-id`}
            className={`mb-0 flex-centered pointer ${topicsPage ? 'hide-topic-label-wrapper-topics' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              switchVisability(item);
            }}
          >
            {changingState ? (
              <i
                className='fas fa-spinner fa-spin'
                style={{
                  color: '#212529',
                  width: '14px',
                  marginRight: '11px',
                  fontSize: '14px',
                }}
              ></i>
            ) : (
              <span
                className={`hide-topic-btn-icon mr-2 ${item.myState === 'Hidden' ? 'icon-eye-hide' : 'icon-visibility'}`}
              ></span>
            )}
            {item.myState === 'Hidden' ? 'Unhide' : 'Hide'} {topicOrClientWord}{' '}
          </label>
        </>
      ) : (
        <div className={`checkbox-item ${changingState ? 'checkbox-item-disabled' : ''}`}>
          <input
            type='checkbox'
            id={`hideTopic-${item.id}-id`}
            checked={item.myState === 'Hidden'}
            onChange={(e) => {
              e.stopPropagation();
              switchVisability(item);
            }}
          />
          <label
            className='title-h5-m main-subtle-text'
            style={{ lineHeight: 1.2 }}
            htmlFor={`hideTopic-${item.id}-id`}
          >
            {item.myState === 'Hidden' ? 'Unhide' : 'Hide'} this {topicOrClientWord} from my feeds
          </label>
        </div>
      )}
      <span
        onClick={(e) => e.stopPropagation()}
        className='icon-info main-light-text d-inline-block pointer ml-2'
        data-tooltip-content={`Hidden topics will no longer appear in drop-down lists on Monitor, Influence or Analytics, and will not be available for email alerts. They will also be removed from any existing email alerts. This setting only applies to you, and will not impact others on your team.`}
        data-tooltip-id={'settings-tooltip'}
        data-tooltip-class-name={'pricing-feature-tooltip feed-tooltip billing-tooltip'}
        style={topicsPage ? { fontSize: '15px' } : { verticalAlign: 'middle', marginTop: '-4px' }}
      />
    </div>
  );
}

export default HideTopicCheckbox;
