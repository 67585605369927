const MembershipOfSelectCommitteesFilters = [
  {
    name: 'Select Committees',
    pages: ['InfluenceContacts', 'Stakeholders'],
    type: 'membership',
    subtype: 'MembershipOfSelectCommittees',
    searchState: 'SeneddOrHolyroodCondition',
    filterName: 'selectCommittees',
    options: [],
    prefix: 'select',
    optionSelected: '',
    updateFilter: true,
  },
];

const LordSelectCommitteesFilters = [
  {
    name: 'Lords Select Committees',
    pages: ['InfluenceContacts', 'Stakeholders'],
    type: 'membership',
    subtype: 'MembershipOfLordsSelectCommittees',
    searchState: 'SeneddOrHolyroodCondition',
    filterName: 'lordsSelectCommittees',
    options: [],
    prefix: 'lords-select',
    optionSelected: '',
  },
];

export { LordSelectCommitteesFilters };
export default MembershipOfSelectCommitteesFilters;
