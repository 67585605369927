function reorderArray(from, to, arr) {
  if (from === to || from < 0 || to < 0) {
    return arr;
  }
  var moveNumber = arr[from];
  if (from < to) {
    for (let i = from; i < to; i++) {
      arr[i] = arr[i + 1];
    }
  } else {
    for (let i = from; i > to; i--) {
      arr[i] = arr[i - 1];
    }
  }
  arr[to] = moveNumber;
  return arr;
}

export default reorderArray;
