import { useContext, useEffect, useState, useRef } from 'react';
import EmbedAnalyticsSettings from './EmbedAnalyticsSettings';
import getUrlParam from '../Dashboard/utilities/getUrlParam';
import { store } from '../Store';
import { useCreateAnalyticsSections } from '../Dashboard/Analytics/ui/AnalyticsSidebar';
import CustomScrollbar from '../Common/CustomScrollbar';
import { mentionsGraphNations } from '../Dashboard/Analytics/createAnalyticsState';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';
import { useCreateInitialHeader } from '../Dashboard/Analytics/ui/DownloadChartModal/DownloadChartModal';
import EmbedAnalyticsPreview from './EmbedAnalyticsPreview';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useGetKeywordsListsByClient from '../Settings/WebsiteFeeds/useGetKeywordsListsByClient';

const EmbedAnalyticsMain = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { keywordsLists, team } = state;
  const { analyticsSections } = useCreateAnalyticsSections();

  const keywordsListsToUse = keywordsLists.filter((item) => item.id !== null);
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const { createInitialHeader } = useCreateInitialHeader();
  const { getKeywordListByClient } = useGetKeywordsListsByClient();

  const { setShowPopup, topicsProp } = props;
  const topicParameter = getUrlParam('topic-id');
  const [selectedTopics, setSelectedTopics] = useState(
    topicParameter ? [keywordsListsToUse?.find((item) => item.id === parseInt(topicParameter))] : topicsProp ?? []
  );
  const [searchValue, setSearchValue] = useState(
    !!getUrlParam('search') ? decodeURIComponent(getUrlParam('search')) : null
  );
  const [chartTypes, setChartTypes] = useState(props?.chartTypes ?? analyticsSections.map((item) => item.name));
  const [designState, setDesignState] = useState({
    headerColour: '#FFFFFF',
    backgroundColour: '#FFFFFF',
    includeBranding: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCountriesState, setSelectedCountriesState] = useState(mentionsGraphNations);
  const [client, setClient] = useState(team);

  const createHeaderName = () => {
    let lists = newAgencyPlan ? getKeywordListByClient({ client }) : keywordsLists;
    let finalHeader =
      searchValue ??
      (selectedTopics?.length === 1 ? lists.find((item) => item.id === selectedTopics[0]?.id)?.name : '');
    let header =
      chartTypes?.length === 1
        ? createInitialHeader(chartTypes[0], selectedTopics?.length > 1, finalHeader)
        : `Real-time political data ${agencyUser ? 'relevant' : 'related'} to: ${finalHeader}`;
    return header;
  };

  const mainEmbedContainer = useRef();
  const embedElement = useRef();
  const titleElement = useRef();
  const settingsElement = useRef();
  const mainElementContainer = useRef();
  const [header, setHeader] = useState('Live political data');
  const [subheader, setSubheader] = useState(createHeaderName());

  useEffect(() => {
    setSubheader(createHeaderName());
  }, [chartTypes, selectedTopics]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  useEffect(() => {
    if (newAgencyPlan) {
      let lists = getKeywordListByClient({ client });
      let filteredLists = lists?.filter((item) => item?.id !== null);
      const topicFromParameter =
        topicParameter && filteredLists?.length > 0
          ? filteredLists?.find((item) => item?.id === parseInt(topicParameter))
          : null;
      setSelectedTopics(filteredLists?.length > 0 ? [topicFromParameter ?? filteredLists?.[0]] : []);
    }
  }, [client]);

  const { width, left } = settingsElement?.current?.getBoundingClientRect() ?? {};

  return (
    <div className='bg-white website-feeds-popup h-100 rounded' ref={mainEmbedContainer}>
      <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
        <div className='text-center pb-4 px-3 px-lg-0 pt-5 main-sticky-lg bg-main-white rounded-top' ref={titleElement}>
          <h3 className='title-h3-bold'>Embed a live political analysis dashboard on any website</h3>
        </div>
        <div className='px-3 px-lg-5 pb-4'>
          <div className='px-4 row mx-0' ref={mainElementContainer}>
            <div className='col-lg-7 pl-0 pr-2' ref={settingsElement}>
              <div className='dashboard-root-container border rounded bg-light-blue-hovered h-100 pb-4 pb-lg-0 website-feeds-popup-settings position-relative'>
                <EmbedAnalyticsSettings
                  mainEmbedContainer={mainEmbedContainer}
                  selectedTopics={selectedTopics}
                  setSelectedTopics={setSelectedTopics}
                  chartTypes={chartTypes}
                  setChartTypes={setChartTypes}
                  designProps={{ designState, setDesignState }}
                  setShowPopup={setShowPopup}
                  selectedCountries={selectedCountriesState}
                  setSelectedCountries={setSelectedCountriesState}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  headerProps={{ header, setHeader }}
                  titleElement={titleElement}
                  subheaderProps={{ subheader, setSubheader }}
                  clientState={{ client, setClient }}
                  topicsByClient={getKeywordListByClient({ client })}
                />
              </div>
            </div>
            {isLoading ? (
              <div className='col-lg-9'>
                <SkeletonTheme baseColor='#f2f2f2'>
                  <Skeleton width={'100%'} height={'67vh'} />
                </SkeletonTheme>
              </div>
            ) : (
              <div
                className='col-lg-9 d-none d-lg-block pr-0 website-feeds-popup-embed'
                ref={embedElement}
                style={{
                  position: 'fixed',
                  height: `${mainEmbedContainer?.current?.getBoundingClientRect()?.height - titleElement?.current?.getBoundingClientRect()?.height - 20}px`,
                  top: `${
                    titleElement?.current?.getBoundingClientRect()?.height +
                    mainEmbedContainer?.current?.getBoundingClientRect()?.top
                  }px`,
                  left: `${left + width + 5}px`,
                  width: `${((mainElementContainer?.current?.getBoundingClientRect()?.width - 48) * 56.25) / 100}px`,
                }}
              >
                <div className='bg-main-blue rounded px-4 py-3 border h-100 dashboard-root-container'>
                  <EmbedAnalyticsPreview
                    selectedTopics={selectedTopics}
                    header={header}
                    keywordsLists={newAgencyPlan ? getKeywordListByClient({ client }) : keywordsLists}
                    designState={designState}
                    chartTypes={chartTypes}
                    subheader={subheader}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default EmbedAnalyticsMain;
