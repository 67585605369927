import React, { useState } from 'react';
import DeleteEmailDomainModal from './DeleteEmailDomainModal';
import { useCollapse } from 'react-collapsed';
import ShareInstructionsButton from './ShareInstructionsButton';
import DomainUnauthanticatedHeader from './DomainUnauthanticatedHeader';
import useIsMobile from '../../../Dashboard/utilities/useIsMobile';
import useEmailDomainFunctions from '../../../Dashboard/hooks/useEmailDomainFunctions';

const ContentUnauthenticated = ({ emailDomain, statusText, loadInitialInformation, teamId, isAdminPage }) => {
  return (
    <>
      <div className='domains-header'>
        <p className='domains-title'>Get set up to send emails</p>
        <p className='domains-subtitle'>
          This process requires the assistance of someone that is able to administer DNS records for your organisation.
          <br />
          This is usually someone that is responsible for your organisation’s IT or website.
        </p>
      </div>
      <div className='domains-unauthenticated-container'>
        <DomainUnauthanticatedHeader domain={true} />
        <ShareInstructionsButton emailDomain={emailDomain} />
        <div className='unauthenticated-section'>
          <ContentUnauthenticatedInstructions
            emailDomain={emailDomain}
            statusText={statusText}
            loadInitialInformation={loadInitialInformation}
            teamId={teamId}
            isAdminPage={isAdminPage}
          />
        </div>
      </div>
    </>
  );
};

const TableAuthenticated = ({ emailDomain, statusText }) => {
  return (
    <>
      <table className='domains-table domains-table-domain'>
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Value</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {emailDomain.dkimDnsRecords.map((item) => (
            <tr key={item.id}>
              <td>{item.type}</td>
              <td>{item.name}</td>
              <td>{item.value}</td>
              <td>
                <span className='domain-status-cell' data-value={statusText}>
                  {statusText}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const TableUnauthenticated = ({ emailDomain, statusText }) => {
  const isMobile = useIsMobile(768);
  return (
    <>
      <table className='domains-table domains-table-unauthenticated'>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Name</th>
            <th>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {emailDomain.dkimDnsRecords.map((item, index) => (
            <tr key={item.id}>
              {!isMobile && <td>{index + 1}</td>}
              <td>{`${item.type} ${isMobile ? `${index + 1}` : ''}`}</td>
              <td>{item.name}</td>
              <td>{item.value}</td>
              <td>
                <span className='domain-status-cell' data-value={statusText}>
                  {statusText}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export const ContentUnauthenticatedInstructions = ({
  emailDomain,
  statusText,
  loadInitialInformation,
  teamId,
  isAdminPage,
}) => {
  const [resetIsOnLoad, setResetIsOnLoad] = useState(false);
  const { resetEmailDomain } = useEmailDomainFunctions();
  const handleRetryEmailDomain = async () => {
    setResetIsOnLoad(true);
    await resetEmailDomain({ domainName: emailDomain?.name, teamId, loadInitialInformation, isAdminPage });
    setResetIsOnLoad(false);
  };
  return (
    <div className='unauthenticated-section-instruction'>
      <div className='section-instruction-title'>
        <span className='email-name'>{emailDomain.name}</span>
        <span className='domain-status' data-value={statusText}>
          {statusText}
        </span>
        {statusText === 'Failed' && (
          <button
            className='ml-3 ml-lg-auto general-button action-button px-2 py-0 paragraph-p3'
            style={{ lineHeight: '26px' }}
            onClick={handleRetryEmailDomain}
          >
            {resetIsOnLoad ? <i className='fas fa-spinner fa-spin' style={{ marginRight: '2px' }}></i> : 'Retry'}
          </button>
        )}
      </div>
      <div className='section-instruction-body'>
        <p className='instruction-body-title'>Verification unsuccessful</p>
        {statusText === 'Failed' ? (
          <p className='instruction-body-description'>
            We did not detect the correct CNAME records within the required three days, should you still want to proceed
            with registering this domain, please continue to create the correct CNAME records and then click the Retry
            button.
            <br />
            <br />
            To send emails using PolicyMogul, three CNAME records need to be added on your domain provider’s website.
            These records give PolicyMogul permission to send emails on your behalf by authenticating the domain.
            <br />
            <br />
            Within your domain provider’s website:
          </p>
        ) : (
          <p className='instruction-body-description'>
            To send emails using PolicyMogul, three CNAME records need to be added on your domain provider’s website.
            These records give PolicyMogul permission to send emails on your behalf by authenticating the domain.
            <br />
            <br />
            If we do not detect the correct CNAME records within three days, you will receive an email saying Email
            domain setup has failed. At this stage, should you still want to proceed with registering this domain,
            please return to this page to restart the verification process.
            <br />
            <br />
            Within your domain provider’s website:
          </p>
        )}
        <ul className='instruction-body-description-list'>
          <li>
            Look for a place to create <b>or add a new record</b>
          </li>
          <li>
            <b>Choose CNAME</b> for the record type
          </li>
          <li>
            <b>Copy the ‘Name’</b> info from the first record below, and <b>paste it</b> into the field called ‘Name’,
            ‘Host’, ‘Hostname’ or ‘Host record’
          </li>
          <li>
            <b>Copy the ‘Value’</b> info from the first record below, and <b>paste it</b> into the field called ‘Value’,
            ‘Data’ or ‘Points To’
          </li>
          <li>
            If you have an option for TTL, either leave it at the default setting, or choose the lowest value offered
          </li>
          <li>
            <b>Add or save your record</b>
          </li>
        </ul>
        <span className='blue-text-span'>Follow these steps for each of the three CNAME records below</span>
        <div className='instruction-body-records'>
          <p className='instruction-body-title'>Records</p>
          <TableUnauthenticated emailDomain={emailDomain} statusText={statusText} />
        </div>
      </div>
    </div>
  );
};

const EmailDomainContainer = ({
  teamId,
  emailDomain,
  loadInitialInformation,
  isAdminPage,
  expandedContainer,
  allowChanges,
  lastItem,
}) => {
  const [showDeleteEmailDomainModal, setShowDeleteEmailDomainModal] = useState(false);
  const [isExpanded, setOpen] = useState(expandedContainer);
  const showHideDeleteEmailDomainModal = () => {
    setShowDeleteEmailDomainModal(!showDeleteEmailDomainModal);
  };

  const statusText = emailDomain.verificationStatus === 'Success' ? 'Verified' : emailDomain.verificationStatus;
  const { getToggleProps, getCollapseProps } = useCollapse({
    isExpanded,
  });

  return (
    <>
      <DeleteEmailDomainModal
        setShowPopup={setShowDeleteEmailDomainModal}
        showPopUp={showDeleteEmailDomainModal}
        emailDomainId={emailDomain.id}
        isAdminPage={isAdminPage}
        teamId={teamId}
        loadInitialInformation={loadInitialInformation}
      />
      <div className='container-text-size' style={lastItem ? { marginBottom: '-25px' } : null}>
        <div>
          <div
            className={`email-domains-container-title `}
            {...getToggleProps({
              onClick: () => setOpen((prev) => !prev),
            })}
          >
            <div className='domain-name-container'>
              <span>@</span>
              <span className='domain-name'>{emailDomain.name}</span>
              <div
                data-tooltip-content={
                  allowChanges ? undefined : `Only team owners and admins can delete domains and sender email addresses`
                }
                data-tooltip-id={'settings-tooltip'}
                data-tooltip-class-name={'add-domain-tooltip'}
                className='d-flex'
              >
                <button
                  className='delete-domain-btn'
                  onClick={allowChanges ? showHideDeleteEmailDomainModal : undefined}
                  disabled={!allowChanges}
                />
              </div>
            </div>
            <div>
              <span className='domain-status' data-value={statusText}>
                {statusText}
              </span>
              <div className='domains-expand-btn-container' tabIndex={-1}>
                <span
                  className='domains-expand-btn'
                  style={isExpanded ? { transform: 'rotate(180deg)', marginTop: '-3px' } : undefined}
                  tabIndex={-1}
                />
              </div>
            </div>
          </div>
          <div {...getCollapseProps()}>
            <div className='domain-record-container' data-expanded={isExpanded}>
              {statusText === 'Verified' ? (
                <TableAuthenticated emailDomain={emailDomain} statusText={statusText} />
              ) : (
                <ContentUnauthenticated
                  emailDomain={emailDomain}
                  statusText={statusText}
                  loadInitialInformation={loadInitialInformation}
                  teamId={teamId}
                  isAdminPage={isAdminPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailDomainContainer;
