import MembershipOfSelectCommitteesFilters, {
  LordSelectCommitteesFilters,
} from './MembershipOfSelectCommitteesFilters';
import MembershipOfAPPGsFilters from './MembershipOfAPPGsFilters';
import RegionFilters from './RegionFilters';
const PositionOptions = [
  {
    name: 'Cabinet',
  },

  {
    name: 'Government',
  },

  {
    name: 'Shadow Cabinet',
  },

  {
    name: 'Shadow Government',
  },

  {
    name: 'Parliamentary Private Secretaries',
    filterValue: 'Parliamentary Private Secretary',
  },

  {
    name: 'Backbenchers',
    filterValue: 'Backbencher',
  },
];

const RoleOptions = [
  {
    name: 'MPs',
    filterValue: 'MP',
    hash: 'mps',
  },

  {
    name: 'Peers',
    filterValue: 'Lord',
    hash: 'lords',
  },

  {
    name: 'Special advisers',
    filterValue: 'Special Adviser',
    hash: 'special-advisers',
  },
  {
    name: 'MSPs',
    filterValue: 'MSP',
    hash: 'msp',
    optionConditionToShow: [
      {
        keywordLists: ['all'],
        locations: ['/stakeholder-mapping', '/policymakers'],
      },
      {
        locations: ['/search-results/stakeholders', '/influence/directory'],
      },
    ],
  },
  {
    name: 'MSs',
    filterValue: 'MS',
    hash: 'mss',
    optionConditionToShow: [
      {
        keywordLists: ['all'],
        locations: ['/stakeholder-mapping', '/policymakers'],
      },
      {
        locations: ['/search-results/stakeholders', '/influence/directory'],
      },
    ],
  },
  {
    name: 'Former MPs (2019 - 24)',
    filterValue: 'Former MP (2019-24)',
    hash: 'former-mp',
    optionConditionToShow: [
      {
        keywordLists: ['all'],
        locations: ['/stakeholder-mapping', '/policymakers'],
      },
      {
        locations: ['/search-results/stakeholders', '/influence/directory'],
      },
    ],
  },
];

const PartyOptions = [
  {
    name: 'Conservative',
  },
  {
    name: 'Labour',
  },
  {
    name: 'Liberal Democrat',
    hash: 'lib-dem',
  },
  {
    name: 'Reform UK',
  },
  {
    name: 'Alliance',
  },
  {
    name: 'Bishops',
  },
  {
    name: 'Crossbench',
  },
  {
    name: 'Democratic Unionist Party',
    hash: 'dup',
  },
  {
    name: 'Green Party',
    hash: 'green',
  },
  {
    name: 'Independent',
  },
  {
    name: 'Independent Social Democrat',
  },
  {
    name: 'Labour Independent',
  },
  {
    name: 'Non-affiliated',
  },
  {
    name: 'Non-aligned Lords',
  },
  {
    name: 'Plaid Cymru',
  },
  {
    name: 'Scottish National Party',
    hash: 'snp',
  },
  {
    name: 'Sinn Féin',
  },
  {
    name: 'Social Democratic & Labour Party',
    hash: 'sdlp',
  },
  {
    name: 'Speaker',
  },
  {
    name: 'UKIP',
  },
  {
    name: 'Ulster Unionist Party',
  },
  {
    name: 'Workers Party of Britain',
  },
];
const NationOptions = [
  {
    name: 'Westminster',
    filterValue: 'UK',
    labelClassName: 'label-country-sort uk-flag',
  },
  {
    name: 'Senedd',
    filterValue: 'Wales',
    labelClassName: 'label-country-sort label-wales',
  },
  {
    name: 'Holyrood',
    filterValue: 'Scotland',
    labelClassName: 'label-country-sort label-scotland',
  },
];

const StakeholdersFilters = [
  {
    name: 'Nation',
    pages: ['Stakeholders'],
    searchState: 'list',
    filterName: 'nations',
    type: 'sort',
    collapseOnSelect: true,
    customOrder: 0,
    optionSelected: 'Westminster',
    subtextClassName: 'nations-sort-subtext',
    options: NationOptions,
  },
  {
    name: 'Sort',
    pages: ['Stakeholders'],
    searchState: 'list',
    type: 'sortMode',
    optionSelected: 'Most relevant',
    customOrder: 0,
    options: [
      {
        name: 'Most relevant',
        description: 'Based on a combination of influence and contributions',
        tiptext: 'relevance',
        filterValue: 'relevance',
        optionConditionToShow: [
          {
            optionFilterSelected: [
              { name: 'Nation', selectedOption: 'Senedd', condition: false },
              { name: 'Nation', selectedOption: 'Holyrood', condition: false },
            ],
            locations: ['/stakeholder-mapping', '/policymakers'],
          },
        ],
      },
      {
        name: 'Most influential',
        applyToSearch: true,
        description: 'Based on relevant positions and memberships',
        filterValue: 'importance',
        optionConditionToShow: [
          {
            optionFilterSelected: [
              { name: 'Nation', selectedOption: 'Senedd', condition: false },
              { name: 'Nation', selectedOption: 'Holyrood', condition: false },
            ],
            locations: ['/stakeholder-mapping', '/policymakers'],
          },
        ],
      },

      {
        name: 'Most vocal',
        description: `Based on revelant parliamentary contributions ${
          process.env.REACT_APP_ENABLE_TWITTER === 'false' ? '' : 'and Tweets'
        }`,
        tiptext: 'contributions',
        filterValue: 'TotalContributions',
      },

      {
        name: 'Most positive',
        activePlanCondition: 'enableSentiment',
        description: 'Based on sentiment analysis of relevant contributions',
        filterValue: 'MostPositive',
      },

      {
        name: 'Most negative',
        activePlanCondition: 'enableSentiment',
        description: 'Based on sentiment analysis of relevant contributions',
        filterValue: 'MostNegative',
      },
    ],
  },
  {
    name: 'Search within results',
    searchState: 'activeSearch',
    pages: ['Stakeholders'],
    type: 'search',
    value: '',
    updateFilter: true,
  },
  {
    name: 'Sort',
    searchState: 'allResults',
    type: 'sort',
    pages: ['Stakeholders'],
    hidden: true,
    optionSelected: 'Last name ascending',
    options: [
      {
        name: 'Last name ascending',
        filter: [
          {
            pages: ['Stakeholders'],
            property: { field: 'lastName', order: 'ASC' },
          },
        ],
      },
    ],
  },
  {
    name: 'Sort',
    searchState: 'referenceSection',
    type: 'sort',
    pages: ['Stakeholders'],
    hidden: true,
    optionSelected: 'Position category rank',
    options: [
      {
        name: 'Position category rank',
        filter: [
          {
            pages: ['Stakeholders'],
            property: { field: 'positionCategoryRank', order: 'DESC' },
          },
        ],
      },
    ],
  },
  {
    name: 'Role',
    pages: ['Stakeholders', 'InfluenceContacts'],
    searchState: 'SeneddOrHolyroodCondition',
    filterName: 'categories',
    optionSelected: '',
    options: RoleOptions,
    prefix: 'role',
    customOrder: 1,
  },
  {
    name: 'Position',
    pages: ['Stakeholders', 'InfluenceContacts'],
    searchState: 'SeneddOrHolyroodCondition',
    filterName: 'positionCategories',
    optionSelected: '',
    options: PositionOptions,
    prefix: 'position',
    customOrder: 2,
  },
  {
    name: 'Party',
    pages: ['Stakeholders', 'InfluenceContacts'],
    additionalItemsText: 'parties',
    searchState: 'always',
    filterName: 'party',
    optionSelected: '',
    options: PartyOptions,
    prefix: 'party',
    updateFilter: true,
    customOrder: 3,
  },
  ...RegionFilters,
  {
    name: 'Sentiment',
    pages: ['Stakeholders'],
    searchState: 'sentimentSortModeCondition',
    filterName: 'aggregatedSentiment',
    optionSelected: '',
    conditionFilter: 'sortMode',
    condititonsOptionsToShow: ['Most negative', 'Most positive'],
    options: [
      {
        name: 'Positive contributors',
        filterValue: 'Positive',
        hash: 'positive',
      },

      {
        name: 'Mixed contributors',
        filterValue: 'Mixed',
        hash: 'mixed',
      },

      {
        name: 'Neutral contributors',
        filterValue: 'Neutral',
        hash: 'neutral',
      },

      {
        name: 'Negative contributors',
        filterValue: 'Negative',
        hash: 'negative',
      },
    ],
    prefix: 'sentiment',
  },
  ...MembershipOfSelectCommitteesFilters,
  ...LordSelectCommitteesFilters,
  ...MembershipOfAPPGsFilters,
  //AE: This filter is the one shown when the user has not the property on their plan.
  {
    name: 'Sentiment',
    pages: ['Stakeholders'],
    searchState: 'disabledPlanFieldOption',
    disabled: true,
    activePlanProperty: 'enableSentiment',
    filterName: 'aggregatedSentiment',
    optionSelected: '',
    options: [
      {
        name: 'Positive contributors',
        filterValue: 'Positive',
        hash: 'positive',
      },

      {
        name: 'Mixed contributors',
        filterValue: 'Mixed',
        hash: 'mixed',
      },

      {
        name: 'Neutral contributors',
        filterValue: 'Neutral',
        hash: 'neutral',
      },

      {
        name: 'Negative contributors',
        filterValue: 'Negative',
        hash: 'negative',
      },
    ],
    prefix: 'sentiment',
  },
];

export { PartyOptions, RoleOptions, PositionOptions, NationOptions };
export default StakeholdersFilters;
