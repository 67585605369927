import { useEffect, useRef, useState } from 'react';
import useOutsideElement from '../../utilities/useOutsideElement';
import { TopicPickerFilterSentiment } from './TopicPickerFilter';
import { OutsideCommonFilter } from './OutsideFilters';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import uppercaseFirstLetter from '../../utilities/uppercaseFirstLetter';
import DropdownTagFilter from './DropDownFilters/DropdownTagFilter';

const DropdownFilter = (props) => {
  const { item, filterProps, applyOptions, clearFilterFunction, renderOptions = true } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [localFilter, setLocalFilter] = useState(JSON.parse(JSON.stringify(item)));
  const { name, type, filterName, options, sentiment } = localFilter ?? {};

  const actionsElement = useRef();
  const hideActions = () => {
    setIsOpen(false);
  };

  useOutsideElement(actionsElement, hideActions);
  const isSelected = item?.options?.some((item) => item?.selected);

  const createFilterInitialName = () => {
    const topicPickerFilter = type === 'topicPickerWithSentiment';
    if (topicPickerFilter && isSelected) {
      return `${item?.sentiment ? uppercaseFirstLetter(item?.sentiment) : 'Vocal'} about  `;
    } else if (name === 'Tag is') {
      return 'Tag';
    } else if (type === 'membership') {
      return '';
    } else {
      return name;
    }
  };

  const createPhrase = () => {
    const topicPickerFilter = type === 'topicPickerWithSentiment';
    let phrase = createFilterInitialName();
    if (isSelected) {
      const optionsSelected = item?.options?.filter((item) => item?.selected);
      if (!topicPickerFilter && type !== 'membership') {
        phrase += ` is `;
      }
      if (optionsSelected?.length === 1) {
        phrase += `${optionsSelected[0]?.name}`;
      } else if (optionsSelected?.length === 2) {
        const formatter = new Intl.ListFormat('en-GB', { type: 'disjunction' });
        phrase += `${formatter.format(optionsSelected?.map((item) => item.name))}`;
      } else {
        phrase += `${optionsSelected[0]?.name} +${optionsSelected?.length - 1}`;
      }
    }
    return phrase;
  };

  const buttonRef = useRef();
  const { top, height, left } = buttonRef?.current?.getBoundingClientRect() ?? {};

  const selectOption = ({ filter, newValue }) => {
    setLocalFilter({ ...filter, options: newValue });
  };
  useEffect(() => {
    if (JSON.stringify(localFilter) !== JSON.stringify(item)) {
      setLocalFilter(JSON.parse(JSON.stringify(item)));
    }
  }, [item]);
  return (
    <div className='position-relative ' ref={actionsElement}>
      <div className={`d-flex rounded-pill specific-right-margin-18`}>
        <button
          className={`general-button specific-y-padding-6 border-general transition-default ${isSelected ? 'text-white rounded-pill-left  bg-main-blue border-main-blue general-dropdown-selected-button specific-left-padding-20 pr-3' : 'px-3 bg-main-white rounded-pill border-grey action-state-hover'}`}
          onClick={() => setIsOpen(!isOpen)}
          ref={buttonRef}
        >
          <span
            className={`${isSelected ? 'hidden-lines hidden-one-line' : 'dropdown-item-element dropdown-item-element-10'} ${isOpen ? 'open' : ''}`}
          >
            {createPhrase()}
          </span>
        </button>
        {isSelected && (
          <button
            className='general-button pr-3 rounded-pill-right flex-centered border-general bg-main-blue border-main-blue text-white specific-left-padding-12 general-dropdown-selected-button'
            onClick={() => clearFilterFunction({ filter: item })}
          >
            <span className='icon-close-bold paragraph-p6' />
          </button>
        )}
      </div>
      {isOpen && renderOptions && (
        <div
          className={`position-fixed box-shadow rounded z-max left-0 general-dropdown-filter-options `}
          style={{ left: `${left}px`, top: `${top + height + 5}px` }}
        >
          {type === 'topicPickerWithSentiment' && (
            <TopicPickerFilterSentiment
              localFilterState={{ localFilter, setLocalFilter }}
              selectOption={selectOption}
              showName={false}
              {...filterProps}
            />
          )}

          {filterName === 'tags' && (
            <DropdownTagFilter {...filterProps} filter={localFilter} selectOption={selectOption} />
          )}

          {(!type || type === 'membership') && filterName !== 'tags' && (
            <CustomScrollbar className={`simple-scrollbar`} autoHeight autoHeightMin='100%' autoHeightMax={`${280}px`}>
              <div className={`px-3 pt-3 mt-1`}>
                <OutsideCommonFilter
                  {...filterProps}
                  filter={localFilter}
                  selectOption={selectOption}
                  showMoreOptions={false}
                />
              </div>
            </CustomScrollbar>
          )}
          <div className='border-top py-2 px-3 text-right'>
            <button
              className={`general-button d-inline-block mr-3 simple-link-button ${isSelected ? 'text-red' : ''}`}
              onClick={() => {
                if (isSelected) {
                  clearFilterFunction({ filter: item });
                }
                setIsOpen(false);
              }}
            >
              {isSelected ? 'Reset' : 'Cancel'}
            </button>
            <button
              className='general-button action-button rounded-pill px-4 specific-y-padding-7'
              onClick={() => {
                applyOptions({
                  filter: item,
                  newValue: type === 'topicPickerWithSentiment' ? { options, sentiment } : options,
                });
                setIsOpen(false);
              }}
              disabled={options?.filter((item) => item.selected)?.length === 0}
            >
              Show results
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownFilter;
