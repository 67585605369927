import { useEffect, useRef, useState } from 'react';
import { AIAssistantPopup } from '../Dashboard/Navigation/NotFeaturePopups';
import { useAuth0 } from '@auth0/auth0-react';
import useAiAssistantWidgetFunctions from './useAiAssistantWidgetFunctions';
import CustomScrollbar from '../Common/CustomScrollbar';
import { smoothScrollTo } from '../Dashboard/Influence/CrmGroups/GroupScrollFunctions';

const AIassistantWidget = (props) => {
  const { openAIassistantChatState, heightContainer, createReportFromUpdateContainer, data } = props;
  const { openAIassistantChat, setOpenAIassistantChat } = openAIassistantChatState ?? {};
  const { objectId, objectType } = data;
  const [value, setValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialHeight, setInitialHeight] = useState(null);
  const widgetContainer = useRef();
  const topValue = createReportFromUpdateContainer?.current?.getBoundingClientRect()?.height ?? 0;
  const [historicChatMessages, setHistoricChatMessages] = useState([]);
  const { isAuthenticated } = useAuth0();
  const { aiChatCall } = useAiAssistantWidgetFunctions();

  const chatContainerRef = useRef();
  const scrollbarRef = useRef();
  const textAreaRef = useRef();

  useEffect(() => {
    if (widgetContainer.current) {
      setInitialHeight(widgetContainer?.current?.getBoundingClientRect()?.height);
    }
  }, []);

  const pushNewMessage = ({ message, role = 'User' }) => {
    let newHistoricChatMessages = [...historicChatMessages];
    newHistoricChatMessages.push({ role, content: message });
    setHistoricChatMessages(newHistoricChatMessages);
  };

  const moveToChatBottom = () => {
    if (scrollbarRef?.current) {
      setTimeout(() => {
        smoothScrollTo(scrollbarRef, scrollbarRef.current.getScrollHeight() - 100, 1000);
      }, 50);
    }
  };

  const sendValue = async () => {
    if (isAuthenticated) {
      setIsLoading(true);
      pushNewMessage({ message: value });
      setOpenAIassistantChat(true);
      if (!openAIassistantChat) {
        setTimeout(() => {
          moveToChatBottom();
          textAreaRef.current?.focus();
        }, 50);
      }
      setValue('');
      const aiChatCallRes = await aiChatCall({ objectId, objectType, newChatMessage: value, historicChatMessages });
      if (aiChatCallRes) {
        setHistoricChatMessages(aiChatCallRes);
        moveToChatBottom();
      }
      setIsLoading(false);
    } else {
      setIsOpen(true);
    }
  };

  const inputAreaProps = {
    value,
    onChange: (e) => setValue(e?.target?.value),
    onKeyDown: (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        moveToChatBottom();
        sendValue();
      }
    },
  };

  const SendMessageButton = () => {
    return (
      <button
        className='general-button bg-main-blue text-white assistant-widget-button flex-centered justify-content-center'
        style={{ visibility: value?.trim() === '' ? 'hidden' : 'visible' }}
        onClick={() => {
          moveToChatBottom();
          sendValue();
        }}
      >
        <span className='icon-arrow-top paragraph-p5' />
      </button>
    );
  };

  return (
    <>
      <div
        ref={widgetContainer}
        className={`py-3 px-3 ${openAIassistantChat ? 'dashboard-root-container' : 'mb-3'} box-rounded box-outstanding-reports bg-white ai-assistant-widget`}
        style={
          initialHeight
            ? {
                height: `${openAIassistantChat ? heightContainer : initialHeight}px`,
                transform: `translateY(${openAIassistantChat ? `-${topValue + 16}` : 0}px)`,
              }
            : null
        }
      >
        <h3 className={`flex-centered justify-content-between`}>
          <span>AI assistant</span>{' '}
          {openAIassistantChat && (
            <button
              className='circle-button general-button hovered-button modal-close-button'
              onClick={() => {
                setOpenAIassistantChat(false);
              }}
            >
              <span className='icon-close paragraph-p5' />
            </button>
          )}
        </h3>
        {openAIassistantChat ? (
          <>
            <CustomScrollbar ref={scrollbarRef}>
              <div className='flex-grow-1 py-3 mb-1' ref={chatContainerRef}>
                {historicChatMessages.map((item, i) => {
                  const lastItem = i === historicChatMessages.length - 1 && !isLoading;
                  return item.role === 'User' ? (
                    <div
                      className={`d-flex justify-content-end ${lastItem ? '' : 'mb-3'}`}
                      key={`${item.role}-message-${i}`}
                    >
                      <p className='mb-0 user-message rounded text-white bg-light-blue rounded px-3 py-2 text-925'>
                        {item.content}
                      </p>
                    </div>
                  ) : (
                    <div className={`${lastItem ? '' : 'mb-3'}`} key={`${item.role}-message-${i}`}>
                      <p className='mb-0 user-message rounded border rounded px-3 py-2 bg-grey text-925'>
                        {item.content}
                      </p>
                    </div>
                  );
                })}

                {isLoading && (
                  <div className='chat-bubble'>
                    <div className='chat-typing'>
                      <div className='chat-dot'></div>
                      <div className='chat-dot'></div>
                      <div className='chat-dot'></div>
                    </div>
                  </div>
                )}
              </div>
            </CustomScrollbar>
            <div className='py-3 flex-centered'>
              <textarea
                ref={textAreaRef}
                placeholder='Start typing...'
                className='input-empty w-100 border border-grey rounded py-2 specific-left-padding-12 pr-5 writing-textarea text-925'
                {...inputAreaProps}
              />
              <div className='text-area-send-message-btn'>
                <SendMessageButton />
              </div>
            </div>
          </>
        ) : (
          <div className='rounded-pill border flex-centered specific-right-padding-7 specific-left-padding-15 specific-y-padding-6'>
            <img
              style={{ width: '16px' }}
              className='specific-right-margin-6'
              src={`${process.env.REACT_APP_CDNURL}/images/20231216-prospecting-icon.png`}
              alt='Prospecting icon'
            />
            <div className='flex-grow-1'>
              <input
                className='input-empty w-100 text-925 pr-2'
                placeholder='Ask anything about this update'
                {...inputAreaProps}
              />
            </div>
            <SendMessageButton />
          </div>
        )}
      </div>
      <AIAssistantPopup isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default AIassistantWidget;
