import { useContext, useEffect, useRef, useState } from 'react';
import { useFilters } from '../../Filters/FiltersContext';
import DropdownFilter from '../../Filters/ui/DropdownFilter';
import normalize from '../../utilities/normalizeString';
import FiltersPopup from '../../Filters/ui/FiltersPopUp';
import { createFiltersProperties } from '../../helpers/controlActiveFilters';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../../../Store';
import FiltersData from '../../Filters/FiltersData';
import useDetectActiveFilters from '../../SubNav/useDetectActiveFilters';
import { Portal } from 'react-portal';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import useWindowHeight from '../../utilities/useWindowHeight';

const CrmContactsFilters = () => {
  const globalState = useContext(store);
  const { state } = globalState;

  const { activePlan, contentResults, activeResults } = state;
  const { subscriptionStatus, enableReferenceSection } = activePlan ?? {};

  const { CrmContacts } = contentResults[activeResults] ?? {};
  const { getDefaultSectionFilters, filtersState, filterTypeModifiers, applyFiltersFunction, clearFilters } =
    useFilters();
  const sectionFilters = getDefaultSectionFilters(filtersState);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [containerWidth, setContainerWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const containerRef = useRef(null);
  const allFiltersButton = useRef(null);
  const buttonRefs = useRef([]);
  const filtersContainer = useRef();

  const filterProperties = createFiltersProperties();
  const { isAuthenticated } = useAuth0();
  const { detectActiveFilters } = useDetectActiveFilters();
  const { windowWidth } = useWindowHeight();

  const sectionFiltersToUse = sectionFilters.filter((item) => item?.type !== 'addedOn');
  const updateSelectedFilters = useRef();
  updateSelectedFilters.current = () => {
    const initialSectionFilters = sectionFiltersToUse.filter(
      (item) => !selectedFilters.some((filter) => filter.name === item.name && item.searchState === filter.searchState)
    );

    const sectionFiltersSelected = initialSectionFilters?.filter((item) =>
      item.options?.some((option) => option.selected)
    );
    if (sectionFiltersSelected?.length > 0) {
      setSelectedFilters([...selectedFilters, ...sectionFiltersSelected]);
    }
    const emptyFilters = !sectionFiltersToUse?.some((item) => item?.options?.some((option) => option?.selected));
    if (emptyFilters && selectedFilters?.length > 0) {
      setSelectedFilters([]);
    }
  };

  useEffect(() => {
    if (!!CrmContacts && isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  }, [CrmContacts]);

  useEffect(() => {
    if (!!CrmContacts) {
      updateSelectedFilters.current();
    }
  }, [sectionFiltersToUse]);

  useEffect(() => {
    if (filtersContainer?.current) {
      const children = Array.from(filtersContainer?.current?.children);
      if (children.length > 0) {
        const firstChildTop = children[0].offsetTop;
        const firstRowChildren = children.filter((child) => child.offsetTop === firstChildTop);
        const lastInFirstRow = firstRowChildren[firstRowChildren.length - 1];
        if (!!lastInFirstRow) {
          const { width } = lastInFirstRow?.getBoundingClientRect() ?? {};
          setContainerWidth(width + lastInFirstRow?.offsetLeft);
        }
      }
    }
  }, [sectionFilters, selectedFilters, windowWidth]);

  const sortedItems = ({ items }) => {
    return items.sort((a, b) => {
      const indexA = selectedFilters.findIndex((item) => item?.name === a?.name);
      const indexB = selectedFilters.findIndex((item) => item?.name === b?.name);
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      if (indexA !== -1) return -1;
      if (indexB !== -1) return 1;

      const hasCustomOrderA = a.customOrder !== undefined;
      const hasCustomOrderB = b.customOrder !== undefined;

      if (hasCustomOrderA && hasCustomOrderB) {
        return (a.customOrder ?? 0) - (b.customOrder ?? 0);
      } else if (hasCustomOrderA) {
        return -1;
      } else if (hasCustomOrderB) {
        return 1;
      } else {
        return 0;
      }
    });
  };
  const filtersToRender = sortedItems({
    items: sectionFiltersToUse,
  });

  const trialUser = subscriptionStatus === 'TrialEnded' || !enableReferenceSection;
  const allowedUsers = isAuthenticated && !trialUser;

  const applyOptions = ({ filter, newValue }) => {
    if (allowedUsers) {
      const newFilters = filterTypeModifiers({ filter, newValue });
      applyFiltersFunction(newFilters);
      let newSelectedFilters = [...selectedFilters];
      let position = newSelectedFilters?.findIndex((item) => item?.name === filter?.name);
      //AE: This condition will change for tags and for topic picker
      let valueToFilter = filter?.type === 'topicPickerWithSentiment' ? newValue?.options : newValue;
      if (valueToFilter?.some((item) => item?.selected)) {
        if (position < 0) {
          newSelectedFilters.push(filter);
        }
      } else {
        if (position >= 0) {
          newSelectedFilters.splice(position, 1);
        }
      }
      setSelectedFilters(newSelectedFilters);
    }
  };

  const clearFilterFunction = ({ filter }) => {
    if (filter) {
      const { name, type } = filter;
      const referenceFilters = JSON.parse(JSON.stringify(FiltersData()));
      const filterPosition = referenceFilters.findIndex((item) => item.name === name && item.type === type);
      const initialFilter = referenceFilters[filterPosition];
      const newValue =
        filter?.type === 'topicPickerWithSentiment'
          ? { options: initialFilter?.options, sentiment: null }
          : initialFilter?.options;
      applyOptions({ filter, newValue });
    }
  };

  buttonRefs.current = [];

  const detectAdditionalFilters = () => {
    let phrase;
    if (filtersContainer?.current) {
      const children = Array.from(filtersContainer?.current?.children);
      const firstLineTop = children[0]?.offsetTop;
      const firstLineElements = children.filter((child) => child.offsetTop === firstLineTop);
      if (selectedFilters?.length > firstLineElements?.length) {
        let number = selectedFilters?.length - firstLineElements?.length;
        phrase = `+${number} filter${number > 1 ? 's' : ''}`;
      }
    }
    return phrase;
  };

  return (
    <>
      <div className='d-flex justify-content-between contact-filters pb-3  w-100 pr-3 pr-lg-5 align-items-center'>
        {!isLoading ? (
          <>
            <div
              className='d-flex position-relative flex-grow-1 position-relative'
              ref={containerRef}
              style={{ paddingRight: `${allFiltersButton?.current?.getBoundingClientRect()?.width}px` }}
            >
              <div
                className='d-flex flex-wrap overflow-hidden row-gap-15'
                ref={filtersContainer}
                style={{ maxHeight: '38px' }}
              >
                {filtersToRender?.map((item, index) => {
                  const filterProps = {
                    filter: item,
                    filters: sectionFilters,
                    allowedUsers,
                  };
                  return (
                    <div
                      key={`dropdown-item-${normalize(item?.name)}`}
                      ref={(el) => {
                        if (el) buttonRefs.current[index] = el;
                      }}
                    >
                      <DropdownFilter
                        key={`dropdown-item-${normalize(item?.name)}`}
                        filterProps={filterProps}
                        item={item}
                        applyOptions={applyOptions}
                        clearFilterFunction={clearFilterFunction}
                      />
                    </div>
                  );
                })}
              </div>
              <div
                className={'border-left specific-left-padding-18 position-absolute'}
                style={{ left: `${containerWidth}px` }}
                ref={allFiltersButton}
              >
                <button
                  className={`general-button specific-y-padding-6 px-3 border-general  rounded-pill nowrap-item  ${!!detectAdditionalFilters() ? 'bg-main-blue border-main-blue text-white general-dropdown-selected-button' : 'bg-main-white border-grey action-state-hover'}`}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <span className='icon-filters mr-2 d-inline-block-centered centered-3' />
                  {!!detectAdditionalFilters() ? `${detectAdditionalFilters()}` : 'All filters'}
                </button>
              </div>
            </div>
            {detectActiveFilters() && (
              <button
                className='general-button action-delete-button specific-y-padding-6 ml-3 px-2 px-sm-4 px-lg-5 border-general text-white rounded-pill'
                style={{ visibility: detectActiveFilters() ? 'visible' : 'hidden' }}
                onClick={() => {
                  clearFilters();
                }}
              >
                Reset
              </button>
            )}
          </>
        ) : (
          <div className='d-flex'>
            <SkeletonTheme baseColor='#f2f2f2'>
              {Array(6)
                .fill()
                .map((item, index) => (
                  <div className='mr-3' key={index}>
                    <Skeleton style={{ borderRadius: '50rem' }} height={34} width={118} />
                  </div>
                ))}
            </SkeletonTheme>
          </div>
        )}
      </div>
      {isOpen && <div className='general-close-background' />}
      <Portal>
        <FiltersPopup section={filterProperties?.section} openState={{ isOpen, setIsOpen }} />
      </Portal>
    </>
  );
};

export default CrmContactsFilters;
