import React from 'react';
import useEmailComponentFunctions from './useEmailComponentFunctions';
import { Portal } from 'react-portal';
import ModalComponent from '../../../Common/Modal';

const ConfirmDiscardPopup = (props) => {
  const { showPopup, setShowPopup } = props;
  const { isLoading, removeEmail } = useEmailComponentFunctions();

  return (
    <Portal>
      <ModalComponent maxWidth={500} isOpen={showPopup} setIsOpen={setShowPopup}>
        <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2'>
          <div className='text-center'>
            <p>Are you sure you want to delete this draft?</p>

            <div className='my-4'>
              <button
                className='general-button danger-button px-4 py-2'
                onClick={async (e) => {
                  let deleteAction = await removeEmail();
                  if (deleteAction) {
                    setShowPopup(false);
                  }
                }}
              >
                {isLoading === 'delete' ? (
                  <i className='fas fa-spinner fa-spin' style={{ marginRight: '2px' }}></i>
                ) : (
                  'Delete draft'
                )}
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </Portal>
  );
};

export default ConfirmDiscardPopup;
