import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHeightContainer } from '../utilities/useHeightContainer';
import SkeletonRelatedContent from '../../ContentRelated/SkeletonRelatedContent';

const SkeletonConsultationContent = () => {
  const [heightContainer, containerRef] = useHeightContainer();
  return (
    <SkeletonTheme baseColor='#f2f2f2'>
      <div ref={containerRef} style={{ height: `${heightContainer}px` }}>
        <div className='h-100 row mx-0 piece-content-container'>
          <div className={`general-content-column padding-to-nav h-100 px-0 main-content-section content-container `}>
            <div className='p-4 pb-5'>
              <div className='section-title pb-2 mb-2'>
                <h3 className='main-title news-title pr-lg-4'>
                  <Skeleton height={24} width={`100%`} />
                  <div className='row justify-content-between mx-0 mt-2'>
                    <Skeleton height={19} width={200} />
                    <div className='mt-2 mt-lg-0'>
                      <Skeleton height={19} width={50} />
                      <span className='mx-1'>
                        <Skeleton width={20} height={20} circle={true} />
                      </span>
                      <Skeleton width={20} height={20} circle={true} />
                    </div>
                  </div>
                </h3>
              </div>
              <div className='news-content-in pt-2 pb-3 context mb-2'>
                <div className='my-1'>
                  <Skeleton height={19} width={`100%`} />
                </div>
                <div className='my-1 pl-4'>
                  <Skeleton height={19} width={`40%`} />
                </div>
                <div className='my-1 pl-4'>
                  <Skeleton height={19} width={`40%`} />
                </div>

                <div className='mt-2 mb-3'>
                  <Skeleton height={19} width={`100%`} />
                </div>
                {Array(4)
                  .fill()
                  .map((item, index) => (
                    <div key={index} className='mb-3'>
                      <Skeleton count={4} height={19} width={`100%`} />
                      <Skeleton height={19} width={`45%`} />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className='related-content-column show-lg px-0 h-100'>
            <SkeletonRelatedContent />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonConsultationContent;
