import { useContext } from 'react';
import { store } from '../../../Store';
import useGeneralApiCall from '../../apiCalls/useGeneralApiCall';

const useCallRemainingEmailCampaigns = () => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { generalApiCall } = useGeneralApiCall();
  const callRemainingEmailCampaigns = async (source) => {
    try {
      const pathname = `/api/crm-email/remaining-email-campaigns-to-send-this-month`;
      const method = 'get';
      const results = await generalApiCall({
        pathname,
        method,
        needsAuthentication: true,
        notShowErrorMessage: true,
        requestSource: source,
        returnCompleteRequest: true,
        returnError: true,
      });
      let remainingCampaigns = 0;
      if (!!results) {
        switch (results?.status) {
          case 200:
            remainingCampaigns = results?.data;
            break;
          case 204:
            remainingCampaigns = null;
            break;
          default:
            remainingCampaigns = 0;
        }
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'remainingEmailCampaigns',
          value: { remainingCampaigns, isReady: true },
        });
      }
    } catch (error) {}
  };
  return { callRemainingEmailCampaigns };
};

export default useCallRemainingEmailCampaigns;
