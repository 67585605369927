import React, { useContext, useEffect, useRef, useState } from 'react';
import ReportsTopNav from './ReportsTopNav';
import ReportsSidebar from './ReportsSidebar';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import { applyTheme } from '@cloudscape-design/components/theming';
import ReportsBoard from './ReportsBoard';
import useMutationObserver from '../../Dashboard/hooks/useMutationObserver';
import { store } from '../../Store';
import { useNavigate, useParams } from 'react-router-dom';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import ReportLogo from './ReportLogo';
import createNotification from '../../Settings/Utilities/CreateNotification';
import { useCreateReportsWidgets } from './useCreateReportsWidgets';
import useReportIconAutoselect from '../useReportIconAutoselect';
import ReportSkeleton from './ReportSkeleton';
import dayjs from 'dayjs';
import { ReportDiscardChangesModal, ReportSelectTopicModal } from './ReportComponentModals';
import { formatColor, hex_is_light } from './ReportDesignButton';
import CustomScrollbar from '../../Common/CustomScrollbar';
import { ReportCreateStep } from './ReportEmptyState';
import useReportMutationCallback from '../useReportMutationCallback';
import getUrlParam from '../../Dashboard/utilities/getUrlParam';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import useChangeClient from '../../Dashboard/Navigation/useChangeClient';

const theme = {
  tokens: {
    fontFamilyBase: 'Lato',
    borderRadiusContainer: '8px',
    fontSizeHeadingL: '17px',
  },
};
applyTheme({ theme });

const ReportBoardComponent = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, updateToCreateReport, team, clientToSwitchOnReports } = state;
  const { reportData, setReportData, macrosState, agencyClientLogoUrl, isLoading } = props;

  const [heightContainer, containerRef] = useHeightContainer();
  const { createReportName, topic, modifyGraphDates } = useCreateReportsWidgets({ reportData });
  const { callSuggestionIcon } = useReportIconAutoselect();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const { generalApiCall } = useGeneralApiCall();
  const { changeClientFunction } = useChangeClient();
  const targetRef = useRef();
  const scrollBarRef = useRef();

  const params = useParams();
  const navigate = useNavigate();
  const createParam = getUrlParam('open');

  const { id } = params;
  const [report, setReport] = useState(reportData);
  const [layout, setLayout] = useState(!!report && !!id ? JSON.parse(report?.json) : []);
  const [previewMode, setPreviewMode] = useState(params.action === 'preview');
  const [reportTitle, setReportTitle] = useState(
    !!report && !!id
      ? { title: report?.title, subtext: report?.description, icon: report?.icon }
      : { title: !!updateToCreateReport ? '' : createReportName({}), subtext: '', icon: null }
  );
  const [reloadContent, setReloadContent] = useState(false);
  const [designState, setDesignState] = useState({
    headerColour: '#FFFFFF',
    backgroundColour: '#FFFFFF',
    includeBranding: true,
    makeDefaultsSettings: true,
  });
  const [isOpenDiscardChangesModal, setIsOpenDiscardChangesModal] = useState(false);
  const [isOpenCreatetPopup, setIsOpenCreatePopup] = useState(!!createParam);
  const [isOpenSelectTopicModal, setIsOpenSelectTopicModal] = useState(false);
  const [defaultProperty, setDefaultProperty] = useState(null);
  const [pathnameChanged, setPathnameChanged] = useState(null);

  const createClientLogotype = () => {
    if (defaultTopic?.prospective) {
      return `https://logo.clearbit.com/${defaultTopic?.websiteUrl}?size=180`;
    } else if (defaultTopic?.logoUrl) {
      return defaultTopic?.logoUrl;
    } else if (!!agencyClientLogoUrl) {
      return `https://logo.clearbit.com/${agencyClientLogoUrl}?size=180`;
    } else if (newAgencyPlan) {
      return team?.organisationLogo;
    } else {
      return null;
    }
  };
  const defaultTopicRef = useRef(topic);
  const defaultTopic = defaultTopicRef.current;
  const [logotypes, setLogotypes] = useState({
    main: activePlan?.organisationLogo,
    client: createClientLogotype(),
    logoIsRemoved: false,
  });
  const { backgroundColour, headerColour } = designState;

  const createIconWithDefaultName = async (source) => {
    let title = reportTitle.title;
    if (!!title) {
      let value = await callSuggestionIcon({ value: title, source });
      if (value) {
        let finalValue = value === 'logotype' ? activePlan?.organisationLogo ?? null : value;
        if (finalValue) {
          setReportTitle({ ...reportTitle, icon: finalValue });
        }
      }
    }
  };

  const reportDataApiCall = useRef();
  reportDataApiCall.current = async (source) => {
    if (reportData) {
      setReport(reportData);
      let reportJson = JSON.parse(reportData?.json);
      setLayout(reportJson?.layout);
      setLogotypes({
        ...logotypes,
        main: reportData?.mainLogoOverride ?? (reportData?.haveNoLogo ? null : activePlan?.organisationLogo),
        client: reportData?.agencyClientLogoOverride ?? (reportData?.haveNoLogo ? null : logotypes.client),
        logoIsRemoved: reportData?.haveNoLogo,
      });
      if (!!reportJson?.design) {
        setDesignState(reportJson.design);
      }
      if (!!reportJson?.defaultProperty) {
        setDefaultProperty(reportJson.defaultProperty);
      }
      setReportTitle({
        title: reportData?.title === 'Untitled report' ? '' : reportData?.title,
        subtext: reportData?.description,
        icon: reportData?.icon,
      });
    } else if (!id && !updateToCreateReport) {
      createIconWithDefaultName(source);
    }
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    reportDataApiCall.current(source);
    return () => {
      source.cancel('Report icon cancelled by the user');
    };
  }, [reportData]);

  const defineChangesCondition = () => {
    let reportJSON = !!report ? JSON.parse(report?.json) : {};
    let layoutCondition = JSON.stringify(layout) !== JSON.stringify(reportJSON?.layout);
    let designCondition = !reportJSON.design || JSON.stringify(designState) !== JSON.stringify(reportJSON?.design);
    let titleCondition =
      (report?.title !== '' && report?.title !== reportTitle.title) ||
      report?.description !== reportTitle?.subtext ||
      report?.icon !== reportTitle?.icon;
    let logoCondition =
      (report?.mainLogoOverride !== logotypes?.main &&
        logotypes?.main !== (report?.mainLogoOverride ?? (report?.haveNoLogo ? null : activePlan?.organisationLogo))) ||
      (logotypes?.logoIsRemoved && !report?.haveNoLogo);
    let agencyLogoCondition = agencyUser && report?.agencyClientLogoOverride !== logotypes.client;
    let reportUpdateToCondition = reportData?.updateTo !== report?.updateTo;

    return (
      !report ||
      layoutCondition ||
      titleCondition ||
      logoCondition ||
      agencyLogoCondition ||
      designCondition ||
      reportUpdateToCondition
    );
  };

  const [leaveReportPageCondition, setLeaveReportPageCondition] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const changesCondition = defineChangesCondition();
    if (newAgencyPlan) {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'changesOnReports', value: changesCondition });
    }
    const finalChangesCondition = changesCondition && clientToSwitchOnReports === null;
    setLeaveReportPageCondition(!finalChangesCondition);
  }, [
    report,
    pathnameChanged,
    reportTitle,
    layout,
    designState,
    report?.updateTo,
    location,
    clientToSwitchOnReports,
    logotypes?.logoIsRemoved,
  ]);

  useOutsideElement(
    targetRef,
    (target) => {
      const targetHasLink = target.href ?? target.closest('a')?.href;
      if (targetHasLink) {
        if (!leaveReportPageCondition) {
          setPathnameChanged(targetHasLink);
          setIsOpenDiscardChangesModal(true);
        }
      }
    },
    [pathnameChanged, leaveReportPageCondition, location, previewMode]
  );

  useEffect(() => {
    if (clientToSwitchOnReports) {
      setIsOpenDiscardChangesModal(true);
    }
    return () => {
      dispatch({ type: 'MODIFY_SECTION', parameter: 'changesOnReports', value: false });
    };
  }, [clientToSwitchOnReports]);

  const mutationContainer = useRef();
  const { callback } = useReportMutationCallback({ scrollBarRef, layout, mutationContainer, targetRef });

  useMutationObserver(mutationContainer, callback);

  const saveOrCreateReport = async ({
    updateTo = report?.updateTo,
    reportLayout = layout,
    showSuccessMessage = true,
  }) => {
    setPathnameChanged(true);
    const method = 'post';
    const reportId = id ?? report?.id;
    const pathname = `/api/report-definition/${!!reportId ? 'update' : 'create'}`;
    const jsonDefinition = {
      updateTo: updateTo,
      design: designState,
      layout: reportLayout,
      defaultProperty,
    };

    let requestProperties = {
      title: !!reportTitle?.title ? reportTitle?.title : 'Untitled report',
      description: reportTitle?.subtext,
      icon: reportTitle?.icon,
      keywordListId: defaultTopic?.prospective ? null : defaultTopic?.id,
      keywords: defaultTopic?.prospective ? defaultTopic?.keywords : null,
      json: JSON.stringify(jsonDefinition),
      mainLogoOverride: reportId ? (logotypes?.logoIsRemoved ? null : logotypes?.main) : null,
      haveNoLogo: reportId ? logotypes?.logoIsRemoved : false,
    };

    if (agencyUser && !!logotypes.client) {
      requestProperties = {
        ...requestProperties,
        agencyClientLogoOverride: logotypes.client,
      };
    }
    if (!!reportId) {
      requestProperties = {
        ...requestProperties,
        id: reportId,
      };
    }
    const results = await generalApiCall({ method, pathname, requestProperties, needsAuthentication: true });
    if (results) {
      if (showSuccessMessage) {
        createNotification('success', 'Latest changes have been saved');
      }
      let json = JSON.parse(results?.json);
      let dateToReference = json?.updateTo ?? results?.lastEditedAt;
      let report = { ...results, updateTo: dateToReference };
      setReport(report);
      setReportData(report);
      setLogotypes({ ...logotypes, logoIsRemoved: false });
    }
  };

  const deleteReport = async ({ id }) => {
    setPathnameChanged(true);
    const method = 'delete';
    const pathname = `/api/report-definition/${id}`;
    const results = await generalApiCall({ method, pathname, needsAuthentication: true, returnCompleteRequest: true });
    if (results?.status === 200) {
      createNotification('success', 'Report has been deleted successfully');
      navigate({ pathname: '/reports' });
    }
  };
  const previewWithoutItems = previewMode && layout.length === 0;
  const refreshContentUpdated = ({ updateDate }) => {
    return new Promise((resolve) => {
      setReloadContent(true);
      setTimeout(async () => {
        let newReport = { ...report };
        let date = updateDate ? dayjs(updateDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD');
        let json = JSON.parse(newReport?.json);
        let newLayout = [...layout];
        newLayout = newLayout.map((item) => {
          let newItem = { ...item };
          newItem = modifyGraphDates({ item: newItem, dateToReference: date });
          return newItem;
        });
        json = {
          layout: newLayout,
          updateTo: date,
          design: designState,
        };
        newReport = {
          ...newReport,
          json: JSON.stringify(json),
          updateTo: date,
        };
        setLayout(newLayout);
        setReport(newReport);
        setReloadContent(false);
        resolve();
      }, 200);
    });
  };

  useEffect(() => {
    setIsOpenCreatePopup(!!createParam);
  }, [createParam]);

  return (
    <>
      <div className='bg-grey flex-grow-1'>
        {previewMode && <div className={`reports-preview-background`} />}
        <ReportsTopNav
          reportTitle={reportTitle}
          setReportTitle={setReportTitle}
          targetRef={mutationContainer}
          layoutState={{ layout, setLayout }}
          previewModeState={{ previewMode, setPreviewMode }}
          reportsFunctions={{ saveOrCreateReport, deleteReport }}
          report={report}
          isLoading={isLoading}
          refreshContentUpdated={refreshContentUpdated}
          scrollBarRef={scrollBarRef}
          changesCondition={defineChangesCondition()}
          designProps={{ designState, setDesignState }}
        />
        <div className={`row mx-auto main-content-wrapper pb-lg-0`}>
          <div className={`side-left-element d-none d-lg-block ${previewMode ? 'reports-preview-side-nav' : ''}`}>
            <ReportsSidebar
              isLoading={isLoading}
              layout={layout}
              setLayout={setLayout}
              macrosState={macrosState}
              report={report}
              defaultProperty={defaultProperty}
            />
          </div>

          {isLoading ? (
            <ReportSkeleton />
          ) : (
            <div
              ref={targetRef}
              className={`main-content pr-xl-5 px-md-4 px-3 pt-lg-5 pt-3 mx-auto ${
                previewMode ? 'reports-preview-main-content' : 'position-relative'
              } `}
            >
              <div ref={containerRef}>
                <CustomScrollbar
                  ref={scrollBarRef}
                  className={'main-content-scrollbar'}
                  maximalThumbYSize={100}
                  style={{ height: `${heightContainer + 1}px` }}
                >
                  <div
                    className={`rounded-top dashboard-root-container reports-white-container`}
                    ref={mutationContainer}
                    style={{
                      backgroundColor: backgroundColour,
                      border: `1px solid ${
                        (formatColor(backgroundColour) !== '#FFFFFF' || formatColor(headerColour) !== '#FFFFFF') &&
                        previewMode
                          ? 'transparent'
                          : '#dee2e6'
                      }`,
                    }}
                  >
                    <div
                      className={`rounded-top report-title-elements d-flex px-lg-5 px-3 ${
                        previewMode ? 'py-3 align-items-center' : 'py-4'
                      }`}
                      style={{
                        backgroundColor: headerColour,
                        borderBottom: `1px solid ${
                          formatColor(backgroundColour) === '#FFFFFF' ? '#dee2e6' : 'transparent'
                        }`,
                      }}
                    >
                      <div className='flex-grow-1 pr-2'>
                        {previewMode ? (
                          <>
                            <h1
                              className='title-h3-bold mb-0'
                              style={{ color: hex_is_light(headerColour) ? '#00122b' : '#fff' }}
                            >
                              {!!reportTitle?.title ? reportTitle?.title : 'Untitled report'}
                            </h1>
                            {!!reportTitle?.subtext && (
                              <p
                                className='mt-1 mb-0 report-subtext-input'
                                style={{ color: hex_is_light(headerColour) ? 'rgba(0,18,43,0.7)' : '#fff' }}
                              >
                                {reportTitle?.subtext}
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <ReportTitle
                              reportTitle={reportTitle}
                              setReportTitle={setReportTitle}
                              layout={layout}
                              scrollBarRef={scrollBarRef}
                            />
                            <input
                              value={reportTitle.subtext ?? ''}
                              onChange={(e) => setReportTitle({ ...reportTitle, subtext: e.target.value })}
                              className='form-control main-input report-subtext-input'
                              type='text'
                              placeholder='Subtext (optional)'
                            />
                          </>
                        )}
                      </div>
                      <div className='pl-4 flex-centered ml-2'>
                        {(!previewMode || (previewMode && !!logotypes?.main && !logotypes.logoIsRemoved)) && (
                          <ReportLogo
                            previewMode={previewMode}
                            logo={logotypes?.main}
                            setLogo={(url) =>
                              setLogotypes({
                                ...logotypes,
                                main: url,
                                logoIsRemoved: !url,
                              })
                            }
                            defaultTopic={defaultTopic}
                          />
                        )}
                        {(agencyUser || newAgencyPlan) &&
                          (!previewMode || (previewMode && !!logotypes?.client && !logotypes.logoIsRemoved)) && (
                            <div className='ml-4 pl-2'>
                              <ReportLogo
                                previewMode={previewMode}
                                logo={logotypes?.client}
                                setLogo={(url) =>
                                  setLogotypes({
                                    ...logotypes,
                                    client: url,
                                    logoIsRemoved: !url,
                                  })
                                }
                                defaultTopic={defaultTopic}
                              />
                            </div>
                          )}
                      </div>
                    </div>

                    <div className={`py-4 px-3 reports-board-main-container position-relative flex-grow-1`}>
                      {/*This used to be a minHeight, let's see if removing that creates an issue */}
                      {!previewWithoutItems && (
                        <ReportsBoard
                          layout={layout}
                          setLayout={setLayout}
                          reportTitle={reportTitle}
                          setReportTitle={setReportTitle}
                          previewMode={previewMode}
                          macrosState={macrosState}
                          boardContainer={targetRef}
                          report={report}
                          reloadContent={reloadContent}
                          defaultProperty={defaultProperty}
                          setDefaultProperty={setDefaultProperty}
                          setIsOpenSelectTopicModal={setIsOpenSelectTopicModal}
                        />
                      )}
                    </div>
                    {previewMode && designState.includeBranding && (
                      <div className='text-right px-5 py-3 border-top bg-salmon'>
                        <a
                          className='text-decoration-none main-subtle-text d-inline-block-centered centered-6'
                          href={`${process.env.REACT_APP_APPURL}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <span className='specific-top-padding-1 d-inline-block specific-right-padding-3'>
                            Powered by{' '}
                          </span>
                          <img
                            src={`${process.env.REACT_APP_DOTNETAPIURL}/api/cors/resource?url=${encodeURIComponent(
                              `${process.env.REACT_APP_CDNURL}/images/logo.png`
                            )}&mime=${encodeURIComponent('image/png')}&cache=true`}
                            alt='PolicyMogul logo'
                            width={'100px'}
                            className={`d-inline-block ml-1`}
                          />{' '}
                        </a>
                      </div>
                    )}
                  </div>
                </CustomScrollbar>
              </div>
            </div>
          )}
        </div>
      </div>

      <ReportDiscardChangesModal
        isOpen={isOpenDiscardChangesModal}
        setIsOpen={setIsOpenDiscardChangesModal}
        saveOrCreateReport={saveOrCreateReport}
        setPathnameChanged={setPathnameChanged}
        discardFunction={async () => {
          dispatch({ type: 'MODIFY_SECTION', parameter: 'updateToCreateReport', value: null });
          dispatch({ type: 'MODIFY_SECTION', parameter: 'updateBeforCreateReportLocation', value: null });
          if (!!clientToSwitchOnReports) {
            const teamId = clientToSwitchOnReports?.teamId;
            await changeClientFunction({ clientId: teamId, reports: true });
          } else {
            const urlChanged = new URL(pathnameChanged);
            navigate({ pathname: urlChanged?.pathname, search: urlChanged.search });
          }
        }}
      />

      <ReportCreateStep
        setLayout={setLayout}
        reportTitle={reportTitle}
        setReportTitle={setReportTitle}
        isOpen={isOpenCreatetPopup}
        setIsOpen={setIsOpenCreatePopup}
        isFirstTime={!getUrlParam('emptyState')}
        setDefaultProperty={setDefaultProperty}
      />

      <ReportSelectTopicModal
        isOpen={isOpenSelectTopicModal}
        setIsOpen={setIsOpenSelectTopicModal}
        setLayout={setLayout}
      />
    </>
  );
};

const ReportTitle = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;

  const inputRef = useRef();
  const searchTimeout = useRef();
  const { callSuggestionIcon } = useReportIconAutoselect();
  const { reportTitle, setReportTitle, scrollBarRef } = props;

  useEffect(() => {
    if (reportTitle?.title === '') {
      inputRef.current.focus();
      scrollBarRef.current.scrollToTop();
      scrollBarRef.current.scrollToLeft();
    }
  }, []);

  const onBlurFunction = async (e) => {
    const inputValue = reportTitle.title;
    if (inputValue !== '') {
      let value = await callSuggestionIcon({ value: inputValue });
      if (value) {
        let finalValue = value === 'logotype' ? activePlan?.organisationLogo ?? null : value;
        if (finalValue) {
          setReportTitle({ ...reportTitle, title: e.target.value, icon: finalValue });
        }
      }
    }
  };

  return (
    <input
      ref={inputRef}
      value={reportTitle.title}
      onChange={(e) => setReportTitle({ ...reportTitle, title: e.target.value })}
      className='form-control main-input mb-2'
      type='text'
      placeholder='Report title'
      onKeyUp={(e) => {
        if (searchTimeout.current) clearTimeout(searchTimeout.current);
        searchTimeout.current = setTimeout(() => onBlurFunction(e), 500);
      }}
    />
  );
};

export default ReportBoardComponent;
