import React from 'react';
import ModalComponent from '../../../../../Common/Modal';

const ContentPopup = ({ setShowPopup }) => {
  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2'>
      <div className='text-center'>
        <p>This contact has been deleted</p>

        <div className='my-4'>
          <button
            className='general-button delete-button px-4 mr-3 py-1'
            onClick={() => {
              setShowPopup(false);
            }}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: '#0094cc',
              fontWeight: 400,
              marginTop: '-4px',
              border: 'none',
            }}
            className='btn btn-general action-button px-4 py-2'
            onClick={async () => {
              setShowPopup(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const RemovedContactModal = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <ModalComponent maxWidth={350} isOpen={showPopUp} setIsOpen={setShowPopup}>
      <ContentPopup {...props} />
    </ModalComponent>
  );
};

export default RemovedContactModal;
