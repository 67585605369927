import CalendarFilters from './CalendarFilters';
import ConsultationsFilters from './ConsultationsFilters';
import ElectionKeyUpdatesFilters from './ElectionKeyUpdatesFilters';
import InfluenceContactsFilters from './InfluenceContactsFilters';
import InfluenceFilters from './InfluenceFilters';
import LegislationFilters from './LegislationFilters';
import LibraryMaterialFilters from './LibraryMaterialFilters';
import LobbyingMaterialFilters from './LobbyingMaterialFilters';
import ParliamentaryRecordFilters from './ParliamentaryRecordFilters';
import CommitteePublicationsFilters from './CommitteePublicationsFilters';
import StakeholdersFilters from './StakeholdersFilters';
import TweetsFilters from './TweetsFilters';

const dayjs = require('dayjs');
const updateLocale = require('dayjs/plugin/updateLocale');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  monthsShort: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
});

const FiltersData = () => {
  return [
    {
      name: 'Sort by',
      searchState: 'freeSearch',
      type: 'sort',
      mainListSort: true,
      pages: ['KeyUpdates', 'Tweets', 'Legislation', 'ParliamentaryRecord', 'LibraryMaterial', 'CommitteePublications'],
      optionSelected: '',
      options: [
        {
          name: 'Most recent',
          filter: [
            {
              pages: ['KeyUpdates', 'Tweets', 'LibraryMaterial', 'CommitteePublications'],
              property: { field: 'dateTime', order: 'desc' },
            },
            {
              pages: ['Legislation', 'ParliamentaryRecord'],
              property: { field: 'lastImporterUpdatedAtUtc', order: 'desc' },
            },
          ],
        },

        {
          name: 'Most relevant',
        },
      ],
      updateFilter: true,
    },
    {
      name: 'Sort by',
      searchState: 'inbox',
      type: 'sort',
      pages: [
        'Legislation',
        'ParliamentaryRecord',
        'KeyUpdates',
        'Tweets',
        'LibraryMaterial',
        'LobbyingMaterial',
        'Campaigns',
      ],
      optionSelected: 'Most recent',
      hidden: true,
      options: [
        {
          name: 'Most recent',
          filter: [
            {
              pages: [
                'Legislation',
                'ParliamentaryRecord',
                'KeyUpdates',
                'Tweets',
                'LibraryMaterial',
                'LobbyingMaterial',
                'Campaigns',
              ],
              property: { field: 'feedItemId', order: 'desc' },
            },
          ],
        },
      ],
      updateFilter: true,
    },
    ...ConsultationsFilters,
    ...LegislationFilters,
    ...ParliamentaryRecordFilters,
    ...CommitteePublicationsFilters,
    ...CalendarFilters,
    ...StakeholdersFilters,
    ...TweetsFilters,
    ...InfluenceFilters,
    ...LibraryMaterialFilters,
    ...LobbyingMaterialFilters,
    ...InfluenceContactsFilters,
    ...ElectionKeyUpdatesFilters,
    {
      name: 'Date',
      searchState: 'inbox',
      pages: ['KeyUpdates', 'ParliamentaryRecord', 'Tweets', 'LibraryMaterial'],
      type: 'date',
      optionSelected: '',
      filterName: 'dateTime',
      options: [
        {
          name: 'Today',
          filter: [dayjs().utc().startOf('day').format()],
        },
        {
          name: 'Yesterday',
          filter: [
            {
              field: 'dateTime',
              value: `${dayjs().utc().startOf('day').subtract(1, 'day').format().replace('Z', '')}`,
              operator: 'DATE_GTE',
            },
            {
              field: 'dateTime',
              value: `${dayjs().utc().startOf('day').format().replace('Z', '')}`,
              operator: 'DATE_LT',
            },
          ],
        },
        {
          name: 'Before',
          filterProperty: 'DATE_LT',
          showCalendar: true,
        },
        {
          name: 'After',
          filterProperty: 'DATE_GTE',
          showCalendar: true,
        },
        {
          name: 'Custom',
          hash: 'between',
          filterProperty: 'range',
          showCalendar: true,
        },
      ],
    },
    {
      name: 'Date',
      searchState: 'not-inbox',
      pages: ['KeyUpdates', 'ParliamentaryRecord', 'Tweets', 'LibraryMaterial'],
      type: 'date',
      optionSelected: '',
      filterName: 'dateTime',
      options: [
        {
          name: 'Today',
          filter: [dayjs().utc().startOf('day').format()],
        },
        {
          name: 'Yesterday',
          filter: [
            {
              field: 'dateTime',
              value: `${dayjs().utc().startOf('day').subtract(1, 'day').format().replace('Z', '')}`,
              operator: 'DATE_GTE',
            },
            {
              field: 'dateTime',
              value: `${dayjs().utc().startOf('day').format().replace('Z', '')}`,
              operator: 'DATE_LT',
            },
          ],
        },
        {
          name: 'Last 7 days',
          filter: [dayjs().subtract(6, 'day').utc().startOf('day').format()],
        },
        {
          name: 'Last 30 days',
          filter: [dayjs().subtract(29, 'day').utc().startOf('day').format()],
        },
        {
          name: 'Last 3 months',
          filter: [dayjs().subtract(3, 'months').add(1, 'day').utc().startOf('day').format()],
        },
        {
          name: 'Last 12 months',
          filter: [dayjs().subtract(1, 'year').add(1, 'day').utc().startOf('day').format()],
        },
        {
          name: 'Before',
          filterProperty: 'DATE_LT',
          showCalendar: true,
        },
        {
          name: 'After',
          filterProperty: 'DATE_GTE',
          showCalendar: true,
        },
        {
          name: 'Custom',
          hash: 'between',
          filterProperty: 'range',
          showCalendar: true,
        },
      ],
    },
    {
      name: 'Search within results',
      searchState: 'activeSearch',
      pages: [
        'KeyUpdates',
        'Consultations',
        'Legislation',
        'ParliamentaryRecord',
        'Tweets',
        'LibraryMaterial',
        'LobbyingMaterial',
        'Campaigns',
        'CommitteePublications',
      ],
      type: 'search',
      value: '',
      updateFilter: true,
    },
  ];
};

export default FiltersData;
