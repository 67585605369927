import { useContext } from 'react';
import { store } from '../../../../Store';
import LocalStorage from '../../../../../utils/LocalStorage';

function useGetInitialEmailAddress() {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;

  const { myself } = team ?? {};

  const getInitialSenderEmailAddress = (id, emailAddresses) => {
    let defaultCrmEmailAddressId = JSON.parse(LocalStorage.getItem('lastSenderEmailAddress'))?.emailAddressId;
    let userEmail = emailAddresses.find((address) => address.emailAddress?.includes(myself?.emailAddress))?.id;
    let defaultaddresToUse = userEmail ?? defaultCrmEmailAddressId;
    if (userEmail && defaultCrmEmailAddressId && userEmail !== defaultCrmEmailAddressId) {
      defaultaddresToUse = undefined;
    }
    let crmEmailAddressId = id ?? defaultaddresToUse;
    return emailAddresses.find((address) => address.id === crmEmailAddressId);
  };
  return { getInitialSenderEmailAddress };
}

export default useGetInitialEmailAddress;
