import React, { useEffect, useRef, useState } from 'react';
import ModalComponent from '../../Common/Modal';
// import normalize from '../../Dashboard/utilities/normalizeString';

const ContentPopupCreateFolder = (props) => {
  //we can access this property from the parent component to use if it's needed. e.g: a cancel button
  const { setShowPopup, createEditAFolder, folderToEdit, backFunctionality, editParameter } = props;
  const editItem = folderToEdit !== null && folderToEdit !== undefined;
  const [folderName, setFolderName] = useState(editItem ? folderToEdit.name : '');
  // const [access, setAccess] = useState('Private to me')
  const nameFolder = useRef(null);
  const focusInput = useRef();

  focusInput.current = () => {
    setTimeout(() => {
      if (nameFolder.current !== null) {
        nameFolder.current.focus();
      }
    }, 300);
  };

  useEffect(() => {
    focusInput.current();
  }, []);

  // const accessOptions = () => {
  //   const options = [
  //     {
  //       name: 'Private to me'
  //     },
  //     {
  //       name: 'Shared with my team'
  //     }
  //   ]
  //   return (
  //     <div className="topics-alerts-dropdown">
  //       {options.map((item, index)=>{
  //         const {name} = item;
  //         return (
  //           <p className={`mb-0 frequency-item px-3 py-2 item-share-access ${name === access ? 'frequency-item-selected' : ''} ${normalize(name)} `}
  //             key={`item-${index}`}
  //             onClick={()=>{
  //               setAccess(name)
  //             }}
  //           >
  //             {name}
  //           </p>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  const createTitleText = () => {
    if (editParameter === null) {
      return `${editItem ? 'Edit' : 'Create'} a folder`;
    } else {
      return editParameter === 'Access' ? 'Change access' : 'Rename folder';
    }
  };

  return (
    <div className='saved-items-popup'>
      <h3 className='topic-title pt-4 section-title pl-lg-5 pl-4 pb-3 mb-0'>{createTitleText()}</h3>
      <div className='px-4 px-lg-5 py-3 py-lg-4'>
        {(editParameter === 'Rename' || editParameter === null) && (
          <div className='mb-4'>
            <label htmlFor='folder-name'>Name</label>
            <input
              className='form-control main-input'
              type='text'
              id='folder-name'
              ref={nameFolder}
              value={folderName}
              autoComplete='off'
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
            />
          </div>
        )}
        {/* {
          (editParameter === 'Access' || editParameter === null) && 
          <div className='mb-4'>
            <label htmlFor='folder-name'>Access</label>
            <DropdownComponent
              mainItemClass={`item-share-access ${normalize(access)}`}
              mainItem={access}
              items={accessOptions}
            />         
          </div>
        } */}
      </div>
      <div
        className={`d-flex justify-content-${backFunctionality ? 'between' : 'end'} py-4 align-items-center border-top bg-grey rounded-bottom px-4 px-lg-5 `}
      >
        {backFunctionality && (
          <button
            className='general-button simple-link-button back-button'
            onClick={() => {
              backFunctionality();
            }}
          >
            Back
          </button>
        )}
        <div className='d-flex justify-content-end'>
          <button
            className='general-button simple-link-button mr-3 px-3'
            onClick={() => {
              setShowPopup(false);
            }}
          >
            Cancel
          </button>
          <button
            className='general-button action-button px-3'
            disabled={folderName === ''}
            onClick={async (e) => {
              let properties = {
                e,
                name: folderName,
              };
              if (editItem) {
                properties = { ...properties, id: folderToEdit.id };
              }
              let results = await createEditAFolder(properties);
              if (results) {
                setShowPopup(false);
              }
            }}
          >
            Save {editItem ? 'changes' : ''}
          </button>
        </div>
      </div>
    </div>
  );
};

const CreateFolderPopup = (props) => {
  const { setFolderToEdit, setEditParameter, showPopUp, setShowPopup } = props;

  useEffect(() => {
    return () => {
      setFolderToEdit(null);
      setEditParameter(null);
    };
  }, []);

  return (
    <ModalComponent maxWidth={560} isOpen={showPopUp} setIsOpen={setShowPopup}>
      <ContentPopupCreateFolder {...props} />
    </ModalComponent>
  );
};

export { CreateFolderPopup, ContentPopupCreateFolder };
