import React, { useEffect, useRef, useState } from 'react';
import { useHeightContainer } from '../../utilities/useHeightContainer';
import InfluenceVisibilityContainer from '../../UserContent/InfluenceVisibilityContainer';
import RelatedContentContainer from '../../UserContent/RelatedContentContainer';
import CreationSteps from './CreationSteps';
import Description from './Description';
import Headline from './Headline';
import InfluenceHeader from './InfluenceHeader';
import { useLocation, useParams } from 'react-router-dom';
import GeneralTooltips from '../../components/GeneralTooltips';
import DeleteInfluenceItemPopUp from './DeleteInfluenceItemPopUp';
import PublishedContentPage from './PublishedContentPage';
import Client, { ConfirmClientModal } from './Client';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import CustomScrollbar from '../../../Common/CustomScrollbar';

const CreateComponent = (props) => {
  const [heightContainer, containerRef] = useHeightContainer();
  const [boxHeightContainer, boxHeightContainerRef] = useHeightContainer();
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const location = useLocation();
  const {
    sectionName,
    nextFunction,
    prevFunction,
    disabledNavigation,
    nextText,
    validateFields,
    deleteFunction,
    validateEditChanges,
    itemInformation,
    InfluenceState,
    keywordsLists,
    sectionValues,
    associatedContent,
  } = props;

  const { agencyUser } = useTopicOrClientWord();
  const keywordsListsToUse = keywordsLists?.filter((item) => item?.id !== null);
  const [showClientValidation, setShowClientValidation] = useState(false);
  const editPage = location.pathname.includes('/edit');
  const [clientValidationState, setClientValidationState] = useState({
    isConfirmedClient: location?.state?.client || editPage || !agencyUser,
    url: null,
  });

  const { publishedAt } = itemInformation;

  const scrollBarRef = useRef(null);
  const params = useParams();

  useEffect(() => {
    scrollBarRef.current?.scrollToTop();
  }, [params.step]);

  const newRelatedItems = associatedContent;
  return (
    <>
      <InfluenceHeader {...props} />
      {sectionName === 'Preview' ? (
        <PublishedContentPage
          data={itemInformation}
          button={'Back'}
          publishFunction={nextFunction}
          page={params?.page}
        />
      ) : (
        <div className='pt-lg-5'>
          <div ref={boxHeightContainerRef} />
          <CustomScrollbar
            ref={scrollBarRef}
            className={'simple-scrollbar '}
            style={{ height: `${boxHeightContainer}px` }}
            maximalThumbYSize={100}
          >
            <div className='row mx-auto pl-lg-5 influence-general-container'>
              <div className='d-none d-lg-block influence-menu-items '>
                <CreationSteps
                  clientValidationState={clientValidationState}
                  setClientValidationState={setClientValidationState}
                  setShowClientValidation={setShowClientValidation}
                  {...props}
                />
              </div>
              <div className='influence-content-container flex-grow-1'>
                <div
                  className='mx-auto px-lg-5 px-xl-0'
                  style={{
                    maxWidth: '960px',
                  }}
                >
                  <div className='influence-creation_section influence-creation_header d-lg-flex justify-content-between align-items-center main-sticky'>
                    <h2 className={`influence-creation_title title-h2-bold mb-3 mb-lg-0`}>{sectionName}</h2>
                    <div className='create-content-buttons flex-centered'>
                      {InfluenceState[parseInt(params.step) - 1]?.name === 'Associated content' &&
                      newRelatedItems?.length > 0 ? (
                        <p className='mr-3 main-light-text mb-0'>{newRelatedItems?.length} selected</p>
                      ) : (
                        ''
                      )}
                      {!!deleteFunction && (
                        <button
                          className='general-button outline-button general-button-spacing mr-2 mr-lg-3 mt-lg-0 text-red-70'
                          disabled={disabledNavigation}
                          onClick={() => {
                            setShowDeletePopUp(true);
                          }}
                        >
                          Delete
                        </button>
                      )}
                      {!!prevFunction && (
                        <button
                          className='general-button outline-button general-button-spacing mr-2 mr-lg-3 mt-lg-0'
                          disabled={disabledNavigation}
                          onClick={() => {
                            prevFunction();
                          }}
                        >
                          Back
                        </button>
                      )}
                      <button
                        className={`general-button general-button-spacing ${!editPage || publishedAt === null ? 'action-button' : 'outline-button'}`}
                        disabled={disabledNavigation || !validateFields}
                        onClick={() => {
                          if (sectionName === 'Client' && !publishedAt) {
                            setShowClientValidation(true);
                          } else {
                            nextFunction();
                          }
                        }}
                      >
                        {disabledNavigation ? <i className='fas fa-spinner fa-spin' /> : nextText}
                      </button>
                      {editPage && publishedAt !== null && (
                        <button
                          className={`general-button action-button general-button-spacing ml-2 ml-lg-3`}
                          disabled={disabledNavigation || !validateFields || !validateEditChanges}
                          onClick={() => {
                            let publish = true;
                            nextFunction(publish);
                          }}
                        >
                          Republish
                        </button>
                      )}
                    </div>
                  </div>
                  <div
                    className='simple-box influence-creation_content'
                    ref={containerRef}
                    style={{
                      minHeight: `${heightContainer}px`,
                      margin: '0 auto',
                      maxWidth: '960px',
                    }}
                  >
                    {sectionName === 'Client' && (
                      <Client
                        {...props}
                        keywordsLists={keywordsListsToUse}
                        setClientValidationState={setClientValidationState}
                      />
                    )}
                    {sectionName === 'Headline' && <Headline {...props} />}
                    {sectionName === 'Description' && <Description {...props} />}
                    {sectionName === 'Details' && (
                      <>
                        <InfluenceVisibilityContainer {...props} />
                      </>
                    )}
                    {sectionName === 'Associated content' && <RelatedContentContainer {...props} />}
                  </div>
                </div>
              </div>
            </div>
          </CustomScrollbar>
        </div>
      )}
      <GeneralTooltips />
      <DeleteInfluenceItemPopUp {...props} setShowDeletePopUp={setShowDeletePopUp} showDeletePopUp={showDeletePopUp} />
      <ConfirmClientModal
        isOpen={showClientValidation}
        setIsOpen={setShowClientValidation}
        clientId={sectionValues?.fields?.[0]?.value}
        keywordsListsToUse={keywordsListsToUse}
        nextFunction={nextFunction}
        clientValidationState={clientValidationState}
        setClientValidationState={setClientValidationState}
      />
    </>
  );
};

export default CreateComponent;
