import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CreateAccountPopup from '../../components/CreateAccountPopup';
import FilterRegisterPopup from './FilterRegisterPopup';
import { useMainSectionParameter } from '../../helpers/useMainSectionParameter';
import { useContext, useState } from 'react';
import useIsMobile from '../../utilities/useIsMobile';
import FiltersPopup from './FiltersPopUp';
import { useFilters } from '../FiltersContext';
import { store } from '../../../Store';
import { useAuth0 } from '@auth0/auth0-react';

const MoreFiltersButton = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, contentResults, activeResults, search } = state;

  const { list } = search ?? {};
  const { subscriptionStatus, enableReferenceSection, name: planName } = activePlan ?? {};
  const { section } = props;

  const { getDefaultSectionFilters, compileFiltersNumber } = useFilters();
  const filters = getDefaultSectionFilters();
  const number = compileFiltersNumber();

  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showFiltersPopup, setShowFiltersPopup] = useState(false);

  const { isAuthenticated } = useAuth0();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const sectionName = section;
  const sectionResults = contentResults[activeResults][sectionName];

  const disabledCondition = () => {
    if (list !== null) {
      if (location.pathname.includes('/stakeholder-mapping')) {
        return sectionResults && sectionResults.currentlyProcessing;
      }
    }
    return false;
  };
  const disabledButton = disabledCondition();

  const { inboxSection } = useMainSectionParameter(params);
  const searchResultsSection = !enableReferenceSection && !inboxSection;

  const stakeholdersSection =
    location.pathname.includes('/stakeholder-mapping') || location.pathname.includes('/policymakers');
  const contactsSection = location.pathname.includes('influence/directory');
  const stakeholdersInfluenceSection = location.pathname.includes('influence/stakeholder-mapping');
  const groupsSection = location.pathname.includes('influence/my-groups');

  const trialUser = subscriptionStatus === 'TrialEnded' || !enableReferenceSection || subscriptionStatus === 'InTrial';
  const influenceSections = stakeholdersInfluenceSection || contactsSection;
  const allowedUsers =
    isAuthenticated &&
    (!influenceSections ||
      (influenceSections && !trialUser) ||
      (influenceSections && trialUser && planName === 'Enterprise'));

  const isMobile = useIsMobile(576);
  const candidatesSection = location.pathname.includes('/general-election-2024');

  return (
    <>
      {filters.filter((item) => (isMobile ? !item.hidden : !item.excludeFromPopup && !item.hidden)).length > 0 && (
        <div
          className={`position-relative filter-main-container more-filters-container ${
            stakeholdersSection || contactsSection || groupsSection ? 'more-filters-stakeholders' : 'd-lg-none'
          } ${candidatesSection ? 'candidates-filter-btn' : ''}`}
        >
          <button
            className={`sort-button more-filters-button ${number > 0 ? 'more-filters-button-active' : ''} ${
              stakeholdersInfluenceSection ? 'stakeholders-influence-filters-button' : ''
            } ${contactsSection || groupsSection ? 'contacts-influence-filters-button' : ''}`}
            style={{
              height: stakeholdersSection || contactsSection ? '38px' : undefined,
            }}
            onClick={() => {
              if (location.pathname.includes('/general-election-2024')) {
                setShowFiltersPopup(true);
                return;
              }
              if (isAuthenticated) {
                if (searchResultsSection) {
                  navigate('/reference/upgrade');
                } else {
                  if (allowedUsers) {
                    setShowFiltersPopup(true);
                  } else {
                    setShowRegisterPopup(true);
                  }
                }
              } else {
                setShowRegisterPopup(true);
              }
            }}
            disabled={disabledButton}
          >
            <span className='icon-filters'></span>
            <span
              className={`${stakeholdersInfluenceSection ? 'stakeholders-influence-filters' : ''} ${
                contactsSection ? 'contacts-filters-button' : ''
              } d-none d-md-inline-block`}
            >
              {inboxSection || stakeholdersSection ? 'More filters' : 'Filters'}
            </span>{' '}
            {number > 0 && <span>({number})</span>}
          </button>
        </div>
      )}
      <FiltersPopup openState={{ isOpen: showFiltersPopup, setIsOpen: setShowFiltersPopup }} />
      <FilterRegisterPopup
        setShowPopup={setShowRegisterPopup}
        proMessage={!enableReferenceSection && isAuthenticated}
        showPopup={showRegisterPopup && (stakeholdersSection || contactsSection) && isAuthenticated}
      />

      {!isAuthenticated && (
        <CreateAccountPopup
          text={'You’ll need to create an account to filter results'}
          setShowPopup={setShowRegisterPopup}
          showPopup={showRegisterPopup}
        />
      )}
    </>
  );
};

export default MoreFiltersButton;
