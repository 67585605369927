import React from 'react';
import { useState } from 'react';
import ShareDomainInstructions from './ShareDomainInstructions';
import useIsMobile from '../../../Dashboard/utilities/useIsMobile';

function ShareInstructionsButton({ emailDomain }) {
  const [showShareInstructionsModal, setShowShareInstructionsModal] = useState(false);
  const showHideShareInstructions = () => {
    setShowShareInstructionsModal(!showShareInstructionsModal);
  };
  const isSmallMobile = useIsMobile(500);
  return (
    <>
      <ShareDomainInstructions
        closeModal={showHideShareInstructions}
        emailDomainId={emailDomain.id}
        showModal={showShareInstructionsModal}
      />
      <div className='unauthenticated-section'>
        <p className='share-instructions' onClick={showHideShareInstructions}>
          Click here to share these instructions{isSmallMobile ? '' : ' with a colleague'}
        </p>
      </div>
    </>
  );
}

export default ShareInstructionsButton;
