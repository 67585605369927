import { useContext, useRef, useState } from 'react';
import useOutsideElement from '../../../utilities/useOutsideElement';
import normalize from '../../../utilities/normalizeString';
import AddLobbyingMaterialButton from '../../EngagementStatisticsDropdown/AddLobbyingMaterialButton';
import InfluenceSectionTitle from '../../InfluenceSectionTitle/InfluenceSectionTitle';
import { store } from '../../../../Store';

const filtersOptions = [
  {
    name: 'Any type of content',
  },
  {
    name: 'Policy asks',
    type: 'PolicyAsk',
  },
  {
    name: 'Briefing material',
    type: 'BriefingMaterial',
  },
  {
    name: 'Research',
    type: 'Research',
  },
  {
    name: 'News releases',
    type: 'NewsRelease',
  },
];

const LobbyingMaterialSubnav = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { teamContentResultsQntty, teamContentFilter } = state;

  const [activeFilters, setActiveFilters] = useState([]);

  const [showFilters, setShowFilters] = useState(false);
  const hideFilters = () => {
    setShowFilters(false);
  };
  const filtersElement = useRef(null);
  useOutsideElement(filtersElement, hideFilters);

  const applyFilters = async (item) => {
    dispatch({ type: 'MODIFY_SECTION', parameter: 'teamContentFilter', value: item });
    setActiveFilters(item?.type ? [item] : []);
  };

  const createCheckedFilters = () => {
    let checked = [];
    activeFilters.forEach((item) => {
      checked.push(item.name);
    });
    return checked;
  };

  const checkedFilters = createCheckedFilters();

  return (
    <div className='flex-centered w-100'>
      <div className='flex-grow-1'>
        <InfluenceSectionTitle />
      </div>
      <div className={`flex-centered`}>
        <div
          className={`position-relative filter-main-container d-flex`}
          style={{ visibility: teamContentResultsQntty > 0 || !!teamContentFilter ? 'visible' : 'hidden' }}
          ref={filtersElement}
        >
          <button
            className={`mr-3 sort-button filter-button ${showFilters ? 'active' : ''} ${
              activeFilters.length === 0 ? 'active-complete' : 'active-single'
            }  type-of-content-influence-btn mr-0`}
            onClick={() => {
              setShowFilters(!showFilters);
            }}
          >
            {activeFilters.length === 0 && <span className='filter-name'>Type of content</span>}
            {activeFilters.length > 0 && (
              <>
                <span className='filter-name'>{activeFilters[0].name}</span>
                {activeFilters.length > 1 ? ` +${activeFilters.length - 1}` : ''}
                <span
                  className='general-button close-filter-active'
                  onClick={(e) => {
                    e.stopPropagation();
                    applyFilters({});
                    setShowFilters(false);
                  }}
                >
                  <span className='icon-close paragraph-p6-bold'></span>
                </span>
              </>
            )}
          </button>
          {showFilters && (
            <div className={`box filters-container house-container`}>
              <button
                onClick={() => {
                  setShowFilters(false);
                }}
                className='close-button-popup circle-button general-button hovered-button action-state-hover'
              >
                <span className='icon-close paragraph-p5' />
              </button>

              <p className='title'>Type of content</p>

              <div className='filter-options-radio-container'>
                {filtersOptions.map((item, index) => (
                  <div className='radio-button mb-2' key={`filter-${index}`}>
                    <input
                      id={`${normalize(item.name)}`}
                      onChange={() => {
                        applyFilters(item?.name === 'Any type of content' ? 'anyType' : item);
                      }}
                      type='radio'
                      checked={
                        item.name === 'Any type of content'
                          ? activeFilters.length === 0
                          : checkedFilters.includes(item.name)
                      }
                    />
                    <label htmlFor={`${normalize(item.name)}`}>{item.name}</label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <AddLobbyingMaterialButton influenceSubnav />
      </div>
    </div>
  );
};

export default LobbyingMaterialSubnav;
