import React, { useState, useContext, useEffect, useRef } from 'react';
import { store } from '../../Store.js';
import { useLocation, Link, NavLink, useParams } from 'react-router-dom';
import LoginButtons from './LoginButtons';
import { useAuth0 } from '@auth0/auth0-react';
import useIsMobile from '../utilities/useIsMobile';
import CreateKeywordListButton from './CreateKeywordListButton';
import TrialBanner from './TrialBanner.js';
import ProfileButton from './ProfileButton.js';
import NavPages, { useResearchItemsUrl } from './NavPages.js';
import { useMainSectionParameter } from '../helpers/useMainSectionParameter.js';
import SavedItemsIcon, { useCloseButtonFunctionality } from '../../SavedItems/ui/SavedItemsIcon.js';
import { SentimentButtons } from '../Sentiment/SentimentGraphPage.js';
import ProspectiveButton from '../../Reports/ProspectiveButton.js';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord.js';
import parliamentarianUsers from '../sidebar/parliamentarianUsers.js';
import ClientNavigation, { useCloseSectionsCondition, useResetClientNavigation } from './ClientNavigation.js';
import { LoggedOutTopHeader } from './NavPagesLoggedOut.js';
import NavigationSidebar from './NavigationSideBar.js';
import useResetReference from '../SubNav/useResetReference.js';
import getUrlParam from '../utilities/getUrlParam.js';
import dayjs from 'dayjs';

const christmasCondition = () => {
  const initialDate = dayjs(`December 16 ${dayjs().year()}`);
  const end = dayjs(`January 2 ${dayjs().year()}`).add(1, 'year');
  const today = dayjs();

  return today.isBefore(end) && today.isAfter(initialDate);
};

const Navigation = () => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { contentResults, readyKeywordLists, activePlan, isHiddenMenu } = state;
  const { code, enableReports } = activePlan;

  const location = useLocation();
  const isMobile = useIsMobile();
  const params = useParams();
  const { inboxSection, searchResultsSection } = useMainSectionParameter(params);

  const { isAuthenticated } = useAuth0();
  const { agencyUser, newAgencyPlan } = useTopicOrClientWord();
  const { policymakerUrl } = useResearchItemsUrl();
  const { closeButtonFunctionality } = useCloseButtonFunctionality();
  const { resetClientNavigation } = useResetClientNavigation();

  //CREATE FIXED POSITIONS
  useEffect(() => {
    const createFixed = () => {
      const html = document.getElementsByTagName('html')[0];
      let position;
      let classHeader;
      let height;
      let classFavicon;
      let top;
      if (isFixedPathName()) {
        height = `${headerContainer.current?.getBoundingClientRect()?.height}px`;
        if (html.scrollTop > 50) {
          position = 'fixed';
          classHeader = 'header-scrolled';
          top = 0;
        } else {
          position = 'relative';
          top = null;
          classHeader = '';
        }
        if (html.scrollTop > 75) {
          classFavicon = 'logo-header-favicon resized';
          classHeader = 'header-scrolled header-favicon';
        } else {
          classFavicon = '';
        }
      } else {
        position = 'relative';
        top = null;
        height = null;
      }

      setPositonNav({
        position,
        classHeader,
        height,
        top,
        classFavicon,
      });
    };
    document.addEventListener('scroll', createFixed);
    // AE: WE CAN KEEP THIS HERE BECAUSE IS A COMMON COMPONENT OF THE DASHBOARD, TO DO A BETTER LOGIC IT SHOULD BE MOVED TO A BETTER PLACE
    return () => {
      document.removeEventListener('scroll', createFixed);
      let stateForResults = { ...contentResults, searchResults: {} };
      dispatch({ type: 'MODIFY_SECTION', parameter: 'contentResults', value: stateForResults });
      dispatch({ type: 'MODIFY_SECTION', parameter: 'unseenNumbersCurrentList', value: {} });
    };
  }, []);

  const fixedPathNames = ['/', '/pricing', '/for-parliamentarians', '/data-hub'];
  const [positionNav, setPositonNav] = useState({ position: 'relative', classHeader: '', classFavicon: '' });
  const isFixedPathName = () => {
    let isFixed = false;
    fixedPathNames.forEach((item) => {
      if (item === location.pathname) {
        isFixed = true;
      }
    });
    return isFixed;
  };

  const headerContainer = useRef(null);

  const sentimentPage = location.pathname.toLowerCase() === '/sentiment';
  const slackIntegrationPage = location.pathname.toLowerCase() === '/integration-with-slack';
  const noSpecificPages = !sentimentPage && !slackIntegrationPage;

  const logoUrl = `${slackIntegrationPage ? '/' : isAuthenticated ? `/monitor-inbox/key-updates${inboxSection ? location.search : ''}${location.hash}` : '/'}`;
  const { resetReference } = useResetReference();

  const conditionTopNav = isAuthenticated || searchResultsSection;
  const { specificPaths, clickOnSearchItem } = useCloseSectionsCondition();
  const hamburguerMenu = useRef(null);
  const toggleMenu = () => {
    dispatch({ type: 'SHOW_HIDE_MENU', value: !isHiddenMenu });
  };

  const calculateTransform = () => {
    let topReference = !!headerContainer.current ? headerContainer.current.getBoundingClientRect().top : 0;
    return `-${topReference}px`;
  };

  return (
    <>
      {!isAuthenticated && <LoggedOutTopHeader />}
      <TrialBanner />
      <header
        className={`main-header ${!isMobile ? positionNav.classHeader : ''} ${isAuthenticated ? 'header-user-logged' : 'header-user-logout'}`}
        style={{ position: positionNav.position, top: positionNav.top }}
        ref={headerContainer}
      >
        <>
          {/*LOGO ON MOBILE */}
          <div
            className={`hide-lg px-3 pb-lg-3 header-mobile row mx-0 align-items-center ${isAuthenticated || sentimentPage || slackIntegrationPage ? 'justify-content-between ' : ''} mobile-loggedout-header`}
          >
            <div style={{ width: '26px' }} />
            <div className={` ${noSpecificPages ? 'flex-grow-1 text-center' : ''}`}>
              <Link to={logoUrl} className={noSpecificPages ? '' : 'd-lg-inline-block'}>
                <img
                  src={`${process.env.REACT_APP_CDNURL}/images/${christmasCondition() ? 'logo_white-christmas3.png' : 'logo_white.svg'}`}
                  alt='sidebar-icon'
                  className={`${noSpecificPages ? 'logo' : `policy-logo ${christmasCondition() ? 'christmas-logo' : ''}`}`}
                />
              </Link>
            </div>

            {isMobile && (
              <>
                {sentimentPage || slackIntegrationPage ? (
                  <SentimentButtons slackIntegrationPage={slackIntegrationPage} />
                ) : isAuthenticated ? (
                  <ProfileButton />
                ) : (
                  ''
                )}
              </>
            )}
          </div>

          {/*NAV PAGES */}
          <div
            className={`mx-auto main-content-wrapper row mx-auto align-items-center justify-content-between nav-wrapper-container resize-elements-container ${conditionTopNav ? '' : 'px-md-5 px-3'}`}
          >
            <div
              className={`show-lg ${conditionTopNav ? `side-left-element ${newAgencyPlan ? 'side-left-element-new-agency' : ''} pl-3 pl-lg-5` : ''}`}
            >
              {positionNav.classHeader?.includes('header-scrolled') ? (
                <div className='logo-header-scrolled-container'>
                  <Link
                    to={logoUrl}
                    className={`d-lg-inline-block logo-header-scrolled ${positionNav.classFavicon} ${christmasCondition() ? 'logo-part-christmas' : ''}`}
                  >
                    <img
                      className='logo-part logo-part-1'
                      src={`${process.env.REACT_APP_CDNURL}/images/logo-part-1.svg`}
                      alt='logo part 1'
                    />
                    <img
                      className='logo-part logo-part-2'
                      src={`${process.env.REACT_APP_CDNURL}/images/logo-part-2.svg`}
                      alt='logo part 2'
                    />
                    <img
                      className={`logo-part logo-part-3 `}
                      src={`${process.env.REACT_APP_CDNURL}/images/${christmasCondition() ? 'logo-part-3-christmas1.png' : 'logo-part-3.svg'}`}
                      alt='logo part 3'
                    />
                    <img
                      className='logo-part logo-part-4'
                      src={`${process.env.REACT_APP_CDNURL}/images/logo-part-4.svg`}
                      alt='logo part 4'
                    />
                  </Link>
                </div>
              ) : (
                <Link
                  onClick={() => {
                    resetClientNavigation();
                  }}
                  to={logoUrl}
                  className='d-lg-inline-block header-logo-element'
                >
                  <img
                    src={`${process.env.REACT_APP_CDNURL}/images/${christmasCondition() ? 'logo_white-christmas3.png' : 'logo_white.svg'}`}
                    alt='Logo'
                    className={`policy-logo ${christmasCondition() ? 'christmas-logo' : ''}`}
                  />
                </Link>
              )}
            </div>
            {conditionTopNav ? (
              <div className='main-content flex-grow-1 header-container d-flex justify-content-between align-items-center flex-column-reverse flex-lg-row pr-xl-5 px-md-4 px-3'>
                {!isMobile && (
                  <>
                    {noSpecificPages && (
                      <>
                        {newAgencyPlan ? (
                          <div className='flex-grow-1'>
                            <ClientNavigation />
                          </div>
                        ) : (
                          <NavPages />
                        )}
                      </>
                    )}
                  </>
                )}

                <div
                  className={`flex-centered justify-content-end ${specificPaths || clickOnSearchItem ? '' : 'main-header-actions-buttons'} ${newAgencyPlan ? 'pl-3' : ''}`}
                >
                  {/* SEARCHBAR MODIFIED JUST FOR THE HEADER */}
                  {noSpecificPages && readyKeywordLists && (
                    <>
                      <Link
                        className={`simple-searchbar mr-lg-3 flex-centered ${searchResultsSection ? 'simple-searchbar-active' : ''}`}
                        to={'/search-results/key-updates'}
                        data-tooltip-content={'Search'}
                        data-tooltip-id={'general-tooltip'}
                        onClick={() => {
                          if (getUrlParam('search') || getUrlParam('or')) {
                            resetReference();
                          }
                          closeButtonFunctionality();
                        }}
                      >
                        <span className='icon-search-medium' />
                      </Link>
                      {parliamentarianUsers(code) && (
                        <NavLink
                          to={policymakerUrl}
                          className={({ isActive }) => `calendar-top-nav-icon mr-3 ${isActive ? 'active' : ''}`}
                          data-tooltip-content={location.pathname.startsWith('/policymakers') ? '' : 'Policymakers'}
                          data-tooltip-id={'general-tooltip'}
                        >
                          <span className='icon-team' />
                        </NavLink>
                      )}
                      <NavLink
                        to='/calendar'
                        className={({ isActive }) => `calendar-top-nav-icon mr-3 ${isActive ? 'active' : ''}`}
                        data-tooltip-content={location.pathname.startsWith('/calendar') ? '' : 'Calendar'}
                        data-tooltip-id={'general-tooltip'}
                        onClick={() => {
                          closeButtonFunctionality();
                        }}
                      >
                        <span className='icon-calendar' />
                      </NavLink>
                      {!isMobile && (
                        <>
                          <SavedItemsIcon />
                          {agencyUser && isAuthenticated && enableReports && <ProspectiveButton />}
                        </>
                      )}
                      <CreateKeywordListButton />
                    </>
                  )}
                  <LoginButtonsComponent sentimentPage={sentimentPage} slackIntegrationPage={slackIntegrationPage} />
                </div>
              </div>
            ) : (
              <>
                {!isMobile && <>{noSpecificPages && <NavPages />}</>}
                <LoginButtonsComponent sentimentPage={sentimentPage} slackIntegrationPage={slackIntegrationPage} />
              </>
            )}
          </div>
        </>
      </header>
      {noSpecificPages && (
        <>
          <button
            className={`d-lg-none hamburger hamburger--collapse general-button`}
            type='button'
            ref={hamburguerMenu}
            onClick={() => {
              toggleMenu();
              hamburguerMenu.current.classList.toggle('is-active');
            }}
            style={{
              transform: `translateY(${isHiddenMenu ? 0 : calculateTransform()})`,
              top: `${headerContainer?.current?.getBoundingClientRect()?.top + 20}px`,
            }}
          >
            <span className='hamburger-box'>
              <span className='hamburger-inner'></span>
            </span>
          </button>
        </>
      )}
      <div
        className={`close-menu container-close-menu hide-lg ${isHiddenMenu ? 'd-none' : 'd-block'}`}
        onClick={() => {
          dispatch({ type: 'SHOW_HIDE_MENU', value: true });
          hamburguerMenu.current.classList.remove('is-active');
        }}
      />
      {isMobile && (
        <div className='header-mobile-sidenav'>
          <NavigationSidebar />
        </div>
      )}
      <div style={positionNav?.position === 'fixed' ? { height: positionNav.height } : null} />
    </>
  );
};

const LoginButtonsComponent = (props) => {
  const isMobile = useIsMobile();
  const { isAuthenticated } = useAuth0();
  const { sentimentPage, slackIntegrationPage } = props;

  return (
    <>
      {!isMobile && (
        <>
          {sentimentPage || slackIntegrationPage ? (
            <SentimentButtons slackIntegrationPage={slackIntegrationPage} />
          ) : (
            <>{isAuthenticated ? <ProfileButton /> : <LoginButtons />}</>
          )}
        </>
      )}
    </>
  );
};

export { christmasCondition };
export default Navigation;
