import React, { useRef, useState } from 'react';
import { formatGeneralDate } from '../../Dashboard/helpers/formatGeneralDate';
import { useHeightContainer } from '../../Dashboard/utilities/useHeightContainer';
import userFiltersOptions from '../UserFiltersOptions';
import TeamFilters from './TeamFilters';
import CreateANewTeamPopUp from './CreateANewTeamPopUp';
import useOutsideElement from '../../Dashboard/utilities/useOutsideElement';
import DeleteUserPopup from '../../Settings/Team/DeleteUserPopup';
import SelectOfHiddenItems from '../../Settings/Team/selectOfHiddenItems';
import RoleSelect from '../../Settings/Team/roleSelect';
import CustomScrollbar from '../../Common/CustomScrollbar';
import HiddenItemsKeysPopUp from './HiddenItemsKeysPopUp';
import { useNavigate } from 'react-router-dom';
import { useAdminAgencyUser } from './UserSideNav';
import SelectClientsItemsAdmin from './AdminUserPage/SelectClientsItemsAdmin';

const UserList = (props) => {
  const {
    teamMembers,
    setTeamMembers,
    isLoadingFilters,
    sendASetEmailPasswordEmail,
    teamId,
    deleteUser,
    setUserToDeleteId,
    organisation,
  } = props;

  const [heightContainer, containerRef] = useHeightContainer();
  const [isOpenAddATeamPopup, setIsOpenAddATeamPopup] = useState(false);
  const [showDeleteUser, setShowDeleteUser] = useState(false);
  const [сhangingRoleUserIds, setChangingRoleUserIds] = useState([]);
  const [showKeysPopup, setShowKeysPopUp] = useState(false);

  const navigate = useNavigate();
  const { agencyUser, newAgencyPlan } = useAdminAgencyUser();

  return (
    <>
      {isLoadingFilters && <p>Loading...</p>}
      {!isLoadingFilters && (
        <>
          <TeamFilters
            {...props}
            filtersOptions={userFiltersOptions}
            setIsOpenAddATeamPopup={setIsOpenAddATeamPopup}
            organisation={organisation}
          />
          <div className='box-top-rounded bg-white admin-general-content'>
            <div ref={containerRef} />
            <CustomScrollbar
              className={'main-content-scrollbar content-page-scrollbar'}
              style={{ height: `${heightContainer}px` }}
              maximalThumbYSize={100}
            >
              <div>
                {teamMembers.length > 0 && (
                  <div>
                    <div className='row mx-0 d-none d-lg-flex py-3 px-4 topic-list-titles main-sticky bg-white'>
                      <div className='col-lg-6'>
                        <h3>User</h3>
                      </div>
                      <div className='col-lg-3'>
                        <h3>Role</h3>
                      </div>
                      <div className='col-lg-3'>
                        <h3>Created date</h3>
                      </div>
                      <div className='col-lg-4'>
                        <h3>
                          {agencyUser ? 'Clients' : 'Topics'}{' '}
                          <span className='pointer light-link ml-2' onClick={() => setShowKeysPopUp(true)}>
                            Key
                          </span>
                        </h3>
                      </div>
                    </div>
                    {teamMembers.map((item) => {
                      const { fullName, emailAddress, teamRole, createdAt, auth0Id, id } = item;
                      return (
                        <div
                          key={`user-${auth0Id}`}
                          className='section-title row mx-0 action-state-hover p-2 pb-3 p-md-4 align-items-center admin-topic-list-rows'
                        >
                          <div className='col-lg-6'>
                            <p>{fullName}</p>
                            <p className='main-light-text' style={{ overflowWrap: 'break-word' }}>
                              {emailAddress}
                            </p>
                            <div className='d-flex mt-2'>
                              <button
                                className='general-button outline-active-button main-light-text paragraph-p3 edit-email-alert-button nowrap-item'
                                onClick={() => {
                                  if (!organisation?.planCode?.startsWith('PolicyInfluence')) {
                                    navigate(`/admin/organisation/${teamId}/users/${id}`, { replace: true });
                                  }
                                }}
                                data-tooltip-content={
                                  organisation?.planCode?.startsWith('PolicyInfluence')
                                    ? 'Cannot add alerts for influence accounts'
                                    : undefined
                                }
                                data-tooltip-id='settings-tooltip'
                                data-tooltip-place={'top'}
                              >
                                Edit alerts <span className='icon-edit-pencil'></span>
                              </button>
                              <button
                                className='send-password-email-button outline-active-button paragraph-p3 general-button ml-3 nowrap-item'
                                onClick={(e) => {
                                  let userAuth0Id = auth0Id;
                                  sendASetEmailPasswordEmail({ e, userAuth0Id });
                                }}
                              >
                                Send password email
                              </button>
                            </div>
                          </div>
                          <div className='col-lg-3 mt-2 mt-lg-0'>
                            <RoleSelect
                              item={item}
                              teamMembers={teamMembers}
                              setTeam={setTeamMembers}
                              teamPage
                              adminPage
                              disabled={item.teamRole === 'Owner'}
                              teamRole={teamRole}
                              setChangingRoleUserIds={setChangingRoleUserIds}
                              сhangingRoleUserIds={сhangingRoleUserIds}
                            />
                          </div>

                          <div className='col-lg-3 mt-2 mt-lg-0'>
                            <p>{formatGeneralDate(createdAt)}</p>
                          </div>
                          <div className='flex-grow-1 align-self-lg-center pr-2 pr-0 mt-2 mt-lg-0 pl-3 pl-lg-0'>
                            {newAgencyPlan ? (
                              <SelectClientsItemsAdmin
                                userItem={item}
                                teamRole={teamRole}
                                disabled={сhangingRoleUserIds.includes(id)}
                              />
                            ) : (
                              <SelectOfHiddenItems
                                userItem={item}
                                agencyUser={agencyUser}
                                teamRole={teamRole}
                                disabled={сhangingRoleUserIds.includes(id)}
                                adminPage
                              />
                            )}
                          </div>
                          <div className='d-flex flex-centered  mt-2 mt-lg-0'>
                            {teamRole !== 'Owner' ? (
                              <TeamActions
                                id={id}
                                setUserToDeleteId={setUserToDeleteId}
                                setShowDeleteUser={setShowDeleteUser}
                              />
                            ) : (
                              <div style={{ width: '46px' }}></div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {teamMembers.length === 0 && (
                  <p className='p-5 text-center main-light-text title-h4 mt-5'>No results</p>
                )}
              </div>
            </CustomScrollbar>
          </div>
          <CreateANewTeamPopUp
            {...props}
            setIsOpenAddATeamPopup={setIsOpenAddATeamPopup}
            isOpenAddATeamPopup={isOpenAddATeamPopup}
          />
          <DeleteUserPopup
            setShowPopup={setShowDeleteUser}
            showPopUp={showDeleteUser}
            deleteUser={deleteUser}
            onAfterClose={() => setUserToDeleteId(null)}
          />
        </>
      )}
      <HiddenItemsKeysPopUp isOpen={showKeysPopup} setIsOpen={(val) => setShowKeysPopUp(val)} />
    </>
  );
};

const TeamActions = (props) => {
  const { id, setUserToDeleteId, setShowDeleteUser } = props;
  const [showActions, setShowActions] = useState(false);
  const hideItem = () => {
    setShowActions(false);
  };
  const itemElement = useRef(null);
  useOutsideElement(itemElement, hideItem);

  return (
    <div className='text-right position-relative' ref={itemElement}>
      <span
        className={`team-action-selector ml-2 mr-0 ${showActions ? 'team-action-selector-selected' : ''}`}
        data-tooltip-content={'Manage user'}
        data-tooltip-id={'settings-tooltip'}
        data-tooltip-place='top'
        onClick={() => {
          setShowActions(!showActions);
        }}
      />
      {showActions && (
        <div className='team-actions py-1 px-3 filters-container' style={{ minWidth: '150px' }}>
          <button
            className='general-button team-action-item text-red text-left'
            onClick={() => {
              setUserToDeleteId(id);
              setShowDeleteUser(true);
            }}
          >
            Delete user
          </button>
        </div>
      )}
    </div>
  );
};

export default UserList;
