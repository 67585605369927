import useGeneralApiCall from '../Dashboard/apiCalls/useGeneralApiCall';

const useAiAssistantWidgetFunctions = () => {
  const { generalApiCall } = useGeneralApiCall();

  const aiChatCall = async ({ objectId, objectType: contentType, newChatMessage, historicChatMessages }) => {
    let method = 'post';
    let pathname = '/api/ai-assistant/content-chat';
    let requestProperties = {
      objectId,
      contentType,
      newChatMessage,
      historicChatMessages,
    };

    const result = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      notShowErrorMessage: true,
    });
    return result;
  };

  return {
    aiChatCall,
  };
};

export default useAiAssistantWidgetFunctions;
