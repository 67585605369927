import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { store } from '../../../Store';
import useDetectActiveFilters from '../useDetectActiveFilters';
import masterTopicObject from '../../MainSearchBar/masterTopicObject';
import useIsMobile from '../../utilities/useIsMobile';
import { useNavigate } from 'react-router-dom';
import getUrlParam from '../../utilities/getUrlParam';
import { useFilters } from '../../Filters/FiltersContext';
import LocalStorage from '../../../../utils/LocalStorage';

const ClearFiltersButton = ({ stakeholdersSubNav, contactsSubNav }) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { search, activePlan, keywordsLists } = state;
  const { list, prevList } = search;
  const { isAuthenticated } = useAuth0();
  const { detectActiveFilters } = useDetectActiveFilters();
  const navigate = useNavigate();
  const isMobile = useIsMobile(1280);
  const topicParam = getUrlParam('topic-id');

  const applyTopicCondition = stakeholdersSubNav && topicParam === 'all';
  const { clearFilters } = useFilters();
  return (
    <>
      {(detectActiveFilters() ||
        ((list?.id || (getUrlParam('topic-id') && getUrlParam('topic-id') !== 'all')) && stakeholdersSubNav)) &&
        isAuthenticated && (
          <button
            className={`general-button ${
              stakeholdersSubNav
                ? 'sort-button clear-all-stakeholders'
                : contactsSubNav
                  ? 'general-button sort-button d-lg-block top-nav-create-contact-button action-delete-button px-2 px-sm-4'
                  : 'simple-link-button action-delete-text d-none d-lg-block'
            }`}
            onClick={() => {
              clearFilters();
              if (stakeholdersSubNav) {
                dispatch({
                  type: 'MODIFY_SECTION',
                  parameter: 'search',
                  value: {
                    ...search,
                    query: '',
                    list: applyTopicCondition && prevList ? prevList : masterTopicObject(activePlan, keywordsLists),
                  },
                });
                LocalStorage.removeItem('contentFilters');
                let topicIdToUrl = applyTopicCondition && prevList?.id ? prevList.id : 'all';
                navigate({ search: `topic-id=${topicIdToUrl}` });
              }
            }}
          >
            {stakeholdersSubNav
              ? isMobile
                ? 'See all'
                : applyTopicCondition
                  ? 'See relevant stakeholders'
                  : 'See all stakeholders'
              : 'Clear filters'}
          </button>
        )}
    </>
  );
};

export default ClearFiltersButton;
