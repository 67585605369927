import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import Dashboard from '../components/Dashboard';

const DataHubRedirect = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    const newPath = location.pathname.replace('/data-hub', '/analytics');
    const search = location.search;

    return <Navigate replace to={`${newPath}${search}`} />;
  }

  return <Dashboard />;
};

export default DataHubRedirect;
