import React from 'react';
import AddEmailDomainForm from './AddEmailDomainForm';
import ModalComponent from '../../../Common/Modal';

const AddEmailDomainModal = ({
  setExpandLastDomain,
  closeModal: closeModalProp,
  teamId,
  loadInitialInformation,
  isAdminPage,
  domainsList = [],
  senderAddressesPage,
  teamName,
  openState,
}) => {
  const { isOpen, setIsOpen } = openState ?? {};
  const closeModal = (value) => {
    setIsOpen(value);
    closeModalProp();
  };
  return (
    <ModalComponent maxWidth={605} isOpen={isOpen} setIsOpen={closeModal}>
      <div>
        <h3 className='pt-4 topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 px-3'>
          {senderAddressesPage ? 'Add a sender email address' : 'Add and verify domain'}{' '}
          {teamName ? `for ${teamName}` : ''}
        </h3>
        <AddEmailDomainForm
          setExpandLastDomain={setExpandLastDomain}
          teamId={teamId}
          loadInitialInformation={loadInitialInformation}
          isAdminPage={isAdminPage}
          domainsList={domainsList}
          closeModal={closeModal}
          senderAddressesPage={senderAddressesPage}
        />
      </div>
    </ModalComponent>
  );
};

export default AddEmailDomainModal;
