import React, { useEffect } from 'react';
import { useContext } from 'react';
import { store } from '../../../Store';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import normalize from '../../utilities/normalizeString';
import array_move from '../../helpers/arrayMove';
import { useState } from 'react';
import InfluenceNotIncludedFeaturesPopup from '../CrmContacts/InfluenceNotIncludedFeaturesPopup';
import { useResearchItemsUrl } from '../../Navigation/NavPages';
import { NavLink, useLocation } from 'react-router-dom';

const RenderLink = (props) => {
  const location = useLocation();

  const { item, closeMenu, setShowPopup, setTypePopup } = props;
  const { stakeholderTopicParam } = useResearchItemsUrl();

  const { name, icon, disabled } = item;
  let link = name;
  if (name === 'Directory') {
    link = 'directory';
  } else if (name === 'My lobbying material' || name === 'Lobbying material') {
    link = `/influence/lobbying-material`;
  } else if (name === 'Stakeholder mapping') {
    link = `/influence/stakeholder-mapping${stakeholderTopicParam}`;
  } else if (name === 'Email campaigns') {
    link = 'emails';
  }

  return (
    <li key={normalize(name)}>
      <NavLink
        to={
          name === 'Email performance'
            ? `/influence/${normalize(link)}`
            : name === 'My lobbying material' || name === 'Lobbying material' || name === 'Stakeholder mapping'
              ? link
              : `/influence/${normalize(link)}`
        }
        state={name === 'Email performance' ? { prepopulatedActiveTab: 'Performance' } : {}}
        className={({ isActive }) =>
          `${isActive ? 'active' : ''} sidebar-item ${normalize(name === 'Inbox' ? 'influence-inbox' : name)}-a ${
            name === 'My lobbying material' && location.pathname === '/influence/lobbying-material' ? 'active' : ''
          }`
        }
        onClick={(e) => {
          if (disabled && setShowPopup) {
            e.preventDefault();
            let createTypePopup = () => {
              switch (name) {
                case 'Stakeholder mapping':
                  return 'stakeholders';
                case 'Email performance':
                  return 'analytics';
                case 'Email campaigns':
                  return 'email';
                default:
                  return 'contacts';
              }
            };
            setTypePopup(createTypePopup());
            setShowPopup(true);
          } else {
            if (closeMenu) {
              closeMenu();
            }
          }
        }}
      >
        <div className='row mx-0 align-items-center'>
          <div className={`icon-container-${icon}`}>
            <span className={`icon-${icon}`} />
          </div>
          <span>{name}</span>
        </div>
      </NavLink>
    </li>
  );
};

const InfluenceCrmSideBar = ({ closeMenu, mobile }) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { crmContactsQtty, hideInfluenceSidebar, activePlan } = state;
  const [typePopup, setTypePopup] = useState('contacts');
  const { enableCrm, enableStakeholders } = activePlan;
  const [showPopup, setShowPopup] = useState(false);

  const location = useLocation();
  useEffect(() => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'hideInfluenceSidebar',
      value: false,
    });
  }, [location.pathname]);

  const { newAgencyPlan } = useTopicOrClientWord();

  const crmPeopleSections = [
    { name: 'Stakeholder mapping', icon: 'graph', disabled: !enableStakeholders },
    { name: 'Directory', disabled: !enableCrm, icon: 'directory' },
    { name: 'My groups', disabled: !enableCrm, icon: 'team' },
  ];
  const crmConversationSections = [
    { name: 'Email campaigns', disabled: !enableCrm, icon: 'email' },
    { name: 'Email performance', disabled: !enableCrm, icon: 'bars' },
    // { name: "Inbox" },
  ];

  let crmCampaignSections = [
    { name: 'Lobbying material', icon: 'briefing-material' },
    { name: 'Engagement statistics', icon: 'statistics' },
  ];

  if (newAgencyPlan) {
    crmCampaignSections.push({
      name: 'AI assistant',
      icon: 'bot',
    });
  }

  let menuItems = [
    {
      name: 'People',
      items: crmPeopleSections,
      includeCrmContactsQtty: true,
      titleClass: 'sidenav-subtitle-influence-page',
    },
    {
      name: 'Emails',
      items: crmConversationSections,
    },
    {
      name: `${newAgencyPlan ? 'Content' : 'Lobbying material'}`,
      items: crmCampaignSections,
    },
  ];

  if (!enableStakeholders) {
    let position = menuItems.findIndex((item) => item.name === 'Lobbying material');
    menuItems = array_move(menuItems, position, 0);
  }

  return (
    <>
      {mobile ? (
        <div>
          <ul className='px-0 mb-0 overflow-hidden sidenav-list unstyled-list sidebar-influence'>
            {menuItems.map((section) => {
              const { items, includeCrmContactsQtty, name } = section;
              let properties = { closeMenu, setShowPopup, setTypePopup };
              if (includeCrmContactsQtty) {
                properties = { ...properties, crmContactsQtty };
              }
              return (
                <React.Fragment key={normalize(name)}>
                  {items.map((item) => {
                    return <RenderLink key={`item-${normalize(item.name)}`} {...properties} item={item} />;
                  })}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      ) : (
        !hideInfluenceSidebar && (
          <div>
            <ul className='px-0 mb-0 overflow-hidden sidenav-list unstyled-list sidebar-influence'>
              {menuItems.map((section) => {
                const { items, includeCrmContactsQtty, name, titleClass } = section;
                let properties = { setShowPopup, setTypePopup };
                if (includeCrmContactsQtty) {
                  properties = { ...properties, crmContactsQtty };
                }
                return (
                  <React.Fragment key={normalize(name)}>
                    <h3 className={`mt-4 mb-3 title-h5-m-bold pl-lg-3 ${titleClass ?? ''}`}>{name}</h3>
                    {items.map((item) => {
                      return <RenderLink key={`item-${normalize(item.name)}`} {...properties} item={item} />;
                    })}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        )
      )}
      <InfluenceNotIncludedFeaturesPopup showPopup={showPopup} type={typePopup} setShowPopup={setShowPopup} />
    </>
  );
};

export default InfluenceCrmSideBar;
