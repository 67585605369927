import { NavLink, useLocation } from 'react-router-dom';
import normalize from '../../utilities/normalizeString';
import { useAuth0 } from '@auth0/auth0-react';
import getUrlParam from '../../utilities/getUrlParam';
import { useAllowRightPanel } from './MentionsByTopic/ChartDataTable';
import { store } from '../../../Store';
import { useContext } from 'react';

const useCreateAnalyticsSections = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan } = state;
  const { enableStakeholders } = activePlan;
  const { isAuthenticated } = useAuth0();
  const analyticsSectionsBase = [
    { name: 'Mentions', icon: 'statistics', dashboardName: 'Parliamentary mentions' },
    { name: 'Tweets', icon: 'twitter' },
    {
      name: 'Most vocal policymakers',
      dashboardName: !isAuthenticated ? 'Key policymakers' : null,
      icon: 'team',
      link: isAuthenticated ? `/analytics/dashboard` : '/data-hub/dashboard',
    },
    { name: 'Sentiment snapshot', icon: 'snapshot' },
    { name: 'Sentiment changes', icon: 'changes' },
    { name: 'Interest by region', icon: 'location' },
    {
      name: 'Top MPs and peers',
      dashboardTitle: 'Top MPs and peers',
      icon: 'graph',
      disabled: !isAuthenticated || (isAuthenticated && !enableStakeholders),
      blocked: !isAuthenticated || (isAuthenticated && !enableStakeholders),
    },
  ];

  const createAnalyticsSections = () => {
    const finalSections = [...analyticsSectionsBase];
    if (process.env.REACT_APP_ENABLE_TWITTER === 'false') {
      let TweetsPosition = finalSections.findIndex((item) => item.name === 'Tweets');
      if (TweetsPosition >= 0) {
        finalSections.splice(TweetsPosition, 1);
        /* let interestByRegionPosition = finalSections.findIndex((item) => item.name === 'Interest by region');
        array_move(finalSections, interestByRegionPosition, 1); */
      }
    }
    return finalSections;
  };

  const analyticsSections = createAnalyticsSections();
  return { analyticsSections };
};

const AnalyticsSidebar = (props) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const { analyticsSections } = useCreateAnalyticsSections();
  const { showPanelWithTitle } = useAllowRightPanel();

  const { clickOnMostVocalState } = props;

  return (
    <div className='pt-lg-1 analytics-sidenav'>
      <ul className='px-0 mb-0 overflow-hidden sidenav-list'>
        <li>
          <NavLink
            to={`/${isAuthenticated ? 'analytics' : 'data-hub'}/dashboard${location.search}`}
            className={({ isActive }) => `sidebar-item dashboard-a ${isActive ? 'active' : ''}`}
          >
            <div className='flex-centered'>
              <span className='icon-container-dashboard'>
                <span className='political-updates-nav-icon icon-dashboard' />
              </span>
              <span>Dashboard</span>
            </div>
          </NavLink>
        </li>
        <h3 className='mt-4 mb-3 title-h5-m-bold pl-lg-3'>Charts</h3>
        {analyticsSections.map((item) => {
          const { name, icon, disabled, blocked, link, dashboardName } = item;
          const stakeholdersCondition = name === 'Top MPs and peers' && blocked;
          const mostVocalCondition = name === 'Most vocal policymakers';
          return (
            <li key={normalize(name)}>
              <NavLink
                to={
                  link
                    ? `${link}${location.search}`
                    : `/${isAuthenticated ? 'analytics' : 'data-hub'}/${normalize(name)}${location.search}`
                }
                className={({ isActive }) =>
                  `sidebar-item ${normalize(name)}-a ${isActive ? `${mostVocalCondition ? 'none' : 'active'}` : ''}`
                }
                onClick={(e) => {
                  if (disabled) {
                    e.preventDefault();
                  }
                  if (stakeholdersCondition) {
                    showPanelWithTitle(
                      `See who's shaping the debate on ${!!getUrlParam('search') ? decodeURIComponent(getUrlParam('search')) : 'any issue'}`,
                      true
                    );
                  }
                  if (mostVocalCondition && !!clickOnMostVocalState) {
                    clickOnMostVocalState();
                  }
                }}
              >
                <div className='flex-centered'>
                  <span className={`icon-container-${icon}`}>
                    <span className={`political-updates-nav-icon icon-${icon}`} />
                  </span>
                  <span>
                    {dashboardName ?? name} {blocked && <span className='icon-padlock ml-2 mr-1 paragraph-p3' />}
                  </span>
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { useCreateAnalyticsSections };
export default AnalyticsSidebar;
