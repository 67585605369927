import { useAuth0 } from '@auth0/auth0-react';
import Axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useRef } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import { store } from '../../../Store';
import normalize from '../../utilities/normalizeString';
import useSetFiltersToLocalStorageByType from '../useSetFiltersToLocalStorageByType';
import { colourStyles } from './SelectFilterUtils';
import CustomHighlighter from '../../utilities/CustomHighlighter';
import useGetAccessToken from '../../apiCalls/useGetAccessToken';

const MembershipSelectFilter = ({ disabled, filter, selectOption, useInPopUp, isExpanded, singleItem }) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { net_api_url, team } = state;
  const { type, options, subtype } = filter;
  const { getAccessToken } = useGetAccessToken();
  const { isAuthenticated } = useAuth0();
  const { setFiltersLocalStorageByType } = useSetFiltersToLocalStorageByType();
  const [hasSearched, setHasSearched] = useState(false);

  let axiosCancelToken = useRef(null);

  const createRoute = () => {
    switch (subtype) {
      case 'MembershipOfAPPGs':
        return 'appg';
      case 'MembershipOfSelectCommittees':
        return 'select-committee';
      case 'MembershipOfLordsSelectCommittees':
        return 'lords-select-committee';
      case 'CommitteeForPublication':
        return 'committee-for-publications';
      default:
        return null;
    }
  };

  const getTeamMembersOptions = (inputValue) => {
    const membersStringified = team?.allMembers
      .map((member) => {
        return {
          fullName: member.fullName,
          emailAddress: member.emailAddress,
          id: member.id,
        };
      })
      .map((member) => JSON.stringify(member));

    const filteredMembers = membersStringified
      .filter((memberStr) => memberStr.toLowerCase().includes(inputValue.toLowerCase()))
      .map((memberStr) => JSON.parse(memberStr));
    return filteredMembers.map((member) => {
      return {
        label: `${member.fullName} <${member.emailAddress}>`,
        value: `${member.id}`,
        hash: `${member.id}`,
      };
    });
  };

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        selectRef.current.focus();
      }, 100);
    }
  }, [isExpanded]);

  const getAsyncOptions = async (inputValue) => {
    setHasSearched(inputValue.trim() !== '');
    const urlRoute = createRoute();
    if (urlRoute) {
      let token = isAuthenticated ? await getAccessToken() : '';
      let url = `${net_api_url}/api/autocomplete/${urlRoute}?term=${inputValue}`;

      if (axiosCancelToken.current) {
        axiosCancelToken.current.cancel();
      }

      axiosCancelToken.current = Axios.CancelToken.source();

      let requestOptions = {
        cancelToken: axiosCancelToken.current.token,
      };

      if (isAuthenticated) {
        requestOptions.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      let callResults = await Axios.get(url, requestOptions);
      let results = callResults.data;
      return results;
    } else {
      if (subtype === 'ContactAddedByUser') {
        return getTeamMembersOptions(inputValue);
      }
    }
  };

  //AE: Source https://github.com/JedWatson/react-select/issues/614
  const getOptions = useCallback(
    _.debounce((inputText, callback) => {
      getAsyncOptions(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  const selectRef = useRef(null);

  const checkIfOptionSelected = (value) => {
    return !!options.filter((option) => option.name === value && option.selected).length;
  };

  const handleChange = (selected, action) => {
    if (action.option) {
      const lastSelected = {
        name: action.option.label,
        selected: true,
        filterValue: subtype === 'ContactAddedByUser' ? action.option.value : action.option.label,
      };

      let valueToState = [...options, lastSelected];

      //remove previous value if exists
      if (checkIfOptionSelected(action.option.label)) {
        valueToState = valueToState.filter((option) => !(option.name === action.option.label));
      }
      valueToState = valueToState.filter((option) => !(option.name === action.option.label && !option.selected));
      selectOption({ filter, newValue: valueToState });

      if (!useInPopUp) {
        setFiltersLocalStorageByType({ type, options: valueToState });
      }
    } else if (!!selected && singleItem) {
      selectOption({
        filter,
        newValue: [
          {
            ...selected,
            name: selected?.label,
            filterValue: selected.value ?? selected.label,
          },
        ],
      });
    }
  };

  const Option = (props) => {
    const input = props.selectProps.inputValue;
    const option = props.data.label;
    const value = props.data.value;
    const { isSelected, isFocused } = props;

    const optionRef = useCallback(
      (el) => {
        if (el) {
          const label = el.querySelector('label');
          const text = el.querySelector('label>span');

          let { width: labelWidth } = label.getBoundingClientRect() ?? {};
          let { width: spanWidth } = text.getBoundingClientRect() ?? {};
          let paddingLeft = 34;

          if (spanWidth > labelWidth - paddingLeft) {
            el.setAttribute(
              'data-tooltip-class-name',
              'header-tooltip pricing-tooltip-description table-item-cell-tooltip'
            );
            el.setAttribute('data-tooltip-content', option);
            el.setAttribute('data-tooltip-id', 'general-tooltip');
          }
        }
      },
      [input, option]
    );

    return (
      <div
        className={`email-component-select-option  ${
          checkIfOptionSelected(option) ? 'email-component-select-option-selected ' : ''
        }`}
        style={{
          backgroundColor: isSelected ? '#ebf7fb' : isFocused ? 'rgba(0, 148, 204, 0.04)' : 'transparent',
        }}
      >
        <components.Option {...props}>
          <div className={`checkbox-item filter-item-${normalize(value)}`} key={option} ref={optionRef}>
            <input
              id={normalize(value)}
              onChange={() => {}}
              type={`checkbox`}
              checked={checkIfOptionSelected(option)}
            />
            <label htmlFor={normalize(value)}>
              <CustomHighlighter highlightClassName='highlight-text' searchWord={input} textToHighlight={option} />
            </label>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <div className='box-rounded mb-4 membership-main-container'>
      <div className='filter-select-container'>
        <span className='main-search-icon' />
        <AsyncSelect
          ref={selectRef}
          loadOptions={getOptions}
          cacheOptions={true}
          components={{
            Option,
            MultiValueContainer: () => null,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
          }}
          isMulti={!singleItem}
          openMenuOnFocus={singleItem}
          noOptionsMessage={() => {
            if (!hasSearched) return null;
            return (
              <>
                {subtype === 'MembershipOfAPPGs'
                  ? 'No APPGs found'
                  : subtype === 'ContactAddedByUser'
                    ? 'No team members found'
                    : 'No committees found'}
              </>
            );
          }}
          styles={colourStyles}
          placeholder={
            subtype === 'MembershipOfAPPGs'
              ? 'Search APPGs'
              : subtype === 'ContactAddedByUser'
                ? 'Search team members'
                : 'Search committees'
          }
          onChange={handleChange}
          isDisabled={disabled}
          openMenuOnClick={singleItem}
          hideSelectedOptions={false}
          classNamePrefix={'react-custom-select-contacts'}
          onInputChange={(newValue) => {
            if (newValue.trim() === '') setHasSearched(false);
          }}
        />
      </div>
    </div>
  );
};

export default MembershipSelectFilter;
