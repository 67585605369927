import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';
import ModalComponent from '../../../Common/Modal';

const ContentPopup = (props) => {
  const { title, features } = props;

  return (
    <div className='bg-white rounded-lg text-center relative'>
      <div className='d-lg-flex'>
        <div className='bg-main-blue pl-lg-2 py-5 text-left'>
          <img
            className='statistics-popup-logo'
            alt='statistics-logo'
            src={`${process.env.REACT_APP_CDNURL}/images/logo_white.svg`}
          />

          <ul className='policy-list text-white text-left title-h5-m pt-5 mt-4 statistics-popup-list px-3 pl-lg-5 pr-lg-0'>
            <li className='pl-4 mb-4 py-2 nowrap-item bg-white text-main-blue statistics-list-active-item '>
              <span className='inline-block title-h3 mr-3 icon-small-check' />
              {features?.[0] ?? 'Stakeholder mapping'}
            </li>
            <li className='pl-4 mb-4 py-2 nowrap-item '>
              <span className='inline-block title-h3 mr-3 icon-small-check' />
              Monitor multiple topics
            </li>
            <li className='pl-4 mb-4 py-2 nowrap-item '>
              <span className='inline-block title-h3 mr-3 icon-small-check' />
              Analytics on any policy area
            </li>
          </ul>
        </div>
        <div className='grow px-4 px-lg-5 pb-5 mb-3'>
          <p className='title-h3 text-center mt-3 pt-3 pt-lg-5 px-lg-5 mb-4'>
            {title ?? 'Upgrade to Pro, Mogul or Enterprise to access stakeholder mapping'}
          </p>
          <img
            className='w-100'
            alt='stakeholder'
            src={`${process.env.REACT_APP_CDNURL}/images/influence/20230129-stakeholder-image.png`}
          />
          <div className='text-center text-lg-right mt-4 mt-lg-0'>
            <Link className='action-button rounded-button py-2 px-5 title-h5' to={`/pricing`}>
              <span className='px-4'>
                Upgrade now
                <span className='icon-long-arrow-right paragraph-p5 inline-block ml-2' />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const StakeholdersFeaturesUpgradePopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <Portal>
      <ModalComponent maxWidth={1000} isOpen={showPopUp} setIsOpen={setShowPopup}>
        <ContentPopup {...props} />
      </ModalComponent>
    </Portal>
  );
};

const StakeholdersCrmPopup = (props) => {
  const { setShowPopup, showPopUp } = props;
  return (
    <StakeholdersFeaturesUpgradePopup
      setShowPopup={setShowPopup}
      showPopUp={showPopUp}
      title={'Upgrade to Mogul or Enterprise to access the CRM'}
      features={['Stakeholder CRM']}
    />
  );
};

export { StakeholdersCrmPopup };
export default StakeholdersFeaturesUpgradePopup;
