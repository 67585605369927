import React, { useState } from 'react';
import CreateEmailAlertForm, { useDefaultValuesEmailAlert } from '../../../Settings/Topics/CreateEmailAlertForm.js';
import ModalComponent from '../../../Common/Modal.js';

const AdminCreateEmailAlert = (props) => {
  const {
    emailAlertId,
    isOpenEditEmailAlert,
    setIsOpenEditEmailAlert,
    setEmailAlertId,
    emailAlerts,
    keywordsLists,
    createUpdateEmailAlert,
    deleteEmailAlert,
    teamSubscriptions,
    userInformation,
    client,
  } = props;
  const { hasSlackConfigured } = userInformation;
  const { enableTwitter } = teamSubscriptions;

  const [isSaving, setIsSaving] = useState(false);

  const { createEmailAlertData } = useDefaultValuesEmailAlert({ enableTwitter, keywordsLists, emailAlerts });

  const [emailAlertData, setEmailAlertData] = useState(createEmailAlertData({ emailAlertId }));

  const closeModal = () => {
    setIsOpenEditEmailAlert(false);
    setTimeout(() => {
      setEmailAlertId('');
    }, 200);
  };

  const saveEmailAlertFunction = async () => {
    setIsSaving(true);
    let saveFunction = await createUpdateEmailAlert(emailAlertData);
    if (!!saveFunction) {
      closeModal();
    }
    setIsSaving(false);
  };

  const deleteEmailAlertFunction = async () => {
    setIsSaving(true);
    let deleteFunction = await deleteEmailAlert(emailAlertData.id);
    if (deleteFunction) {
      setIsSaving(false);
      closeModal();
    }
  };

  const FormProperties = {
    keywordsLists,
    emailAlertData,
    setEmailAlertData,
    isSaving,
    saveEmailAlertFunction,
    deleteEmailAlertFunction,
    adminSubscriptions: teamSubscriptions,
    hasSlackConfigured,
    enableTwitter,
    adminPage: true,
    userInformation,
    client,
  };

  return (
    <ModalComponent isOpen={isOpenEditEmailAlert} setIsOpen={closeModal} maxWidth={660}>
      <CreateEmailAlertForm {...FormProperties} />
    </ModalComponent>
  );
};

export default AdminCreateEmailAlert;
