import React from 'react';
import './Election2024.scss';
import { useHeightContainer } from '../Dashboard/utilities/useHeightContainer';
import Manifestos from './Manifestos';
import LobbyingElection from './LobbyingElection';
import ElectionSearch from './ElectionSearch';
import { ReactComponent as ManifestosIcon } from './ManifestosIcon.svg';
import ElectionSectionTitle from './ElectionSectionTitle';
import useIsMobile from '../Dashboard/utilities/useIsMobile';
import { getUKDayTime } from '../Dashboard/utilities/normalizedDateUK';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Routes, NavLink, useLocation, Route } from 'react-router-dom';
import CustomScrollbar from '../Common/CustomScrollbar';

export const Election2024Links = [
  {
    icon: 'manifestos-icon',
    label: 'Explore manifestos',
    path: 'manifestos',
    useScrollbar: true,
    shortName: 'Manifestos',
  },
  {
    icon: 'briefing-material',
    label: 'Add lobbying material',
    path: 'lobbying',
    shortName: 'Lobby',
  },
];

export const PoliticalLandscapeLinks = [
  {
    icon: 'search-medium',
    label: 'Monitor issues',
    path: 'monitor-issues',
    shortName: 'Monitor',
  },
  {
    icon: 'statistics',
    label: 'Data hub',
    path: 'data-hub',
    shortName: 'Analytics',
  },
];

let menuItems = [
  {
    name: 'Election 2024',
    items: Election2024Links,
  },
  {
    name: 'Political landscape',
    items: PoliticalLandscapeLinks,
  },
];

function Election2024() {
  const isMobile = useIsMobile();
  return (
    <div className='election-2024 flex-column align-items-center h-100'>
      {!isMobile && (
        <video autoPlay muted loop id='parliament-bk'>
          <source
            src={`${process.env.REACT_APP_CDNURL}/images/manifesto/election-bk-video-${getUKDayTime()}.mp4`}
            type='video/mp4'
          />
        </video>
      )}
      <div className='z-10 position-relative'>
        <h1
          className={`text-center mt-5 pt-2 font-weight-bold ${getUKDayTime() === 'dark' && !isMobile ? 'text-white' : ''} election-header`}
        >
          {'Navigate the 2024 General Election'}
        </h1>
        {isMobile && (
          <div className='d-flex px-3 pt-3 justify-content-center'>
            <ElectionSectionTitle enableDropdown />
          </div>
        )}

        <div className='d-flex justify-content-center mt-4 mt-lg-5 pt-1 px-2 px-lg-3 election-main-container'>
          <ElectionSideBar />
          <div className='box-rounded ml-lg-5 election-content-box'>
            <Routes>
              <Route path='/' element={<Navigate to={`/general-election-2024/${Election2024Links[0].path}`} />}></Route>
              <Route
                path='/key-updates'
                element={<Navigate to={`/general-election-2024/${Election2024Links[0].path}`} />}
              />
              <Route
                path='/time-table'
                element={<Navigate to={`/general-election-2024/${Election2024Links[0].path}`} />}
              />
              <Route path='/manifestos' element={<Manifestos />} />
              <Route
                path='/candidates'
                element={<Navigate to={`/general-election-2024/${Election2024Links[0].path}`} />}
              />
              <Route path='/lobbying' element={<LobbyingElection />} />
              <Route path='/monitor-issues' element={<ElectionSearch />} />
              <Route path='/data-hub' element={<ElectionSearch />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

const ElectionSideBar = ({ mobile }) => {
  const location = useLocation();
  const [heightContainer, containerRef] = useHeightContainer();
  // const [isCollapsed, setIsCollapsed] = useState(false);
  const isCollapsed = false;
  const { isAuthenticated } = useAuth0();
  return (
    <div
      className={`box-rounded p-2 pl-lg-3 py-lg-4 election-sidebar ${isCollapsed ? 'collapsed pr-lg-3' : 'pr-lg-4'} ${mobile ? 'election-sidebar-mobile login-navbar' : 'd-none d-lg-block'}`}
    >
      <ul
        className='list-unstyled p-0 m-0'
        ref={containerRef}
        style={{ height: `${mobile ? (isAuthenticated ? 208 : 287) : heightContainer}px` }}
      >
        <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
          {menuItems
            .filter((item) => (isAuthenticated ? item.name !== 'Political landscape' : true))
            .map((item, i) => {
              const { items, name } = item;
              return (
                <React.Fragment key={`nav-${name}`}>
                  {!mobile && !isCollapsed && <h5 className={`${i !== 0 ? 'mt-4' : ''} mb-3 title-h4-bold`}>{name}</h5>}
                  {items.map((item, index) => {
                    return (
                      <li key={`item-${index}`}>
                        <NavLink
                          to={`/general-election-2024/${item.path}`}
                          className={`${mobile ? 'paragraph-p1' : 'title-h5'} election-link${location.pathname.endsWith(item.path) ? '-active' : ''} ${isCollapsed ? 'election-link-collapsed' : ''}`}
                          activeClassName='active-election-link'
                        >
                          {item.icon === 'manifestos-icon' ? (
                            <ManifestosIcon height={19} width={19} />
                          ) : (
                            <span className={`election-link-icon icon-${item.icon}`}></span>
                          )}
                          <span className='election-sidebar-name'>{isCollapsed ? item?.shortName : item.label}</span>
                        </NavLink>
                      </li>
                    );
                  })}
                </React.Fragment>
              );
            })}

          {/* <button
            className={`w-100 rounded action-state-hover flex-centered general-button sidebar-collapse-button text-center ${isCollapsed ? 'collapsed flex-column ' : 'no-collapsed'}`}
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
          >
            <span className={`icon-${isCollapsed ? 'expand' : 'collapse'}`} />
            <span className='election-sidebar-name main-subtle-text'>
              {isCollapsed ? 'Expand' : 'Collapse side bar'}
            </span>
          </button> */}
        </CustomScrollbar>
      </ul>
    </div>
  );
};

const ElectionContentBox = (props) => {
  const { header, children, notShowHeader } = props;
  const location = useLocation();
  const page = location.pathname.split('/').pop();
  const sidebarLinks = [...menuItems.reduce((prev, curr) => [...prev, ...curr.items], [])];
  const section = sidebarLinks.find((item) => item.path === page);
  const { useScrollbar, label } = section ?? {};
  const [heightContainer, containerRef] = useHeightContainer();

  return (
    <>
      {!notShowHeader && (
        <div className='election-content-box-header py-4 px-3 px-lg-5 d-flex justify-content-center flex-column'>
          {header ?? <ElectionSectionTitle title={label} />}
        </div>
      )}

      <div ref={containerRef} style={{ height: `${heightContainer}px` }} className='position-relative'>
        {!useScrollbar ? (
          <>{children}</>
        ) : (
          <CustomScrollbar className={'simple-scrollbar'} maximalThumbYSize={100}>
            {children}
          </CustomScrollbar>
        )}
      </div>
    </>
  );
};

export { ElectionContentBox, ElectionSideBar };
export default Election2024;
