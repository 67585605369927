import AnalyticsSidebar from './ui/AnalyticsSidebar';
import AnalyticsSubNav from './ui/AnalyticsDashboard/AnalyticsSubNav';
import { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../Store';
import getUrlParam from '../utilities/getUrlParam';
import MentionsByTopicContainer from './MentionsByTopicContainer';
import RelevantStakeholdersContainer from './RelevantStakeholdersContainer';
import Loading from '../../Login/Loading';
import AnalyticsDashboardContainer from './AnalyticsDashboardContainer';
import SentimentOverTimeContainer from './SentimentOverTimeContainer';
import OverallSentimentContainer from './OverallSentimentContainer';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import StakeHolderNoMogulUsers from '../StakeHolders/StakeHolderNoMogulUsers';
import { useAuth0 } from '@auth0/auth0-react';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord';
import AnalyticsRightPanelPopup from './ui/AnalyticsRightPanelPopup';
import useAnalyticsHashesFunctions from './useAnalyticsHashesFunctions';
import { createAnalyticsState } from './createAnalyticsState';
import InterestByRegionContainer from './InterestByRegionContainer';
import EmptyMessage from '../components/EmptyMessage';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsPopup } from '../Navigation/NotFeaturePopups';
import { useSetListsIds } from '../MainSearchBar/setListIds';
import AnalyticsPage from './ui/AnalyticsDashboard/AnalyticsPage';

const Analytics = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists, readyKeywordLists, activePlan, analyticsState } = state;
  const { enableAnalytics } = activePlan;
  const { restrictPanel } = analyticsState;
  const { isAuthenticated } = useAuth0();

  const { addAnalyticsFilterHashToUrl } = useAnalyticsHashesFunctions();

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { agencyUser } = useTopicOrClientWord();
  const { getStoredListIds } = useSetListsIds();

  const topicParameter = getUrlParam('topic-id');
  const searchParameter = getUrlParam('search');
  const loadingCondition = isLoading || (!topicParameter && isAuthenticated && !searchParameter);
  const dataHubPage = location.pathname === '/data-hub' && !searchParameter;

  const initialCall = useRef(null);
  initialCall.current = () => {
    if (readyKeywordLists && isAuthenticated && !searchParameter) {
      setIsLoading(true);
      let topicParam = getUrlParam('topic-id');
      let noTopic = true;
      let pathname =
        location?.pathname === '/analytics/tweets' && process.env.REACT_APP_ENABLE_TWITTER === 'false'
          ? '/analytics/mentions'
          : location.pathname;
      if (topicParam) {
        let topic = keywordsLists.find((item) => item?.id === parseInt(topicParam));
        if (!!topic) {
          noTopic = false;
          const params = new URLSearchParams(location.search);
          params.set('topic-id', topic.id);
          navigate({ pathname, search: params.toString() }, { replace: true });
        }
      }
      if (noTopic) {
        //AE: Wait while the view parameter is updated
        setTimeout(() => {
          let listsIdsStored = getStoredListIds({});
          let storageList = !!listsIdsStored && keywordsLists.length > 0 ? listsIdsStored?.[0] : null;

          let defaultList = keywordsLists.find((item) => item.id === activePlan?.defaultKeywordListId)
            ? activePlan?.defaultKeywordListId
            : null;

          let topicId =
            storageList ??
            keywordsLists.find((item) => (defaultList !== null ? item.id === defaultList : item.id !== null))?.id;

          const params = new URLSearchParams(window.location.search);
          params.set('topic-id', topicId ?? 'none');
          navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
        }, 1);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialCall.current();
  }, [keywordsLists]);

  useEffect(() => {
    if (!isLoading) {
      //AE: TODO: this can be part of the initial call function. Probably needs to modify the hook and all the analytics components
      addAnalyticsFilterHashToUrl({});
    }
  }, [analyticsState, location.pathname, isLoading]);

  useEffect(() => {
    let initialState = createAnalyticsState(true);
    if (!isLoading && !location.pathname.includes('interest-by-region')) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'analyticsState',
        value: initialState,
      });
    }
  }, [location.pathname]);

  const scrollBarRef = useRef();
  const clickOnMostVocalState = () => {
    if (
      scrollBarRef.current?.scrollValues?.scrollTop > 0 ||
      (location.pathname !== '/analytics/dashboard' && location.pathname !== '/data-hub')
    ) {
      scrollBarRef.current?.scrollTo(0, 0);
      setTimeout(() => {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'highlightVocalState',
          value: true,
        });
      }, 500);
    } else {
      setTimeout(() => {
        dispatch({
          type: 'MODIFY_SECTION',
          parameter: 'highlightVocalState',
          value: true,
        });
      }, 500);
    }
  };

  return (
    <>
      {loadingCondition && <Loading />}
      {!loadingCondition && (
        <>
          {dataHubPage ? (
            <AnalyticsPage />
          ) : (
            <>
              {enableAnalytics || !isAuthenticated || searchParameter ? (
                <>
                  <AnalyticsSubNav />
                  <div className={`row mx-auto main-content-wrapper py-3 pt-lg-5 pb-lg-0 flex-grow-1 `}>
                    <div className={`side-left-element d-none d-lg-block pl-lg-4`}>
                      <AnalyticsSidebar clickOnMostVocalState={clickOnMostVocalState} />
                    </div>
                    <div className={`main-content pr-xl-5 px-md-4 px-3 h-100`}>
                      <div className='d-flex flex-column h-100'>
                        <div className={`flex-grow-1 position-relative`}>
                          {topicParameter === 'none' ? (
                            <EmptyMessage />
                          ) : (
                            <Routes>
                              <Route
                                path={`/data-hub`}
                                element={
                                  isAuthenticated ? (
                                    <Navigate to={`/dashboard${location.search}`} />
                                  ) : (
                                    <div className='h-100' style={{ marginTop: '-0.25em' }}>
                                      <AnalyticsDashboardContainer scrollBarRef={scrollBarRef} />
                                    </div>
                                  )
                                }
                              />
                              <Route path={`/`} element={<Navigate to={`dashboard${location.search}`} />}></Route>
                              <Route
                                path={`dashboard`}
                                element={
                                  <div className='h-100' style={{ marginTop: '-0.25em' }}>
                                    <AnalyticsDashboardContainer />
                                  </div>
                                }
                              ></Route>
                              <Route path={`mentions`} element={<MentionsByTopicContainer />} />
                              <Route path={`interest-by-region`} element={<InterestByRegionContainer />} />
                              <Route path={`tweets`} element={<MentionsByTopicContainer />} />
                              <Route path={`top-mps-and-peers`} element={<RelevantStakeholdersContainer />} />
                              <Route path={`sentiment-changes`} element={<SentimentOverTimeContainer />} />
                              <Route path={`sentiment-snapshot`} element={<OverallSentimentContainer />} />
                            </Routes>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <ReactTooltip
                    effect={'solid'}
                    id={'analytics-tooltip'}
                    className={'general-tooltip pricing-feature-tooltip report-popup-tooltip header-tooltip'}
                    arrowColor={'transparent'}
                    place={'top'}
                    clickable={true}
                    globalEventOff={'click'}
                  />
                  {isAuthenticated && <AnalyticsRightPanelPopup isOpen={restrictPanel} />}
                  {!isAuthenticated && (
                    <AnalyticsPopup
                      isOpen={restrictPanel}
                      setIsOpen={(value) => {
                        let newState = {
                          ...analyticsState,
                          restrictPanel: value,
                          sideBarText: null,
                          useEmoji: false,
                        };
                        dispatch({
                          type: 'MODIFY_SECTION',
                          parameter: 'analyticsState',
                          value: newState,
                        });
                      }}
                    />
                  )}
                </>
              ) : (
                <StakeHolderNoMogulUsers
                  title={`Data that's off the charts`}
                  subtext={`Our reporting and analytics software gives you instant access to the insights that will transform your public affairs and campaigning work ${
                    agencyUser ? 'on behalf of your clients' : ''
                  } — no statistics degree required.`}
                  image={`/images/20230804-analytics-screenshot${agencyUser ? '-agency' : ''}.png`}
                  imageClass={'analytics-screenshot-image'}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Analytics;
