import { useContext, useMemo } from 'react';
import { store } from '../../Store';
import LocalStorage from '../../../utils/LocalStorage';

function useNewlyAddedContacts() {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { crmContactsQtty, newlyAddedContacts } = state;

  const firstTimeContactsAdded = useMemo(() => {
    return JSON.parse(LocalStorage.getItem('firstTimeContactsAdded'));
  }, []);

  const setNewlyAddedContacts = (arr) => {
    let addContacts;
    const contactIds = arr.map((contact) => contact.id);
    if (crmContactsQtty > 0) {
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'newlyAddedContacts',
        value: contactIds,
      });
    } else {
      if (!firstTimeContactsAdded) {
        LocalStorage.setItem(
          'firstTimeContactsAdded',
          JSON.stringify({
            firstTimeContactsAdded: true,
            animationShown: false,
          })
        );
      }
    }
    addContacts = arr.length;
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'crmContactsQtty',
      value: crmContactsQtty + addContacts,
    });
  };

  const checkContactNewlyAdded = (id) => {
    return newlyAddedContacts.includes(id);
  };

  const resetNewlyAddedContacts = () => {
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'newlyAddedContacts',
      value: [],
    });
  };

  return {
    setNewlyAddedContacts,
    checkContactNewlyAdded,
    resetNewlyAddedContacts,
  };
}

export default useNewlyAddedContacts;
