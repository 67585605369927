import React, { useContext } from 'react';
import { store } from '../../Store';
import { Link, useLocation } from 'react-router-dom';
import ModalComponent from '../../Common/Modal';
import useValidateContactsOversubscribed from '../utilities/useValidateContactsOversubscribed';

const ContactsOversubscribedInTrialModal = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { showContactsOversubscribedInTrialModal } = state;

  const location = useLocation();
  const { setShowContactsOversubscribedInTrialModal } = useValidateContactsOversubscribed();

  return (
    <ModalComponent
      maxWidth={420}
      isOpen={showContactsOversubscribedInTrialModal}
      setIsOpen={setShowContactsOversubscribedInTrialModal}
    >
      <>
        <div className='section-title px-4 pt-4 pb-3'>
          <h3 className='mb-0 title-h4'>
            <span className='icon-team paragraph-p2 mr-2' />
            <span>You've reached your trial limit</span>
          </h3>
        </div>
        <div className='px-4 py-3 section-title main-subtle-text'>
          <p>
            Whilst you're on trial, you're limited to{' '}
            <span className='font-weight-bold text-main-blue'>50 contacts.</span>
          </p>

          <p className='mb-0'>
            To add more, please upgrade to a Mogul or Enterprise subscription and select the number of contacts you
            require.
          </p>
        </div>
        <Link to={`/pricing?returnUrl=${encodeURIComponent(location.pathname)}`} className='py-3 d-block text-center'>
          <button
            onClick={() => setShowContactsOversubscribedInTrialModal(false)}
            className='px-3 py-2 action-button general-button'
          >
            Compare plans
          </button>
        </Link>
      </>
    </ModalComponent>
  );
};

export default ContactsOversubscribedInTrialModal;
