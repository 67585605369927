import React, { useRef, useState } from 'react';
import normalize from '../../../utilities/normalizeString';
import DropdownComponent from '../../../../Settings/WebsiteFeeds/DropdownComponent';
import useGroupsAlertsFunctions from '../../CrmGroups/useGroupsAlertsFunctions';
import { useParams } from 'react-router-dom';
import createNotification from '../../../../Settings/Utilities/CreateNotification';

const GroupSelectOptions = (alertState) => [
  {
    label: 'Daily summary of changes',
    description: 'Sent weekdays at around 5pm if changes are found',
    value: 'DailyCrmGroupChanges',
    successMessage: `Daily alerts for this group enabled`,
  },
  {
    label: 'Weekly summary of changes',
    description: 'Sent Fridays at around 5pm if changes are found',
    value: 'WeeklyCrmGroupChanges',
    successMessage: `Weekly alerts for this group enabled`,
  },
  {
    label: alertState ? 'Turn alerts for this group off' : 'Alerts off',
    value: 'off',
    successMessage: `Alerts for this group disabled`,
  },
];

function GroupAlertsSelect() {
  const { getAlertState } = useGroupsAlertsFunctions();

  const params = useParams();
  const { groupId } = params;

  const alertState = getAlertState(groupId);

  return (
    <div className={`ml-3 group-alerts-select`} style={{ minWidth: '146px' }}>
      <DropdownComponent
        fixedDropdown={true}
        mainItem={() => (
          <span className='hidden-lines hidden-one-line w-100'>
            {<span className={`icon-${!!alertState ? 'alert-medium' : 'alert-off'} pr-2`}></span>} Alerts{' '}
            {!!alertState ? 'on' : 'off'}
          </span>
        )}
        notCloseOnSelect
        fixedTopSpace={10}
        fixedRightSpace={-265}
        dropdownClass={'group-alerts-select-dropdown'}
        stickToRight={true}
      >
        <GroupAlertsDropdown alertState={alertState} groupId={groupId} />
      </DropdownComponent>
    </div>
  );
}

const GroupAlertsDropdown = (props) => {
  const { alertState, groupId } = props;
  const listsContainer = useRef(null);
  const { changeGroupAlert } = useGroupsAlertsFunctions();
  const [isLoading, setIsLoading] = useState(null);

  return (
    <div className='keyword-lists-website-feeds px-3'>
      <div
        className='login-navbar website-popup-container-lists large-container py-2'
        style={{ paddingBottom: '1px' }}
        ref={listsContainer}
      >
        {GroupSelectOptions(alertState).map((item, index) => {
          const { label, description, value, successMessage } = item;
          return (
            <button
              className={`settings-button setting-item general-button d-block text-left w-100 px-4 position-relative ${alertState === null ? 'text-main-blue' : ''}`}
              key={`date-${normalize(label)}${index}`}
              onClick={async () => {
                setIsLoading(index);
                await changeGroupAlert({ type: value, groupId });
                setIsLoading(null);
                createNotification('success', successMessage);
              }}
              disabled={value === alertState || isLoading !== null}
            >
              <p className='paragraph-p1 mb-0'>{label}</p>
              <span className='light-50-text paragraph-p2'>{description}</span>
              {(value === alertState || (alertState === null && value === 'off')) && (
                <span className='icon-tick topic-selected-mark paragraph-p4' />
              )}
              {isLoading === index && <i className='fas fa-spinner fa-spin topic-selected-mark'></i>}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default GroupAlertsSelect;
