import React, { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../../../Store';
import EmailPerformanceDropdown from '../../EmailPerformanceDropdown';
import CreateEmailLink from './CreateEmailLink';
import preventLink from '../../../TagsList/prevenLink';
import useOutsideElement from '../../../utilities/useOutsideElement';
import useEmailComponentFunctions from '../../CrmEmails/useEmailComponentFunctions';
import ModalComponent from '../../../../Common/Modal';
import useValidateContactsOversubscribed from '../../../utilities/useValidateContactsOversubscribed';
import { useNavigate, useParams } from 'react-router-dom';
import thousandCommas from '../../../helpers/thousandCommas';
import useTopicOrClientWord from '../../../hooks/useTopicOrClientWord';

function EmailPerformanceNav() {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { emailPerformanceResult, emailPerformanceOnLoad, listDomainsVerified, team } = state;

  const { myself } = team;
  const removeCondition = myself.role === 'Owner' || myself.role === 'Admin';
  const { newAgencyPlan } = useTopicOrClientWord();

  useEffect(() => {
    return () =>
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'emailPerformanceOnLoad',
        value: true,
      });
  }, []);

  return (
    <div className='influence-subnav-wrapper'>
      <div className='mx-auto main-content-wrapper flex-centered align-items-center resize-elements-container stakeholder-sub-nav'>
        {emailPerformanceResult.length > 0 || emailPerformanceOnLoad ? (
          <div className='flex-centered w-100 justify-content-between flex-grow-1'>
            <EmailPerformanceDropdown />
            {emailPerformanceResult.length > 0 && <EmailPerformanceActions removeCondition={removeCondition} />}
          </div>
        ) : (
          !emailPerformanceOnLoad && (
            <CreateEmailLink
              text={`Create email campaign `}
              additionalText={`${newAgencyPlan ? ` for ${team?.teamName}` : ''}`}
              redirectToEmails={
                !listDomainsVerified.verifiedDomains.length && !listDomainsVerified.verifiedEmailAddresses.length
              }
              noIcon
            />
          )
        )}
      </div>
    </div>
  );
}

const EmailPerformanceActions = (props) => {
  const { removeCondition } = props;
  const [showActions, setShowActions] = useState(false);
  const [isOpenClone, setIsOpenClone] = useState(false);

  const hideActions = () => {
    setShowActions(false);
  };

  const actionsElement = useRef();
  useOutsideElement(actionsElement, hideActions);
  const { showConfirmDiscardPopUp, setShowConfirmDiscardPopUp } = useEmailComponentFunctions();

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  return (
    <>
      <div className='position-relative' ref={actionsElement}>
        <button
          className={`indented-button general-button circle-button item-actions-button ml-3 ${showActions ? 'active' : ''}`}
          onClick={(e) => {
            preventLink(e);
            setShowActions(!showActions);
          }}
        >
          Item actions
        </button>
        {showActions && (
          <div className='box box-shadow py-2 item-actions-box reports-actions-box' style={{ width: '140px' }}>
            <button className='general-button' onClick={() => setIsOpenClone(true)}>
              Clone email
            </button>
            {removeCondition && (
              <button
                className='general-button delete-widget-button'
                onClick={() => {
                  validateContactsOversubscribed(() => setShowConfirmDiscardPopUp(true), 'discard_email');
                }}
              >
                Delete email
              </button>
            )}
          </div>
        )}
      </div>
      <DeleteEmailPopup isOpen={showConfirmDiscardPopUp} setIsOpen={setShowConfirmDiscardPopUp} />
      <CloneEmailPopup isOpen={isOpenClone} setIsOpen={setIsOpenClone} />
    </>
  );
};

const DeleteEmailPopup = (props) => {
  const { isOpen, setIsOpen } = props;
  const { removeEmail, isLoading } = useEmailComponentFunctions();

  return (
    <ModalComponent maxWidth={500} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
        <div className='text-center'>
          <h3 className='title-h3-bold mb-4'>You can't undo this action</h3>
          <>
            <p className='title-h5-m main-subtle-text mx-auto'>
              This email and all performance metrics related to it will be deleted for you and your colleagues
            </p>

            <div className='my-4 pt-2'>
              <button
                className='general-button danger-button px-4 py-2'
                onClick={async () => {
                  await removeEmail();
                }}
                disabled={isLoading}
              >
                {isLoading === 'delete' ? <i className='fas fa-spinner fa-spin mr-1'></i> : `Delete email`}
              </button>
            </div>
          </>
        </div>
      </div>
    </ModalComponent>
  );
};

const CloneEmailPopup = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { currentEmailDetails } = state;
  const { sendRecords } = currentEmailDetails ?? {};

  const params = useParams();
  const navigate = useNavigate();

  const { isOpen, setIsOpen } = props;

  return (
    <ModalComponent maxWidth={500} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2 '>
        <div className='text-center'>
          <>
            <p className='title-h5-m main-subtle-text mx-auto'>
              Do you want to include the same {sendRecords?.length > 1 ? thousandCommas(sendRecords?.length) : ''}{' '}
              recipient
              {sendRecords?.length > 1 ? 's' : ''}?
            </p>

            <div className='mt-3 mb-4 pt-2 flex-centered justify-content-center'>
              <button
                className='general-button outline-active-button px-4 mr-3 py-1'
                onClick={() => {
                  navigate(
                    { pathname: `/influence/emails/create` },
                    { state: { cloneEmailId: params.crmEmailId, notIncludeRecipient: true } }
                  );
                }}
              >
                No
              </button>
              <button
                className='btn btn-general action-button px-4 py-1'
                onClick={() => {
                  navigate({ pathname: `/influence/emails/create` }, { state: { cloneEmailId: params.crmEmailId } });
                }}
              >
                Yes
              </button>
            </div>
          </>
        </div>
      </div>
    </ModalComponent>
  );
};

export default EmailPerformanceNav;
