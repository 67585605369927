import React, { useRef } from 'react';
import useIsMobile from '../../../../utilities/useIsMobile';
const RecipientsSearchBar = ({ searchValue, setSearchValue }) => {
  const inputSearch = useRef();
  const isSmallMobile = useIsMobile(480);

  return (
    <div className='position-relative recipient-search-bar rounded-pill'>
      <div className={'flex-centered simple-searchbar-open-influence simple-searchbar-influence'}>
        <span className={`icon-search title-h5-m`} style={{ margin: '2px 11px 0 -1px', color: '#9c9c9c' }} />
        <div className='flex-grow-1'>
          <input
            className={`search-main-input w-100`}
            data-hj-allow
            type='text'
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            spellCheck={false}
            ref={inputSearch}
            placeholder={isSmallMobile ? 'Search' : 'Search for a contact'}
          />
        </div>
        <div
          className='close-icon-container'
          style={{
            visibility: searchValue !== '' ? 'visible' : 'hidden',
          }}
        >
          <button
            className='general-button close-icon'
            data-tooltip-content='Clear search'
            data-tooltip-id='general-tooltip'
            data-tooltip-place='right'
            onClick={(e) => {
              e.stopPropagation();
              setSearchValue('');
              inputSearch.current.focus();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RecipientsSearchBar;
