import React from 'react';
import ModalComponent from '../../Common/Modal';
const RemoveLogoPopup = (props) => {
  const { setShowRemoveLogoPopup, setOrganisationLogo, setIsSaved, organisationLogo, showRemoveLogoPopup } = props;

  return (
    <ModalComponent maxWidth={530} isOpen={showRemoveLogoPopup} setIsOpen={setShowRemoveLogoPopup}>
      <div className='pt-4'>
        <h3 className='topic-title mb-4 section-title pl-lg-5 pl-4 pb-3'>Remove your logo</h3>
        <div className='topic-email-alerts-content px-4 px-lg-5 py-3 '>
          <img
            className='img-fluid d-block mx-auto mb-4'
            src={organisationLogo}
            alt='organisation logo'
            style={{ maxWidth: '180px' }}
          />

          <p className='text-center mx-auto main-light-text'>
            Are you sure you want to remove your logo? We’ll replace it with a default image on all content created by
            you or your team
          </p>
          <div className='my-4 text-center'>
            <button
              className='general-button outline-button px-4 mr-3 py-1'
              onClick={() => {
                setShowRemoveLogoPopup(false);
              }}
            >
              Cancel
            </button>
            <button
              className='btn btn-general topic-delete-button py-1 remove-logo-button'
              onClick={() => {
                setOrganisationLogo(null);
                setIsSaved(null);
                setShowRemoveLogoPopup(false);
              }}
            >
              Yes, remove logo
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default RemoveLogoPopup;
