import React, { useContext, useState } from 'react';
import { store } from '../../Store.js';
import axios from 'axios';
import createNotification from '../Utilities/CreateNotification.js';
import getUrlParam from '../../Dashboard/utilities/getUrlParam.js';
import useSaveFunctions from './SaveFunctions';

const TopicSaveButton = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { IsSavingKeywordList, keywordsLists, remainingKeywords, InitialKeywordsState } = state;
  const { listPosition, showCreateEmailAlert } = props;
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const currentList = keywordsLists[listPosition];
  const { id, keywords, name } = currentList ?? {};
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const teamId = parseInt(getUrlParam('teamId'));

  const { callCreateKeywordList, callUpdateKeywordList } = useSaveFunctions({
    source,
    setIsLoadingButton,
    teamId,
    redirectToHome: false,
    showCreateEmailAlert: showCreateEmailAlert,
  });

  const initialTopic = InitialKeywordsState[listPosition];
  const currentTopic = keywordsLists[listPosition];
  const haveChanges = JSON.stringify(initialTopic) === JSON.stringify(currentTopic) ? false : true;

  const saveConditional =
    !IsSavingKeywordList && haveChanges && (remainingKeywords === 'unlimited' || remainingKeywords >= 0);
  return (
    <button
      tabIndex={-1}
      className={`general-button action-button px-3 ${saveConditional ? '' : 'button-disabled-action'}`}
      id='save-topic-button'
      onClick={async () => {
        if (saveConditional) {
          if (name === '') {
            return createNotification('danger', 'Please give your topic a name');
          } else if (keywords.length === 0) {
            return createNotification('danger', 'Please enter at least one keyword');
          } else {
            setIsLoadingButton(true);
            if (id === '') {
              await callCreateKeywordList();
            } else {
              await callUpdateKeywordList();
            }
          }
        }
      }}
    >
      {isLoadingButton ? <i className='fas fa-spinner fa-spin'></i> : 'Save'}
    </button>
  );
};

export default TopicSaveButton;
