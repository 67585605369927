import React, { useContext } from 'react';
import thousandCommas from '../../../helpers/thousandCommas';
import CustomHighlighter from '../../../utilities/CustomHighlighter';
import { store } from '../../../../Store';
import { components } from 'react-select';

export const ActionsBar = (props) => {
  const { selectRef, optionState } = props;
  const { localRecipients, setLocalRecipients } = optionState ?? {};
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const selectedOptions = selectRef?.current?.getValue() ?? [];

  const actionBtnText = () => {
    if (localRecipients.length > 0) {
      if (selectedOptions?.length > 0) {
        return 'Update recipients';
      } else {
        let number = !!localRecipients?.length ? localRecipients?.length : 0;
        return `Add ${thousandCommas(number)} recipient${number === 1 ? '' : 's'}`;
      }
    } else {
      return 'Add recipients';
    }
  };

  const handleUpdate = async () => {
    let newLocalRecipients = [...localRecipients];
    let valueToState = [
      ...selectedOptions.filter((item) => {
        return !newLocalRecipients.find((opt) => opt.id === item.id);
      }),
      ...newLocalRecipients.filter((item) => !item.toRemove),
    ];
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'emailSelectedContacts',
      value: valueToState.map((val) => ({
        id: val.id,
        email: val.email,
      })),
    });
    selectRef.current.setValue(valueToState, 'set-value');
    setLocalRecipients([]);
    selectRef.current.blur();
  };

  return (
    <div className='contacts-select-actions-bar p-3'>
      <button
        className='general-button py-1 paragraph-p2'
        onClick={(e) => {
          setLocalRecipients([]);
          selectRef.current.blur();
        }}
      >
        Cancel
      </button>
      <button
        className={`action-button py-1 add-recipients-button ml-2 paragraph-p2 nowrap-item ${localRecipients.length === 0 ? 'add-recipients-button-disabled' : ''}`}
        onClick={handleUpdate}
      >
        {actionBtnText()}
      </button>
    </div>
  );
};

export const SelectAllBtn = (props) => {
  const { selectRef, optionState } = props;
  const { localRecipients, setLocalRecipients } = optionState ?? {};
  const selectedValue = selectRef?.current?.getValue();
  const contactsResultOptions = selectRef?.current?.props?.options?.find((item) => item.label === 'contacts')?.options;
  const selectedAllCondition = contactsResultOptions?.every((item) => {
    let localRecipientsEntity = localRecipients.find((selected) => selected.id === item.id);
    return (
      (localRecipientsEntity || selectedValue.find((selected) => selected.id === item.id)) &&
      !localRecipientsEntity?.toRemove
    );
  });
  return (
    <div
      className='contacts-select-all-button'
      onClick={() => {
        let newContacts = contactsResultOptions;
        let valueToState = localRecipients.filter((contact) => !newContacts.find((value) => value.id === contact.id));
        let additionalValue = [];
        if (selectedAllCondition) {
          additionalValue = newContacts.map((item) => {
            if (selectedValue?.find((opt) => opt.id === item.id)) {
              return { ...item, toRemove: true };
            } else {
              return null;
            }
          });
        } else {
          additionalValue = newContacts.map((item) => {
            return { ...item, toRemove: false };
          });
        }
        setLocalRecipients([...valueToState, ...additionalValue.filter((item) => !!item)]);
      }}
    >
      {selectedAllCondition
        ? `Deselect ${thousandCommas(contactsResultOptions?.length)} matching`
        : `Select ${thousandCommas(contactsResultOptions?.length)} matching`}
    </div>
  );
};

const CrmContactsSelectOption = (props) => {
  const { sideBar, tagContacts, groupContacts, FindMoreContactsMessage, NoOptionsMessage, optionState } = props;
  const { tagOption, selectAllBtn, actionBar, isGroup } = props.data;
  const {
    localRecipients,
    setLocalRecipients,
    selectedTagOptions,
    setSelectedTagOptions,
    selectedGroupOptions,
    setSelectedGroupOptions,
  } = optionState;
  const { isSelected } = props;
  const input = props.selectProps.inputValue;
  const option = props.data.label;
  const value = props.data.value;
  const id = props.data.id;
  const disabled = props.data.disabled;
  const selected =
    (isSelected || !!localRecipients.find((item) => item.id === id)) &&
    !localRecipients.find((item) => item.id === id)?.toRemove;
  const selectedValue = props.getValue();
  const renderOptionWrapper = () => {
    return (
      <div className={`select-option-wrapper ${isGroup || tagOption ? 'select-option-wrapper-group' : ''}`}>
        {!disabled && !tagOption && !selectAllBtn && !actionBar && !isGroup && (
          <div className={`custom-checkbox-input`}>
            <input type={'checkbox'} checked={selected} onChange={() => {}} id={`crmContact-${value}-${id}`} />
            <label htmlFor={`crmContact-${value}-${id}`} />
          </div>
        )}
        {isGroup && <span className='icon-team d-inline-block' style={{ marginTop: '4px' }} />}
        {tagOption && <span className='icon-tag title-h3 d-inline-block' style={{ marginTop: '3px' }} />}
        <div className={`contacts-select-option-wrapper ${tagOption && !sideBar ? 'w-100' : ''}`}>
          <CustomHighlighter highlightClassName='highlight-text' searchWord={input} textToHighlight={option} />
        </div>
        {(tagOption || isGroup) && (
          <span className='option-group-count'>{`(${thousandCommas(props.data.contacts)})`}</span>
        )}
      </div>
    );
  };

  const contactsList = tagOption ? tagContacts : groupContacts;

  let isOptionChecked = !!contactsList[value]?.every(
    (contact) =>
      (!!localRecipients?.find((item) => item?.id === contact?.id && !item.toRemove) ||
        !!selectedValue?.find((item) => item?.id === contact?.id)) &&
      !localRecipients?.find((item) => item?.id === contact?.id)?.toRemove
  );

  let handleAddContacts = async () => {
    let contacts = contactsList[value] ?? [];
    let filteredLocalRecipients = localRecipients.filter(
      (item) => !contacts?.find((contact) => contact.id === item.id)
    );
    if (isOptionChecked) {
      setLocalRecipients([
        ...filteredLocalRecipients,
        ...contacts
          .map((tagContact) => {
            if (!!selectedValue?.find((item) => item?.id === tagContact?.id)) {
              return { ...tagContact, toRemove: true };
            } else {
              return null;
            }
          })
          .filter((item) => !!item),
      ]);
      if (tagOption) {
        setSelectedTagOptions([...selectedTagOptions.filter((item) => item !== value)]);
      } else {
        setSelectedGroupOptions([...selectedGroupOptions.filter((item) => item !== value)]);
      }
    } else {
      setLocalRecipients([...filteredLocalRecipients, ...contacts]);
      if (tagOption) {
        setSelectedTagOptions([...selectedTagOptions, value]);
      } else {
        setSelectedGroupOptions([...selectedGroupOptions, value]);
      }
    }
  };
  return disabled ? (
    option === 'find_more' ? (
      <FindMoreContactsMessage text={value} />
    ) : (
      <NoOptionsMessage inputValue={value} />
    )
  ) : (
    <div
      className={`email-component-select-option ${selectAllBtn ? 'w-100' : ''} ${actionBar && sideBar ? 'action-bar-option' : ''}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0',
        backgroundColor: selectAllBtn ? undefined : selected ? '#ebf7fb' : 'transparent',
      }}
    >
      {tagOption || isGroup ? (
        <div
          className={`contacts-select-local-option react-custom-select-contacts__option w-100 d-flex justify-content-between`}
          onClick={handleAddContacts}
        >
          {renderOptionWrapper()}
          {isOptionChecked && (
            <span
              className='icon-simple-check text-light-blue d-block paragraph-p3'
              style={{ marginRight: '2px', marginTop: '4px' }}
            ></span>
          )}
        </div>
      ) : sideBar ? (
        <div
          className='contacts-select-local-option react-custom-select-contacts__option w-100'
          onClick={() => {
            let optionToState = props.data;
            let filteredLocalRecipients = localRecipients.filter((item) => item.id !== id);
            if (selectedValue?.find((opt) => opt.id === id)) {
              if (localRecipients?.find((opt) => opt.id === id)?.toRemove) {
                optionToState.toRemove = false;
              } else {
                optionToState.toRemove = true;
              }
              setLocalRecipients([...filteredLocalRecipients, optionToState]);
            } else {
              if (localRecipients?.find((opt) => opt.id === id)) {
                setLocalRecipients([...filteredLocalRecipients]);
              } else {
                setLocalRecipients([...localRecipients, optionToState]);
              }
            }
          }}
        >
          {renderOptionWrapper()}
        </div>
      ) : (
        <components.Option {...props}>{renderOptionWrapper()}</components.Option>
      )}
    </div>
  );
};

export default CrmContactsSelectOption;
