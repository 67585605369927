import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { store } from '../Store';
import { ReportsPopup } from '../Dashboard/Navigation/NotFeaturePopups';
import { useAuth0 } from '@auth0/auth0-react';
import useTopicOrClientWord from '../Dashboard/hooks/useTopicOrClientWord';

const CreateReportFromUpdate = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { activePlan } = state;
  const { enableReports } = activePlan;
  const [isOpen, setIsOpen] = useState(false);

  const { currentNew, show, userContentPage } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const { embargoed } = currentNew ?? {};
  const { isAuthenticated } = useAuth0();

  const notEnableReports = !enableReports && isAuthenticated;
  const { agencyUser } = useTopicOrClientWord();

  return (
    <>
      {show && !agencyUser && (
        <div
          className={`py-3 pl-3 ${userContentPage ? '' : 'pr-3'} mb-3 box-rounded box-outstanding-reports bg-white related-content-create-report-from-update`}
        >
          <h3
            className={`${userContentPage ? 'paragraph-p2' : 'font-weight-bold'} flex-centered justify-content-between`}
          >
            <span>Create a report</span>{' '}
            <span
              className='icon-info'
              data-tooltip-content={`Create a PDF report with this update, and customise it with your commentary and other political content or analysis`}
              data-tooltip-id='general-tooltip'
              data-tooltip-class-name={'general-tooltip pricing-feature-tooltip report-popup-tooltip'}
            />
          </h3>
          <button
            className={`w-100 d-block text-left action-button px-3 py-2 general-button rounded-pill text-925 ${notEnableReports ? 'action-button-disabled report-button-disabled' : ''}`}
            data-tooltip-html={`${embargoed ? 'You cannot create a report on this update<br/> while it is under embargo' : ''}`}
            data-tooltip-id='general-tooltip'
            disabled={embargoed}
            onClick={async (e) => {
              if (enableReports && isAuthenticated) {
                dispatch({ type: 'MODIFY_SECTION', parameter: 'updateToCreateReport', value: currentNew });
                dispatch({
                  type: 'MODIFY_SECTION',
                  parameter: 'updateBeforCreateReportLocation',
                  value: location?.pathname,
                });
                navigate('/reports/create');
              } else {
                setIsOpen(true);
              }
            }}
          >
            <span className='icon-document title-h5 d-inline-block-centered specific-right-margin-11 centered-2' />
            Create a report{' '}
            {notEnableReports ? (
              <span className='icon-padlock ml-2 d-inline-block-centered centered-1' />
            ) : (
              <> from this update</>
            )}
          </button>
        </div>
      )}
      <ReportsPopup isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default CreateReportFromUpdate;
