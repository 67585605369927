import LocalStorage from '../../../utils/LocalStorage';

const updateUnreadItems = (props) => {
  const { idPosition, contentObject, getIdPosition } = props;
  let newSectionObject;
  if (idPosition !== null) {
    let feedItemFlags = contentObject?.feedItemFlags ?? [];
    let newFeedItemFlags;
    let index = getIdPosition();
    const { totalHits, hits } = contentObject;
    let newTotalHits = totalHits;
    let unreadFilter = LocalStorage.getItem('seeUnreadFilter');
    let newHits = [...hits];
    if (feedItemFlags.length > 0 && feedItemFlags[index] !== undefined) {
      newFeedItemFlags = feedItemFlags.slice();
      newFeedItemFlags[index].seen = true;
      if (!!unreadFilter && unreadFilter === 'true') {
        newTotalHits = totalHits - 1;
        newHits.splice(index, 1);
        newFeedItemFlags.splice(index, 1);
      }
    }
    newSectionObject = {
      ...contentObject,
      totalHits: newTotalHits,
      hits: newHits,
    };

    if (newFeedItemFlags) {
      newSectionObject.feedItemFlags = newFeedItemFlags;
    }
  }
  return newSectionObject;
};

export default updateUnreadItems;
