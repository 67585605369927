export const switchValuesBetweenTagsFilters = ({ newFilters, filter, componentFilters, newValue }) => {
  if (filter.type === 'TagIsNot') {
    const tagsFilter = componentFilters.find((filter) => filter.name === 'Tag is');
    const newTagsValue = tagsFilter.options.map((option) => {
      return {
        ...option,
        selected: !option.selected ? false : !!newValue.find((op) => op.name === option.name)?.selected ? false : true,
      };
    });
    newFilters = newFilters.map((filter) => {
      if (filter.name === 'Tag is') {
        return { ...filter, options: newTagsValue };
      } else {
        return filter;
      }
    });
  }

  if (filter.name === 'Tag is') {
    const isNotTagsFilter = componentFilters.find((filter) => filter.type === 'TagIsNot');
    const newTagIsNotValue = isNotTagsFilter?.options?.map((option) => {
      return {
        ...option,
        selected: !option.selected ? false : !!newValue.find((op) => op.name === option.name)?.selected ? false : true,
      };
    });
    newFilters = newFilters.map((filter) => {
      if (filter.type === 'TagIsNot') {
        return { ...filter, options: newTagIsNotValue };
      } else {
        return filter;
      }
    });
  }
  return newFilters;
};
