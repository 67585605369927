import { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TopicPicker from '../../Common/TopicPicker';
import { store } from '../../Store';
import arraysEqual from '../helpers/arrayEqual';
import getUrlParam from '../utilities/getUrlParam';
import useEditReferenceState from './useEditReferenceState';
import useResetReference from './useResetReference';

const ReferenceTopicWidget = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { referenceState, contentResults, activeResults } = state;
  const { activeReference } = referenceState;
  const { setShowPopup, keywordsLists, setKeywordsSection, enableAdvancedSearch } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const { editState, parliamentaryRecordWidgetSection } = useEditReferenceState();
  const { resetReference } = useResetReference();

  const searchFunction = ({ topic }) => {
    if (topic) {
      let pathname = location.pathname;
      pathname += `?topic-id=${topic.id === null ? 'all' : topic.id}`;
      dispatch({
        type: 'MODIFY_SECTION',
        parameter: 'referenceState',
        value: {
          activeReference: true,
          keywords: topic?.keywords ?? [],
          andKeywords: topic?.andKeywords ?? [],
          keywordExclusions: topic?.keywordExclusions ?? [],
          topic,
        },
      });
      navigate(`${pathname}${getUrlParam('contact-id') ? `&contact-id=${getUrlParam('contact-id')}` : ''}`);
    }
  };

  const closeButtonFunction = () => {
    navigate(`${location.pathname}?topic-id=none${location.hash}`);
    resetReference();
  };

  const categoryButtonProps = {
    referenceState,
    setShowPopup,
    setKeywordsSection,
  };

  const findTopic = ({ keywords, andKeywords, keywordExclusions }) => {
    return keywordsLists.find(
      (item) =>
        arraysEqual(keywords, item.keywords) &&
        arraysEqual(andKeywords, item.andKeywords) &&
        arraysEqual(keywordExclusions, item.keywordExclusions)
    );
  };

  const [defaultTopic, setDefaultTopic] = useState();
  const updateDefaultTopic = useRef();
  updateDefaultTopic.current = () => {
    if (getUrlParam('topic-id')) {
      let topicParam = getUrlParam('topic-id');
      let topicId = topicParam === 'all' ? null : parseInt(topicParam);
      setDefaultTopic(keywordsLists.find((item) => item.id === topicId));
    } else if (activeReference) {
      const { keywords, andKeywords, keywordExclusions, topic } = referenceState;
      setDefaultTopic(
        topic
          ? keywordsLists.find((item) => item.id === topic.id)
          : findTopic({ keywords, andKeywords, keywordExclusions })
      );
    }
  };

  useEffect(() => {
    updateDefaultTopic.current();
  }, [referenceState]);

  const createWidgetName = () => {
    if (parliamentaryRecordWidgetSection) {
      let { ParliamentaryRecord } = contentResults[activeResults];
      return `Browse ${getUrlParam('contact-id') ? `${ParliamentaryRecord?.contactIdInformation?.title}'s` : 'stakeholder'} contributions`;
    } else {
      return 'Advanced search';
    }
  };

  const topicIdCondition = getUrlParam('topic-id') && getUrlParam('topic-id') !== 'none';

  return (
    <>
      <h3 className='mb-3 title-h5-bold d-flex justify-content-between align-items-center'>
        <div>{createWidgetName()}</div>
        {!enableAdvancedSearch && (
          <div className='d-inline-flex justify-content-between flex-grow-1'>
            <span className='icon-padlock ml-2 paragraph-p3'></span>
            <span
              class='icon-info ml-2 main-subtle-text paragraph-p2'
              data-tooltip-class-name='header-tooltip tooltip-width'
              data-tooltip-content='Advanced search is available on Pro, Mogul or Enterprise'
              data-tooltip-id='general-tooltip'
            ></span>
          </div>
        )}
      </h3>
      {editState() && parliamentaryRecordWidgetSection ? (
        <div className={`reference-topic-picker ${topicIdCondition ? 'active' : ''}`}>
          <TopicPicker
            keywordsLists={keywordsLists.filter((item) => item.id !== null)}
            showTopicCases={topicIdCondition}
            searchFunction={searchFunction}
            closeButtonFunction={closeButtonFunction}
            defaultTopic={defaultTopic}
            fixedPosition
            customTopValue={36}
          />
        </div>
      ) : (
        <>
          <CategoryButton text={'Any of'} category={'keywords'} {...categoryButtonProps} />
          <CategoryButton text={'And also'} category={'andKeywords'} {...categoryButtonProps} />
          <CategoryButton text={'Exclude'} category={'keywordExclusions'} {...categoryButtonProps} />
          {activeReference && (
            <button
              className='general-button simple-link-button action-delete-text mt-3'
              onClick={() => {
                resetReference();
              }}
            >
              Clear search
            </button>
          )}
        </>
      )}
      {parliamentaryRecordWidgetSection && getUrlParam('search') && (
        <button
          className='general-button simple-link-button main-disabled-text mt-2 text-underline paragraph-p2'
          onClick={() => {
            setShowPopup(true);
          }}
        >
          {!editState() ? `Edit search` : 'Custom search'}
        </button>
      )}
    </>
  );
};

const CategoryButton = (props) => {
  const { text, category, setKeywordsSection, setShowPopup, referenceState } = props;
  const { keywords } = referenceState;
  const contentCategory = () => {
    return referenceState[category];
  };

  return (
    <button
      className='general-button box-rounded research-button mr-3 mr-lg-4 rounded-button'
      disabled={category !== 'keywords' && keywords.length === 0}
      onClick={() => {
        setShowPopup(true);
        setKeywordsSection(category);
      }}
    >
      {text}...
      <span
        className='research-category-number'
        style={{ visibility: contentCategory().length > 0 ? 'visible' : 'hidden' }}
      >
        {contentCategory().length} keyword{contentCategory().length === 1 ? '' : 's'}
      </span>
    </button>
  );
};

export { CategoryButton };
export default ReferenceTopicWidget;
