import React, { useContext, useEffect, useRef, useState } from 'react';
import { store } from '../../Store.js';
import Resources from './Resources.js';
import DaySelect from './DaySelect.js';
import HourSelect from './HourSelect.js';
import transformHour from './Helpers/TransformHour.js';
import FrequencySelect from './FrequencySelect.js';
import DropdownComponent from '../WebsiteFeeds/DropdownComponent.js';
import AddTopicButton from '../../Dashboard/Navigation/AddTopicButton.js';
import normalize from '../../Dashboard/utilities/normalizeString.js';
import createNotification from '../Utilities/CreateNotification.js';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord.js';
import { Link, useLocation } from 'react-router-dom';
import getUrlParam from '../../Dashboard/utilities/getUrlParam.js';
import CustomScrollbar from '../../Common/CustomScrollbar.js';

const useDefaultValuesEmailAlert = (props) => {
  const { enableTwitter, keywordsLists, emailAlerts, createAlertNameAndKeywordLists } = props;
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  let defaultContentTypesToReceive =
    'KeyUpdates, Consultations, Legislation, AnsweredWrittenQuestion, HansardContent, UserContent, CommonsLibraryArticles, Edms, CommitteePublications';
  //ALLOW TWEETS
  if (enableTwitter && showTweets) {
    defaultContentTypesToReceive += ', Tweets';
  }
  let immediateResources =
    'KeyUpdates, Consultations, Legislation, HansardContent, UserContent, CommonsLibraryArticles, Edms, CommitteePublications';
  let weeklyResources = defaultContentTypesToReceive;
  let defaultValues = [
    {
      frequency: 'Immediate',
      suggestedName: 'Immediate alert',
      timeOfDay: null,
      dayOfWeek: null,
      contentTypesToReceive: immediateResources,
    },
    {
      frequency: 'Weekdaily',
      suggestedName: 'Your daily digest',
      timeOfDay: '09:00:00',
      dayOfWeek: null,
      contentTypesToReceive: weeklyResources,
    },
    {
      frequency: 'TwiceWeekdaily',
      suggestedName: 'Your twice daily digest',
      timeOfDay: '09:00:00',
      secondTimeOfDay: '13:00:00',
      dayOfWeek: null,
      contentTypesToReceive: weeklyResources,
    },
    {
      frequency: 'Weekly',
      suggestedName: 'Your weekly digest',
      timeOfDay: '09:00:00',
      dayOfWeek: 'Friday',
      contentTypesToReceive: weeklyResources,
    },
  ];
  const defaultValueOfAlert = {
    name: 'Daily digest',
    keywordListIds: createAlertNameAndKeywordLists ? createAlertNameAndKeywordLists().keywordListIds : [],
    id: '',
    digestCombiningAllTopics: keywordsLists.length === 1,
    frequency: 'Weekdaily',
    timeOfDay: '09:00:00',
    dayOfWeek: null,
    contentTypesToReceive: defaultContentTypesToReceive,
    additionalName: '',
    alertTypes: ['Email'],
    sendEmbargoedKeyUpdatesImmediately: true,
  };

  const createEmailAlertData = ({ emailAlertId, defaultSlackAlert }) => {
    if (!!emailAlertId) {
      let item = emailAlerts.find((item) => item.id === emailAlertId);
      if (item) {
        let itemResources = item.contentTypesToReceive.split(', ');
        itemResources.forEach((item, index) => {
          if (item === 'HandPickedKeyUpdates' || item === 'HandPickedKeyUpdate') {
            itemResources[index] = 'KeyUpdates';
          }
        });
        if (!showTweets) {
          let tweetsPosition = itemResources.indexOf('Tweets');
          if (tweetsPosition >= 0) {
            itemResources.splice(tweetsPosition, 1);
          }
        }
        return {
          ...item,
          contentTypesToReceive: itemResources.join(', '),
          alertTypes: item?.alertTypes ? item.alertTypes.split(', ') : ['Email'],
        };
      } else {
        return defaultValueOfAlert;
      }
    } else if (defaultSlackAlert) {
      let values = defaultValues.find((item) => item.frequency === 'Immediate');
      return {
        ...values,
        name: values.suggestedName,
        keywordListIds: createAlertNameAndKeywordLists().keywordListIds,
        id: '',
        digestCombiningAllTopics: keywordsLists.length === 1,
        alertTypes: ['Slack'],
      };
    } else {
      return defaultValueOfAlert;
    }
  };

  return { defaultValues, defaultValueOfAlert, createEmailAlertData };
};

const KeyUpdatesUnderEmbargo = ({ sendEmbargoedKeyUpdatesImmediately, generalChangeProperty }) => {
  return (
    <div
      className='ml-0 box-rounded box-grey send-diary-emails alerts-form mb-4 w-100 d-flex align-items-center justify-content-between'
      style={{ paddingTop: '0.3em', paddingBottom: '0.3em' }}
    >
      <div className='checkbox-item d-flex align-items-center m-0'>
        <input
          id='sendEmbargoedKeyUpdatesImmediately'
          type='checkbox'
          onChange={(e) => {
            generalChangeProperty('sendEmbargoedKeyUpdatesImmediately', e.target.checked);
          }}
          checked={!!sendEmbargoedKeyUpdatesImmediately}
          value={!!sendEmbargoedKeyUpdatesImmediately}
        />
        <label htmlFor='sendEmbargoedKeyUpdatesImmediately' className='font-weight-normal no-select-item pl-0'>
          Receive key updates under embargo immediately
        </label>
      </div>
      <span
        className='icon-info d-inline-block pointer'
        data-tooltip-content={`If enabled, key updates which are under embargo will be sent immediately, and will not be included in the digest email.`}
        data-tooltip-id={'setting-tooltip-all'}
        data-tooltip-class-name={'pricing-feature-tooltip feed-tooltip billing-tooltip'}
        onClick={(e) => e.preventDefault()}
      />
    </div>
  );
};
const CreateEmailAlertForm = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const {
    emailAlertData,
    setEmailAlertData,
    saveEmailAlertFunction,
    deleteEmailAlertFunction,
    isSaving,
    openCreatingNewTopic,
    setCreatingNewTopic,
    manageEmailAlerts,
    adminSubscriptions,
    closeModal,
    hasSlackConfigured,
    enableTwitter,
    adminPage,
    userInformation,
    customKeywordsLists,
    client,
  } = props;
  const { IsSavingKeywordList, keywordsLists: stateKeywordsLists, activePlan } = state;
  const { activePlanAndUserState } = userInformation ?? {};
  const { enableHandpickedContent } = adminPage ? activePlanAndUserState : activePlan;
  const {
    id,
    frequency,
    timeOfDay,
    secondTimeOfDay,
    dayOfWeek,
    contentTypesToReceive,
    handPicked,
    keywordListIds,
    name,
    digestCombiningAllTopics,
    alertTypes,
    sendEmbargoedKeyUpdatesImmediately,
  } = emailAlertData;
  const allow = !IsSavingKeywordList;
  const showTweets = process.env.REACT_APP_ENABLE_TWITTER === 'true';

  const keywordsLists = props.keywordsLists ?? stateKeywordsLists;
  const { defaultValues } = useDefaultValuesEmailAlert({ enableTwitter, keywordsLists });
  const { transformTopicOrClientWord, topicOrClientWord, newAgencyPlan } = useTopicOrClientWord();

  const topicsMainItemName = () => {
    let lists = customKeywordsLists ?? keywordsLists;
    if (digestCombiningAllTopics) {
      if (lists.length === 1) {
        return lists[0].name;
      } else {
        return `All ${transformTopicOrClientWord({ plural: true })}`;
      }
    } else if (keywordListIds.length === 0) {
      return `Please select a ${topicOrClientWord}`;
    } else {
      let listName = lists.find((item) => item.id === keywordListIds[0]);
      return `${listName?.name} ${keywordListIds?.length > 1 ? `+ ${keywordListIds?.length - 1}` : ''}`;
    }
  };

  const defaultSlackAlert = getUrlParam('alertType') && getUrlParam('alertType') === 'Slack';
  const disableTypeOfAlerts =
    defaultSlackAlert && frequency !== 'Immediate' && alertTypes.length === 1 && alertTypes.includes('Slack');

  //UPDATE POPUP ITEMS
  const generalChangeProperty = (property, value) => {
    setEmailAlertData({
      ...emailAlertData,
      [property]: value,
    });
  };

  const changeDay = (day) => {
    generalChangeProperty('dayOfWeek', day);
  };

  const changeResources = (resources) => {
    setEmailAlertData({
      ...emailAlertData,
      contentTypesToReceive: resources.join(', '),
      sendEmbargoedKeyUpdatesImmediately: !resources.includes('KeyUpdates') ? null : sendEmbargoedKeyUpdatesImmediately,
    });
  };

  const changeHour = (hour, secondTime) => {
    let timeProp = secondTime ? 'secondTimeOfDay' : 'timeOfDay';
    setEmailAlertData({
      ...emailAlertData,
      [timeProp]: `${hour}:00`,
    });
  };

  const changeTopic = (topicId) => {
    let newKeywordListsIds = keywordListIds.slice();
    let lists = customKeywordsLists ?? keywordsLists;
    if (lists.length > 1) {
      if (keywordListIds.includes(topicId)) {
        let position = newKeywordListsIds.indexOf(topicId);
        newKeywordListsIds.splice(position, 1);
      } else {
        newKeywordListsIds.push(topicId);
      }
    }
    let stateToUpdate = {
      ...emailAlertData,
      keywordListIds: newKeywordListsIds,
      name: nameForOnetopic(),
    };
    setEmailAlertData(stateToUpdate);
  };

  const setDigestCombiningAllTopics = () => {
    let objectToUpdate = {
      ...emailAlertData,
      digestCombiningAllTopics: !digestCombiningAllTopics,
      keywordListIds: [],
      name: nameForOnetopic(),
    };
    setEmailAlertData(objectToUpdate);
  };

  const changeFrequency = (frequency) => {
    let targetObject = defaultValues.find((item) => item.frequency === frequency);
    let resources = targetObject.contentTypesToReceive;
    if (enableTwitter && showTweets && frequency !== 'Immediate') {
      resources += ', Tweets';
    }
    let alertTypes = [...emailAlertData.alertTypes];

    let frequencyObject = {
      ...emailAlertData,
      ...targetObject,
      contentTypesToReceive: resources,
      name: nameForOnetopic(targetObject.suggestedName),
      sendEmbargoedKeyUpdatesImmediately: frequency === 'Immediate' ? null : sendEmbargoedKeyUpdatesImmediately,
    };
    if (frequency !== 'Immediate' && alertTypes.includes('Slack')) {
      if (alertTypes.length === 1) {
        alertTypes = ['Email'];
      } else {
        let slackPosition = alertTypes.indexOf('Slack');
        alertTypes.splice(slackPosition, 1);
      }
      frequencyObject = {
        ...frequencyObject,
        alertTypes,
      };
    }

    setEmailAlertData(frequencyObject);
  };

  let nameForOnetopic = (frequencyName) => {
    let stringsToReplace = ['weekly digest', 'daily digest', 'immediate alert'];
    let defaultFrequencyName = defaultValues.find((value) => value.frequency === frequency).suggestedName;
    if (id === '') {
      return `${frequencyName ? frequencyName : defaultFrequencyName}`;
    } else {
      let createdNewName = name;
      stringsToReplace.forEach((string) => {
        if (name.includes(string)) {
          createdNewName = name.replace(
            string,
            frequencyName
              ? frequencyName.replace('Your ', '').toLowerCase()
              : defaultFrequencyName.replace('Your ', '').toLowerCase()
          );
        }
      });
      return createdNewName;
    }
  };

  const changeAlertType = (type) => {
    let newAlertTypes = [...alertTypes];
    if (newAlertTypes.includes(type)) {
      if (newAlertTypes.length === 1) {
        createNotification('danger', 'You must have at least 1 resource selected');
      } else {
        let position = newAlertTypes.indexOf(type);
        newAlertTypes.splice(position, 1);
      }
    } else {
      newAlertTypes.push(type);
    }
    generalChangeProperty('alertTypes', newAlertTypes);
  };

  return (
    <>
      {defaultSlackAlert && !newAgencyPlan && (
        <p className='bg-light-blue text-white rounded-top pl-lg-5 pl-3 mb-0 py-3 flex-centered justify-content-between'>
          <span>
            <span
              className='icon-circle-check title-h5-m mr-2 d-inline-block'
              style={{ verticalAlign: 'middle', marginTop: '-4px' }}
            />{' '}
            You have successfully set up an integration with Slack
          </span>
          <button
            onClick={() => {
              closeModal();
            }}
            className='circle-button general-button text-white mr-4'
          >
            <span className='icon-close paragraph-p3' />
          </button>
        </p>
      )}
      <h3 className='topic-title topic-title-email-alerts mb-0 section-title pt-4 pb-3 px-lg-5 px-3'>
        {id === '' ? `Create a new` : 'Edit your'} alert {client && id === '' ? `for ${client?.teamName}` : ''}
      </h3>

      <div className='px-lg-5 px-3 '>
        <div className='pt-4 pb-3 px-lg-3 topic-email-alerts-content'>
          <div className='mb-4'>
            <label className='mb-2'>{transformTopicOrClientWord({ plural: true, uppercase: true })}</label>
            <DropdownComponent mainItem={topicsMainItemName()} notCloseOnSelect>
              <TopicsList
                keywordListIds={keywordListIds}
                keywordsLists={customKeywordsLists ?? keywordsLists}
                changeTopic={changeTopic}
                digestCombiningAllTopics={digestCombiningAllTopics}
                setDigestCombiningAllTopics={setDigestCombiningAllTopics}
                client={client}
              />
            </DropdownComponent>
          </div>

          {frequency !== 'Weekdaily' && frequency !== 'TwiceWeekdaily' && (
            <div className='mb-4'>
              <label>Frequency</label>
              <div className='mt-2'>
                <FrequencySelect frequency={frequency} changeFrequency={changeFrequency} allow={allow} />
              </div>
            </div>
          )}

          {(frequency === 'Weekdaily' || frequency === 'TwiceWeekdaily') && (
            <div className='row mx-0 align-items-center mb-4'>
              <div className={`px-0 ${frequency === 'TwiceWeekdaily' ? 'col-7' : 'col-11'}`}>
                <label>Frequency</label>
                <div className='mt-2'>
                  <FrequencySelect frequency={frequency} changeFrequency={changeFrequency} allow={allow} />
                </div>
              </div>
              <div
                className={`px-0 d-flex justify-content-end ${frequency === 'TwiceWeekdaily' ? 'col-4 ' : 'col-5 '}`}
              >
                <div>
                  <label>{frequency === 'TwiceWeekdaily' ? 'Time (first alert)' : 'Time'}</label>
                  <div className='mt-2'>
                    <HourSelect hour={transformHour(timeOfDay)} changeHour={changeHour} allow={allow} />
                  </div>
                </div>
              </div>
              {frequency === 'TwiceWeekdaily' && (
                <div className='col-5 px-0 d-flex justify-content-end'>
                  <div>
                    <label>Time (second alert)</label>
                    <div className='mt-2 twice-daily-select-container'>
                      <HourSelect
                        hour={transformHour(secondTimeOfDay)}
                        changeHour={(e) => changeHour(e, true)}
                        allow={allow}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {frequency === 'Weekly' && (
            <div className='row mx-0 align-items-center my-4'>
              <div className='col-11 px-0'>
                <label>Day</label>
                <div className='mt-2'>
                  <DaySelect day={dayOfWeek} changeDay={changeDay} allow={allow} />
                </div>
              </div>
              <div className='col-5 px-0 d-flex justify-content-end'>
                <div>
                  <label>Time</label>
                  <div className='mt-2'>
                    <HourSelect hour={transformHour(timeOfDay)} changeHour={changeHour} allow={allow} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {frequency !== 'Immediate' && enableHandpickedContent && contentTypesToReceive?.includes('KeyUpdates') && (
            <KeyUpdatesUnderEmbargo
              sendEmbargoedKeyUpdatesImmediately={sendEmbargoedKeyUpdatesImmediately}
              generalChangeProperty={generalChangeProperty}
            />
          )}
          <div className='mb-4'>
            <label>Resources to include</label>
            <div className=' mt-2'>
              <Resources
                resources={contentTypesToReceive.split(', ')}
                typeAlert={frequency}
                changeResources={changeResources}
                allow={allow}
                handPicked={handPicked}
                adminSubscriptions={adminSubscriptions}
                showRetweets={process.env.REACT_APP_ENABLE_TWITTER === 'true'}
              />
            </div>
          </div>

          <div className='mb-4'>
            <label>Alert type</label>
            <div className=' mt-2'>
              <DropdownComponent
                mainItem={typeof alertTypes === 'string' ? alertTypes : alertTypes.join(', ')}
                notCloseOnSelect
                disabled={disableTypeOfAlerts}
                nextIcon={disableTypeOfAlerts ? () => <AlertsWarningIcon /> : null}
              >
                <AlertTypes
                  alertTypes={alertTypes}
                  changeAlertType={changeAlertType}
                  frequency={frequency}
                  adminSubscriptions={adminSubscriptions}
                  hasSlackConfigured={hasSlackConfigured}
                />
              </DropdownComponent>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`d-flex justify-content-${id !== '' || (id === '' && openCreatingNewTopic) || manageEmailAlerts ? 'between' : 'end'} px-4 px-lg-5 confirm-results-section align-items-center bg-white`}
      >
        {id !== '' && (
          <button
            className='general-button simple-delete-button'
            onClick={() => {
              if (!IsSavingKeywordList) {
                deleteEmailAlertFunction(id);
              }
            }}
            disabled={IsSavingKeywordList}
          >
            Delete this alert
          </button>
        )}
        {id === '' && openCreatingNewTopic && (
          <button
            className='general-button simple-link-button back-button'
            onClick={() => {
              setCreatingNewTopic(true);
            }}
          >
            Back
          </button>
        )}
        {manageEmailAlerts && (
          <a
            href='/settings/alerts'
            className='simple-link-button general-button open-external-tab-active'
            target='_blank'
            rel='noopener noreferrer'
          >
            Manage alerts
          </a>
        )}
        <button
          className={`general-button action-button px-3 py-2`}
          onClick={() => {
            if (name === '') {
              createNotification('danger', 'Please give your email alert a name');
            } else if (!digestCombiningAllTopics && keywordListIds.length === 0) {
              createNotification('danger', `Please select at least one ${topicOrClientWord}`);
            } else {
              saveEmailAlertFunction();
            }
          }}
        >
          {isSaving ? <i className='fas fa-spinner fa-spin' /> : `${id === '' ? 'Add' : 'Save'} alert`}
        </button>
      </div>
    </>
  );
};

const TopicsList = (props) => {
  const { transformTopicOrClientWord } = useTopicOrClientWord();
  const { keywordsLists, digestCombiningAllTopics, setDigestCombiningAllTopics, keywordListIds, changeTopic, client } =
    props;

  const referenceHeight = 168;
  const elements = useRef({});
  const [height, setHeight] = useState(referenceHeight);

  const calculateMaxHeightContainer = () => {
    let keys = Object.keys(elements.current);
    if (keys?.length > 0) {
      let height = 8; //padding-top of box
      for (let i = 0; i < keys.length; i++) {
        let el = elements.current[keys[i]];
        let currentHeight = height + el.getBoundingClientRect().height + 36; //margin value + padding-bottom
        height = currentHeight;
      }
      if (height < referenceHeight) {
        setHeight(height);
      }
    }
  };

  useEffect(() => {
    calculateMaxHeightContainer();
  }, []);

  return (
    <div className='keyword-lists-website-feeds large-container topics-alerts-dropdown'>
      <CustomScrollbar
        className={'main-content-scrollbar main-content-section website-list-scroll-component'}
        style={{ height: keywordsLists.length === 0 ? 65 : height }}
        maximalThumbYSize={100}
      >
        {keywordsLists.length === 0 && (
          <p className='mt-2 pt-1 mb-0 main-light-text'>
            <em>You do not currently have any {transformTopicOrClientWord({ plural: true })} set up.</em>
          </p>
        )}
        <div style={{ paddingBottom: '1px' }}>
          {keywordsLists.length > 1 && (
            <div className={`checkbox-item`} ref={(div) => (elements.current['allTopics'] = div)}>
              <input
                id={'digest-combining-all-topics'}
                onChange={() => {
                  setDigestCombiningAllTopics();
                }}
                type='checkbox'
                checked={digestCombiningAllTopics}
              />
              <label className='create-email-alert-label' htmlFor={'digest-combining-all-topics'}>
                All {transformTopicOrClientWord({ plural: true })}
                {client ? ` for ${client?.teamName}` : ''}
                <span
                  className='pricing-feature-more d-none d-lg-inline-block'
                  data-tooltip-id={'settings-tooltip'}
                  data-tooltip-place={'top'}
                  data-tooltip-content={`This will include any future ${transformTopicOrClientWord({ plural: true })} created by you or your team`}
                  data-tooltip-class-name={'pricing-feature-tooltip feed-tooltip digest-tooltip'}
                >
                  More information
                </span>
              </label>
            </div>
          )}
          {keywordsLists.length > 0 && (
            <div className={`${keywordsLists.length > 1 ? 'digest-combining-topics-space' : ''}`}>
              {keywordsLists.map((item) => {
                const { id, name, description } = item;
                return (
                  <div
                    ref={(div) => (elements.current[id] = div)}
                    className={`checkbox-item ${digestCombiningAllTopics ? 'checkbox-item-disabled' : ''} indented-checkbox-item ${!description ? 'pb-1' : ''}`}
                    key={`topicItem-${id}`}
                  >
                    <input
                      id={`${normalize(name)}-${id}`}
                      disabled={digestCombiningAllTopics}
                      onChange={() => {
                        changeTopic(id);
                      }}
                      type='checkbox'
                      checked={keywordListIds.includes(id) || digestCombiningAllTopics}
                    />
                    <label
                      className='create-email-alert-label mb-0 indented-label'
                      htmlFor={`${normalize(name)}-${id}`}
                    >
                      <p className='mb-0'>{name}</p>
                      {description && <p className='topic-description-field mb-0 mt-1'>{description}</p>}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </CustomScrollbar>
      <AddTopicButton client={client} settingsPage keywordsLists={keywordsLists} />
    </div>
  );
};

const AlertTypes = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activeSubscriptions, userProfile } = state;

  const { alertTypes, changeAlertType, frequency, adminSubscriptions, hasSlackConfigured: userSlackConfigured } = props;
  const { planName } = adminSubscriptions ?? activeSubscriptions;
  const hasSlackConfigured = userSlackConfigured ?? userProfile?.hasSlackConfigured;

  const planCondition = planName === 'Mogul' || planName === 'Enterprise';
  const frequencyCondition = frequency === 'Immediate';
  const { agencyUser } = useTopicOrClientWord();
  const location = useLocation();

  const options = [
    { name: 'Email' },
    {
      name: 'Slack',
      disabled:
        (planCondition && !frequencyCondition) ||
        (planCondition && frequencyCondition && !hasSlackConfigured) ||
        !planCondition,
      disabledText:
        planCondition && !frequencyCondition
          ? 'Available for immediate alerts only'
          : `Available on the${agencyUser ? '' : ' Mogul and'}  Enterprise plan`,
      disabledIcon: planCondition && !frequencyCondition ? 'info' : 'padlock',
      disabledLink:
        planCondition && frequencyCondition && !hasSlackConfigured
          ? () => (
              <>
                {location.pathname.startsWith('/admin') ? (
                  <span className='paragraph-p5 ml-1'>User doesn't have integration with Slack configured.</span>
                ) : (
                  <Link
                    className='d-inline-block ml-1 paragraph-p2 simple-link-button'
                    to='/settings/integration-with-slack'
                  >
                    Set up integration with Slack
                  </Link>
                )}
              </>
            )
          : null,
    },
  ];

  return (
    <div className='keyword-lists-website-feeds large-container topics-alerts-dropdown'>
      <div className='pb-2 px-3'>
        {options.map((option) => {
          const { name, disabled, disabledText, disabledIcon, disabledLink } = option;
          return (
            <div
              className={`checkbox-item indented-checkbox-item ${disabled ? 'checkbox-item-disabled' : ''}`}
              key={`alert-item-${name}`}
            >
              <input
                id={`${normalize(name)}`}
                onChange={() => {
                  changeAlertType(name);
                }}
                type='checkbox'
                checked={alertTypes.includes(name)}
                disabled={disabled}
              />
              <label className='create-email-alert-label indented-label' htmlFor={`${normalize(name)}`}>
                <p className='mb-0'>
                  {name}{' '}
                  {disabled && (
                    <>
                      {disabledLink ? (
                        <>{disabledLink()}</>
                      ) : (
                        <>
                          {disabledIcon && disabledText && (
                            <span
                              className={`icon-${disabledIcon} ml-1 d-inline-block`}
                              style={{ verticalAlign: 'middle', marginTop: '-2px' }}
                              data-tooltip-content={disabledText}
                              data-tooltip-id={'settings-tooltip'}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </p>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const AlertsWarningIcon = () => {
  return (
    <span
      style={{ top: '10px' }}
      className='icon-email dropdown-next-icon paragraph-p3 main-light-text pointer'
      data-tooltip-content={'Integration with Slack is available for immediate alerts only'}
      data-tooltip-id={'settings-tooltip'}
    />
  );
};

export { useDefaultValuesEmailAlert };
export default CreateEmailAlertForm;
