import React, { useContext, useState } from 'react';
import SearchFilter from './SearchFilters';
import CommonFilter from './CommonFilter';
import ToggleFilter from './ToggleFilter';
import SortFilter from './SortFilter';
import SortMode from './SortMode';
import useIsMobile from '../../utilities/useIsMobile';
import { DateFilter } from './DateFilter';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import TransformFromPascalCase from '../../../Settings/Utilities/TransformFromPascalCase';
import normalize from '../../utilities/normalizeString';
import ParliamentaryRecordMultipleHierarchy from './ParliamentaryRecordMultipleHierarchy';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { switchValuesBetweenTagsFilters } from '../switchValuesBetweenTagsFilters';
import TwoFiltersPopupContainer from './twoFiltersPopupContainer';
import CalendarPickerFilter from './CalendarPickerFilter';
import { filterFiltersOptionDependOnLocation } from '../filterFiltersOptionDependOnLocation';
import { store } from '../../../Store';
import { RelevantOptions } from '../../key_updates/ShowRelevantExtract';
import ModalComponent from '../../../Common/Modal';
import { useFilters } from '../FiltersContext';
import useCompileFiltersFunction from '../useCompileFilters';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import TopicPickerFilterSentimentPopup from './TopicPickerFilter';
import useWindowHeight from '../../utilities/useWindowHeight';

const FiltersPopup = (props) => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { contactsTags, search } = state;

  const { list } = search;
  const { section, openState } = props;

  const { getDefaultSectionFilters, filtersState, applyFiltersFunction, filterTypeModifiers, clearFilters } =
    useFilters();

  const [componentFilters, setComponentFilters] = useState(JSON.parse(JSON.stringify(filtersState)));
  const sectionFilters = getDefaultSectionFilters(componentFilters);
  const { compileFiltersFunction } = useCompileFiltersFunction(section);
  const compiledSectionFilters = compileFiltersFunction(sectionFilters);

  useEffect(() => {
    if (JSON.stringify(filtersState) !== JSON.stringify(componentFilters)) {
      setComponentFilters(JSON.parse(JSON.stringify(filtersState)));
    }
  }, [filtersState]);

  const { isOpen, setIsOpen } = openState ?? {};

  const componentSelectOption = () => {
    return ({ filter, newValue }) => {
      let basefilters = componentFilters;
      let newFilters = filterTypeModifiers({ basefilters, filter, newValue });
      newFilters = switchValuesBetweenTagsFilters({
        newFilters,
        filter,
        componentFilters,
        newValue,
      });
      setComponentFilters(newFilters);
    };
  };

  const unreadFilter = () => {
    let unreadFilter = componentFilters.find((item) => item.name === 'See unread only');
    return unreadFilter && unreadFilter.value;
  };

  const clearFiltersConditional = () => {
    const { unread, searchWithin, filters } = compiledSectionFilters;
    if (JSON.stringify(filtersState) !== JSON.stringify(componentFilters)) {
      return true;
    } else {
      return filters.length > 0 || unread === true || !!searchWithin;
    }
  };

  const clearFiltersOnPopUp = () => {
    if (JSON.stringify(filtersState) !== JSON.stringify(componentFilters)) {
      setComponentFilters(JSON.parse(JSON.stringify(filtersState)));
    } else {
      clearFilters();
      if (!influenceItem) {
        setIsOpen(false);
      }
    }
  };

  const isMobile = useIsMobile();
  const isMobileFilters = useIsMobile(576);
  const location = useLocation();
  const influenceItem = location.pathname.includes('/have-your-voice-heard') && !isMobile;
  const influenceMapping = location.pathname.includes('/stakeholder-mapping');
  const influenceContacts = location.pathname.includes('influence/directory');

  useEffect(() => {
    //update componentState when switching between pages
    setComponentFilters(JSON.parse(JSON.stringify(filtersState)));
  }, [location.pathname]);

  const tagsFilters = sectionFilters.filter(
    (item) =>
      (item.name === 'Tag is' && contactsTags.length > 0) || (item.type === 'TagIsNot' && contactsTags.length > 0)
  );

  const relevantExtractCondition = location.pathname?.includes('/key-updates');
  const sortedItems = ({ items }) => {
    return items.sort((a, b) => {
      const hasCustomOrderA = a.customOrder !== undefined;
      const hasCustomOrderB = b.customOrder !== undefined;

      if (hasCustomOrderA && hasCustomOrderB) {
        return (a.customOrder ?? 0) - (b.customOrder ?? 0);
      } else if (hasCustomOrderA) {
        return -1;
      } else if (hasCustomOrderB) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const filtersToRender = sortedItems({ items: sectionFilters });
  const { windowHeight } = useWindowHeight();
  const heightValue = (windowHeight * 90) / 100 - 150;

  return (
    <>
      <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} maxWidth={700}>
        <div className={`${!relevantExtractCondition ? 'pt-3' : ''}`}>
          {!relevantExtractCondition && <h3 className='topic-title section-title pl-lg-5 pl-4 pb-3 mb-0'>Filters</h3>}
          <CustomScrollbar
            autoHeight
            autoHeightMin='100%'
            autoHeightMax={`${heightValue}px`}
            className={'simple-scrollbar'}
          >
            <div className='px-4 px-lg-5 pt-4 pb-3 filters-popup-container'>
              {relevantExtractCondition && (
                <div className='section-title py-3 mb-4'>
                  <h3 className='topic-title'>Snippet displayed</h3>
                  <RelevantOptions />
                </div>
              )}
              {contactsTags?.length === 0 && influenceContacts && (
                <div className={`flex-grow-1 filters-section`}>
                  <div className='filters-section-container'>
                    <h3 className='no-contacts-title'>Tags</h3>
                    <p className='mb-0 light-50-text' style={{ paddingLeft: '2px' }}>
                      You have not tagged any stakeholders yet
                    </p>
                  </div>
                </div>
              )}
              {filtersToRender
                .filter((item) => !item.hidden)
                .map((item, index) => {
                  let { type, hidden, hiddenDesktop, excludeFromPopup, name } = item;
                  if (influenceMapping && type === 'sortMode') {
                    excludeFromPopup = true;
                  }
                  const showFilter = isMobile ? !hidden : !hidden && !hiddenDesktop;
                  const renderFilter = isMobileFilters ? showFilter : showFilter && !excludeFromPopup;

                  const filteredOptionsItem = filterFiltersOptionDependOnLocation({ item, filters: sectionFilters });

                  const filterProps = {
                    filter: filteredOptionsItem,
                    selectOption: componentSelectOption(),
                    unreadFilter: unreadFilter(),
                    filters: sectionFilters,
                  };
                  return (
                    <React.Fragment key={`filter-${index}`}>
                      {name === 'Tag is' || type === 'TagIsNot' ? (
                        <TwoFiltersPopupContainer
                          sectionFilters={tagsFilters}
                          componentSelectOption={componentSelectOption}
                          unreadFilter={unreadFilter}
                          section={section}
                        />
                      ) : (
                        <div
                          className={`filters-section filter-section-${type}-${list !== null ? 'topic' : 'search'}-mode ${
                            list !== null && list.id === null ? 'master-topic' : ''
                          } ${normalize(TransformFromPascalCase(section))}-filters-popup`}
                        >
                          {renderFilter && (
                            <div
                              className={`filters-section-container`}
                              style={type === 'addedOn' ? { paddingBottom: 0 } : undefined}
                            >
                              {type === 'toggle' && <ToggleFilter {...filterProps} />}
                              {type === 'sort' && <SortFilter {...filterProps} />}
                              {type === 'date' && <DateFilter {...filterProps} />}
                              {type === 'addedOn' && <CalendarPickerFilter {...filterProps} />}
                              {type === 'sortMode' && <SortMode {...filterProps} list={list} />}
                              {type === 'search' && <SearchFilter {...filterProps} />}
                              {type === 'parliamentaryRecordMultipleHierarchy' && (
                                <ParliamentaryRecordMultipleHierarchy {...filterProps} />
                              )}
                              {type === 'topicPickerWithSentiment' && (
                                <TopicPickerFilterSentimentPopup
                                  showName={true}
                                  localFilterState={{ localFilter: item }}
                                  {...filterProps}
                                />
                              )}
                              {(!type || type === 'membership') && <CommonFilter {...filterProps} />}
                            </div>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
            </div>
          </CustomScrollbar>
          <div className='d-flex justify-content-between align-content-center border-top px-4 px-lg-5 py-2 py-lg-4'>
            <button
              className={`simple-link-button general-button ${
                influenceContacts || influenceMapping ? 'action-delete-text' : ''
              }`}
              onClick={() => {
                clearFiltersOnPopUp();
              }}
              style={{
                visibility: clearFiltersConditional() ? 'visible' : 'hidden',
              }}
            >
              Clear filters
            </button>

            <button
              className='general-button action-button px-4'
              onClick={() => {
                applyFiltersFunction(componentFilters);
                if (!influenceItem) {
                  setIsOpen(false);
                }
              }}
            >
              Apply filters
            </button>
          </div>
        </div>
        <ReactTooltip
          effect={'solid'}
          id={'filters-tooltip'}
          className={'header-tooltip'}
          arrowColor={'transparent'}
          place={'top'}
        />
      </ModalComponent>
    </>
  );
};

export default FiltersPopup;
