import React, { useState } from 'react';
import CreateANewTeamPopUp from './CreateANewTeamPopUp';
import TeamFilters from './TeamFilters';
import TeamList from './TeamList';
import filtersOptions from '../FiltersOptions';
const AdminListPage = (props) => {
  const { isLoadingFilters, teamList, loadMoreResults } = props;
  const [isOpenAddATeamPopup, setIsOpenAddATeamPopup] = useState(false);
  return (
    <div className='mx-auto main-content-wrapper w-100 px-lg-5 px-3 position-relative'>
      <TeamFilters {...props} filtersOptions={filtersOptions} setIsOpenAddATeamPopup={setIsOpenAddATeamPopup} />
      {isLoadingFilters && <p className='pt-5'>Loading...</p>}
      {!isLoadingFilters && <TeamList teamList={teamList} loadMoreResults={loadMoreResults} />}
      <CreateANewTeamPopUp
        {...props}
        setIsOpenAddATeamPopup={setIsOpenAddATeamPopup}
        isOpenAddATeamPopup={isOpenAddATeamPopup}
      />
    </div>
  );
};

export default AdminListPage;
