import React, { useRef, useContext, useEffect } from 'react';
import { store } from '../../Store.js';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord.js';
const TopicName = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { team, IsSavingKeywordList, keywordsLists } = state;
  const { agencyUser } = useTopicOrClientWord();
  const { myself } = team ?? {};
  const { listPosition } = props;
  const currentKeywordCategory = keywordsLists[listPosition];
  const { isMyList, id, name } = currentKeywordCategory ?? {};
  const keywordInput = useRef(null);

  useEffect(() => {
    if (id === '') {
      setEditMode();
    }
  }, [id]);

  const setEditMode = () => {
    setTimeout(() => {
      keywordInput.current.focus();
    }, 50);
  };

  const changeName = (name) => {
    const newList = { ...keywordsLists[listPosition], name: name };
    let keywordsListsToRender = keywordsLists.slice();
    keywordsListsToRender[listPosition] = newList;
    dispatch({ type: 'MODIFY_SECTION', parameter: 'keywordsLists', value: keywordsListsToRender });
  };

  return (
    <div className='topic-name-container' onClick={() => setEditMode()}>
      <input
        type='text'
        placeholder={`${agencyUser ? 'Client name' : 'Give your topic a name'}`}
        className={`topic-name pr-2 pl-0 ${IsSavingKeywordList ? 'disabled-name' : ''}`}
        value={name}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && name !== '') {
            e.preventDefault();
            document.getElementsByClassName('keywords-category-words')[0].click();
          }
        }}
        onChange={(e) => {
          changeName(e.target.value);
        }}
        ref={keywordInput}
        disabled={(!isMyList && myself?.role === 'Member') || IsSavingKeywordList}
        tabIndex={0}
      />
    </div>
  );
};

export default TopicName;
