import { useContext } from 'react';
import { store } from '../../Store';
import useTopicOrClientWord from '../hooks/useTopicOrClientWord';
import LocalStorage from '../../../utils/LocalStorage';

const useSetListsIds = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { team } = state;
  const { teamId } = team ?? {};
  const { newAgencyPlan } = useTopicOrClientWord();

  const setListsIds = (id, clientPicker) => {
    const property = clientPicker ? 'clientsIds' : 'listsIds';
    const agencyCondition = newAgencyPlan && !clientPicker;
    //get clientsIds
    const listIds = LocalStorage.getItem(property);
    const storedProperty = listIds !== null ? JSON.parse(listIds) : null;
    let arrayIds = storedProperty ?? [];
    if (agencyCondition && !!storedProperty) {
      arrayIds = storedProperty?.[teamId] ?? [];
    }
    if (Array.isArray(arrayIds)) {
      if (arrayIds?.includes(id)) {
        let position = arrayIds.indexOf(id);
        arrayIds.splice(position, 1);
      }
      arrayIds?.unshift(id);
    }
    let finalElement = arrayIds;
    if (agencyCondition && !!teamId) {
      if (storedProperty && !Array.isArray(storedProperty)) {
        finalElement = { ...storedProperty, [teamId]: arrayIds };
      } else {
        finalElement = { [teamId]: arrayIds };
      }
    }
    LocalStorage.setItem(property, JSON.stringify(finalElement));
  };

  const getStoredListIds = ({ includeMasterTopic = false, clientPicker }) => {
    const property = clientPicker ? 'clientsIds' : 'listsIds';
    const items = LocalStorage.getItem(property);
    let listsIds = !!items ? JSON.parse(items) : null;
    if (newAgencyPlan && !!listsIds && !clientPicker) {
      if (Array.isArray(listsIds)) {
        listsIds = null;
      } else {
        listsIds = listsIds?.[teamId] ?? null;
      }
    }

    const lists =
      !!localStorage && !!listsIds && Array.isArray(listsIds)
        ? includeMasterTopic
          ? listsIds
          : listsIds?.filter((item) => item !== null)
        : null;
    return lists;
  };

  return { setListsIds, getStoredListIds };
};

export { useSetListsIds };
