import React, { useContext, useEffect, useState } from 'react';
import createNotification from '../Utilities/CreateNotification';
import { store } from '../../Store.js';
import normalize from '../../Dashboard/utilities/normalizeString';
import axios from 'axios';
import calculateEmbedListHeight from '../WebsiteFeeds/calculateEmbedListHeight';
import DropdownComponent from '../WebsiteFeeds/DropdownComponent';
import EmailAlertInformation from './EmailAlertInformation';
import createEmailALertName from '../Topics/Helpers/createEmailAlertName';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import useGeneralApiCall from '../../Dashboard/apiCalls/useGeneralApiCall';
import CustomScrollbar from '../../Common/CustomScrollbar.js';

const CreateEmailAlertNewTopic = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { keywordsLists, listPosition, emailAlerts } = state;
  const {
    emailAlertsToSelect,
    closeModal,
    isSaving,
    setIsSaving,
    saveEmailAlertFunction,
    setCreatingNewTopic,
    topicData,
  } = props;
  const currentKeywordCategory = keywordsLists[listPosition];
  const conditionEmailAlert = emailAlertsToSelect.length > 0;
  const [selectedOption, setSelectedOption] = useState(conditionEmailAlert ? 'add-existing-alert' : 'create-new-alert');
  const [selectedEmailAlerts, setSelectedEmailAlerts] = useState(
    conditionEmailAlert ? [emailAlertsToSelect[0].id] : []
  );

  const { generalApiCall } = useGeneralApiCall();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  useEffect(() => {
    return () => {
      source.cancel('Add to email alerts function');
    };
  }, []);

  const selectEmailAlert = (id) => {
    let newSelectedEmailAlerts = [...selectedEmailAlerts];
    if (newSelectedEmailAlerts.includes(id)) {
      if (newSelectedEmailAlerts.length > 1) {
        let position = newSelectedEmailAlerts.indexOf(id);
        newSelectedEmailAlerts.splice(position, 1);
      }
    } else {
      newSelectedEmailAlerts.push(id);
    }
    if (selectedOption !== 'add-existing-alert') {
      setSelectedOption('add-existing-alert');
    }
    setSelectedEmailAlerts(newSelectedEmailAlerts);
  };

  const saveEmailAlertsSelected = async () => {
    setIsSaving(true);
    let newEmailAlerts = [...emailAlerts];
    let someAlertPaused = newEmailAlerts.some((alert) => alert.paused);
    for (let i = 0; i < selectedEmailAlerts.length; i++) {
      let id = selectedEmailAlerts[i];
      let position = newEmailAlerts.findIndex((alert) => alert.id === id);
      let update = await updateEmailAlert(id);
      if (!!update) {
        newEmailAlerts[position] = update;
      } else {
        break;
      }
    }
    dispatch({
      type: 'MODIFY_SECTION',
      parameter: 'emailAlerts',
      value: newEmailAlerts,
    });
    setIsSaving(false);
    createNotification('success', `${someAlertPaused ? 'Alert updated and unpaused' : `Alert updated`}`);
    closeModal();
  };

  const updateEmailAlert = async (id) => {
    let item = emailAlerts.find((alert) => alert.id === id);
    let pathname = '/api/alert-settings/update';
    let method = 'post';
    let newTopicId = topicData?.topicId ?? currentKeywordCategory?.id;
    let requestProperties = {
      ...item,
      keywordListIds: [...item.keywordListIds, newTopicId],
    };

    if (item.paused) {
      requestProperties.paused = false;
    }
    let results = await generalApiCall({
      method,
      pathname,
      requestProperties,
      needsAuthentication: true,
      requestSource: source,
    });

    return results;
  };

  const EmailAlertList = () => {
    return (
      <div className='keyword-lists-website-feeds large-container email-alerts-dropdown '>
        <CustomScrollbar
          className={'main-content-scrollbar main-content-section website-list-scroll-component'}
          style={{ height: calculateEmbedListHeight(emailAlertsToSelect, 67) }}
          maximalThumbYSize={100}
        >
          {emailAlertsToSelect.map((item) => {
            const { id, name, frequency, paused } = item;
            return (
              <div className={`checkbox-item`} key={`topicItem-${id}`}>
                <input
                  id={`${normalize(name)}-${id}`}
                  onChange={() => {
                    selectEmailAlert(id);
                  }}
                  type='checkbox'
                  checked={selectedEmailAlerts.includes(id)}
                />
                <label className='create-email-alert-label' htmlFor={`${normalize(name)}-${id}`}>
                  {createEmailALertName(frequency)}
                  {paused && (
                    <span className='text-red ml-2'>
                      <em>Currently paused</em>
                    </span>
                  )}
                  <EmailAlertInformation emailAlert={item} />
                </label>
              </div>
            );
          })}
          <span className='d-block' style={{ height: '1px' }}></span>
        </CustomScrollbar>
      </div>
    );
  };

  const changeSelectedOption = (option) => {
    if (selectedOption === option) {
      setSelectedOption('');
    } else {
      setSelectedOption(option);
    }
  };

  const createEmailALertNameDropdown = () => {
    if (conditionEmailAlert) {
      const emailAlertItem = emailAlerts.find((alert) => alert.id === selectedEmailAlerts[0]);
      return <span className='email-alerts-individual-item'>{createEmailALertName(emailAlertItem.frequency)}</span>;
    }
  };

  const SeeAdvancedOptions = () => {
    return (
      <button
        className='simple-link-button general-button'
        onClick={() => {
          setCreatingNewTopic(false);
        }}
      >
        See advanced options
      </button>
    );
  };

  const { topicOrClientWord } = useTopicOrClientWord();

  return (
    <>
      <h3 className='topic-title topic-title-email-alerts mb-0 section-title pt-4 pb-3 px-lg-5 px-4 mt-3 mt-lg-0'>
        Do you want to receive email alerts for this {topicOrClientWord}?
      </h3>
      <div className='pb-3 px-lg-5 pt-5 pb-lg-4 px-4'>
        <div className='email-alerts-create-topic'>
          {conditionEmailAlert && (
            <>
              <div className='radio-button mb-4'>
                <input
                  id={'add-existing-alert'}
                  onChange={() => {
                    changeSelectedOption('add-existing-alert');
                  }}
                  type='radio'
                  checked={selectedOption === 'add-existing-alert'}
                />
                <label htmlFor={'add-existing-alert'} className='title-h5-m-bold mb-3'>
                  Add to an existing alert
                </label>
                <div className='existing-email-alert-dropdown'>
                  <DropdownComponent
                    items={EmailAlertList}
                    mainItem={createEmailALertNameDropdown()}
                    notCloseOnSelect
                  />
                </div>
              </div>
              <div className='radio-button mb-4 sort-mode-radio-stakeholders'>
                <input
                  id={'create-new-alert'}
                  onChange={() => {
                    changeSelectedOption('create-new-alert');
                  }}
                  type='radio'
                  checked={selectedOption === 'create-new-alert'}
                />
                <label htmlFor={'create-new-alert'}>
                  <p className='title-h5-m-bold mb-2'>Create a new alert</p>
                  <p className='main-light-text'>
                    By default, alerts are sent as a daily digest at 9am. <SeeAdvancedOptions />
                  </p>
                </label>
              </div>
            </>
          )}

          {!conditionEmailAlert && (
            <div className='email-alerts-individual-item d-flex'>
              <span
                className='icon-email paragraph-p3 d-inline-block'
                style={{ verticalAlign: 'middle', margin: '5px 8px 0px 0px' }}
              />
              <div>
                <p className='title-h5-m mb-1'>Receive a daily digest at 9am on weekdays</p>
                <p>
                  <SeeAdvancedOptions />
                </p>
              </div>
            </div>
          )}

          <div className='d-flex justify-content-end pt-3 pb-4'>
            <button
              className='general-button outline-active-button mr-3 px-3'
              onClick={() => {
                closeModal();
              }}
            >
              No alert
            </button>
            <button
              className='general-button action-button px-3'
              onClick={() => {
                if (!isSaving) {
                  if (selectedOption === 'add-existing-alert') {
                    saveEmailAlertsSelected();
                  } else {
                    saveEmailAlertFunction();
                  }
                }
              }}
            >
              {isSaving ? (
                <i className='fas fa-spinner fa-spin' />
              ) : (
                `${selectedOption === 'add-existing-alert' ? 'Update alert' : 'Create alert'}`
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEmailAlertNewTopic;
