import React from 'react';
import useTopicOrClientWord from '../../Dashboard/hooks/useTopicOrClientWord';
import EmailAlertInformation from './EmailAlertInformation';
import createEmailAlertName from './Helpers/createEmailAlertName';
import { Portal } from 'react-portal';
import ModalComponent from '../../Common/Modal';

const ResumeTopicEmailAlerts = (props) => {
  const { setOpenTopicEmailAlerts, openTopicEmailAlerts, currentTopicAlerts } = props;
  const { topicOrClientWord } = useTopicOrClientWord();

  return (
    <Portal>
      <ModalComponent maxWidth={660} isOpen={openTopicEmailAlerts} setIsOpen={setOpenTopicEmailAlerts}>
        <div className='pt-4'>
          <h3 className='topic-title topic-title-email-alerts mb-0 section-title pb-3 px-lg-5 px-3 mt-3 mt-lg-0'>
            You have {currentTopicAlerts.length} alert{currentTopicAlerts.length > 1 ? 's' : ''} associated with this{' '}
            {topicOrClientWord}
          </h3>
          <div className='px-lg-5 py-4 py-lg-5 px-3'>
            <>
              {currentTopicAlerts.map((item) => {
                const { id, paused, frequency, alertTypes } = item;
                const slackAlert = alertTypes.includes('Slack');
                const emailAlert = alertTypes.includes('Email');
                return (
                  <div className='email-alerts-individual-item resume-email-alert-item d-flex' key={`email-alert${id}`}>
                    <div className='text-right'>
                      {emailAlert && (
                        <span
                          className='icon-email paragraph-p3 d-inline-block'
                          style={{ verticalAlign: 'middle', margin: '-2px 8px 0px 0px' }}
                        />
                      )}
                      {slackAlert && (
                        <img
                          className='d-inline-block'
                          style={{ verticalAlign: 'middle', width: '19px', margin: '-3px 8px 0px 0px' }}
                          src={`${process.env.REACT_APP_CDNURL}/images/20230716-slack-icon.svg`}
                          alt='slack logo'
                        />
                      )}
                    </div>
                    <div>
                      <p className='mb-0'>
                        {createEmailAlertName(frequency)}
                        <EmailAlertInformation emailAlert={item} />
                      </p>
                      {paused && (
                        <p className='mb-0 text-red paragraph-p2'>
                          <em>Currently paused</em>
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          </div>
          <div className='border-top px-lg-5 py-3 px-3'>
            <a
              href='/settings/alerts'
              className='simple-link-button general-button open-external-tab-active'
              target='_blank'
              rel='noopener noreferrer'
            >
              Manage alerts
            </a>
          </div>
        </div>
      </ModalComponent>
    </Portal>
  );
};

export default ResumeTopicEmailAlerts;
