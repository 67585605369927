import SortMode from '../SortMode';
import React, { useContext, useRef, useState } from 'react';
import normalize from '../../../utilities/normalizeString';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../../../../Store';
import styles from './DropDownFilters.module.scss';
import useOutsideElement from '../../../utilities/useOutsideElement';
import removeHyphenFromString from '../../../utilities/removeHyphenFromString';
import { useLocation } from 'react-router-dom';
import CreateAccountPopup from '../../../components/CreateAccountPopup';
import { useFilters } from '../../FiltersContext';

const StakeholdersSortFilter = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, search, referenceState } = state;
  const { activeSearch } = search;
  const { activeReference } = referenceState;
  const { subscriptionStatus, code, enableReferenceSection } = activePlan;

  const { getDefaultSectionFilters, filtersState, applyFiltersFunction, filterTypeModifiers } = useFilters();

  const section = 'Stakeholders';
  const searchMode = activeSearch || activeReference;

  const sectionFilters = getDefaultSectionFilters(filtersState);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);

  const { isAuthenticated } = useAuth0();

  const trialUser =
    (subscriptionStatus === 'InTrial' && code.includes('Mogul')) ||
    subscriptionStatus === 'TrialEnded' ||
    !enableReferenceSection;

  const allowedUsers = isAuthenticated && !trialUser;

  const selectOption = () => {
    return ({ filter, newValue }) => {
      if (allowedUsers) {
        let newFilters = filterTypeModifiers({ filter, newValue });
        applyFiltersFunction(newFilters);
      } else {
        setShowRegisterPopup(true);
      }
    };
  };

  return (
    <>
      <div className={styles.DropDownFilters} style={{ gap: '0' }}>
        {sectionFilters
          .filter((item) => item.type === 'sortMode')
          .filter((item) => !item.excludeFromPopup)
          .map((item, index) => {
            const { hidden, name } = item;
            const showFilter = !hidden;
            const renderFilter = showFilter;
            const filterProps = {
              filter: item,
              selectOption: selectOption(),
            };
            return (
              <React.Fragment key={`section-filter-${index}-${normalize(name)}`}>
                {renderFilter && (
                  <StakeholderSortFilter
                    {...filterProps}
                    section={section}
                    allowedUsers={allowedUsers}
                    searchMode={searchMode}
                  />
                )}
              </React.Fragment>
            );
          })}
      </div>

      {!isAuthenticated && (
        <CreateAccountPopup
          text={'You’ll need to create an account to filter results'}
          setShowPopup={setShowRegisterPopup}
          showPopup={showRegisterPopup}
        />
      )}
    </>
  );
};

const StakeholderSortFilter = (props) => {
  const { filter, list } = props;
  const { type } = filter;

  const [isExpanded, setExpanded] = useState(false);
  const location = useLocation();
  const searchBarElement = useRef(null);

  const renderSortHeader = () => {
    const selectedInDropdown =
      location.hash.length > 0
        ? removeHyphenFromString(
            location.hash
              .split('~')
              .find((str) => str.startsWith('#'))
              .substring(1)
          )
        : filter.optionSelected;
    return (
      <span className='filter-sort-dropdown-header'>
        Sort by: <span className='filter-sort-dropdown-header-active-stakeholders'>{selectedInDropdown}</span>
      </span>
    );
  };

  useOutsideElement(searchBarElement, () => {
    setExpanded(false);
  });

  return (
    <div>
      {isExpanded && <div className='general-close-background'></div>}
      <div
        className={`dropdown-keywords-lists dropdown-keywords-lists-stakeholders transition-200`}
        ref={searchBarElement}
        style={{
          width: 'auto',
        }}
      >
        <div
          className='dropdown-keywords-activeitem dropdown-keywords-activeitem-sort-button dropdown-filter-activeItem flex-centered'
          onClick={() => {
            setExpanded(true);
          }}
        >
          <p className={`search-main-text-value`}>
            <span className={`d-block`}>{renderSortHeader()}</span>
          </p>

          {/* ARROW BUTTON */}
          <span
            className={`search-dropdown search-dropdown-stakeholders ${
              isExpanded ? 'search-dropdown-open-stakeholders' : ''
            }`}
            onClick={(e) => {
              if (isExpanded) {
                e.stopPropagation();
                setExpanded(false);
              }
            }}
          ></span>
        </div>
        <div
          className={`open-dropdown ${
            type === 'sortMode' ? 'open-dropdown-sort-stakeholders' : ''
          } d-${isExpanded ? 'block' : 'none'}`}
        >
          <div className='open-dropdown-container'>
            <SortMode {...props} list={list} notIncludeName inDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeholdersSortFilter;
