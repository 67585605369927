import React, { useState } from 'react';
import ConfirmDeleteFolder from './ConfirmDeleteFolder';
import { CreateFolderPopup } from './CreateFolderPopup';
import FolderItems from './FolderItems';
import SaveItemsSideNav from './SaveItemsSideNav';
import SkeletonSavedItems from './SkeletonSavedItems';
import SubNavSavedItems from './SubNavSavedItems';
const SavedItemsComponent = (props) => {
  const { isLoading } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [folderToEdit, setFolderToEdit] = useState(null);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
  const [editParameter, setEditParameter] = useState(null);

  return (
    <>
      <SubNavSavedItems
        {...props}
        setShowConfirmDeletePopup={setShowConfirmDeletePopup}
        setFolderToEdit={setFolderToEdit}
        setShowPopup={setShowPopup}
        setEditParameter={setEditParameter}
      />
      {isLoading && <SkeletonSavedItems />}
      {!isLoading && (
        <>
          <div className={`row mx-auto main-content-wrapper align-items-start`}>
            <div className='side-left-element d-none d-lg-block'>
              <SaveItemsSideNav {...props} setShowPopup={setShowPopup} />
            </div>
            <div className='main-content pr-xl-5 px-md-4 px-3'>
              <FolderItems {...props} />
            </div>
          </div>
          <CreateFolderPopup
            {...props}
            setShowPopup={setShowPopup}
            showPopUp={showPopup}
            folderToEdit={folderToEdit}
            setFolderToEdit={setFolderToEdit}
            setEditParameter={setEditParameter}
            editParameter={editParameter}
          />
          <ConfirmDeleteFolder {...props} setShowPopup={setShowConfirmDeletePopup} showPopUp={showConfirmDeletePopup} />
        </>
      )}
    </>
  );
};

export default SavedItemsComponent;
