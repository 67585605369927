class LocalStorageClass {
  localStorageExists = Boolean(typeof window !== 'undefined' && window.localStorage);
  getItem(key) {
    try {
      if (this.localStorageExists) {
        return localStorage.getItem(key);
      }
    } catch (e) {}
  }
  setItem(key, value) {
    try {
      if (this.localStorageExists) {
        localStorage.setItem(key, value);
      }
    } catch (e) {}
  }
  clear() {
    try {
      if (this.localStorageExists) {
        localStorage.clear();
      }
    } catch (e) {}
  }
  removeItem(key) {
    try {
      if (this.localStorageExists) {
        localStorage.removeItem(key);
      }
    } catch (e) {}
  }
}

const LocalStorage = new LocalStorageClass();
export default LocalStorage;
