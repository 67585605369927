import React from 'react';
import useEmailComponentFunctions from '../useEmailComponentFunctions';
import useValidateContactsOversubscribed from '../../../utilities/useValidateContactsOversubscribed';
import ModalComponent from '../../../../Common/Modal';

const ContentPopup = ({ discardFunction, setShowPopup }) => {
  const { isLoading, saveEmail } = useEmailComponentFunctions();

  const { validateContactsOversubscribed } = useValidateContactsOversubscribed();

  return (
    <div className='p-3 px-lg-5 pt-lg-5 pb-lg-2'>
      <div className='text-center'>
        <p>Do you want to discard the changes you've made?</p>

        <div className='my-4'>
          <button
            className='general-button delete-button px-4 mr-3 py-1'
            onClick={() => {
              setShowPopup(false);
            }}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: '#9b171f',
              fontWeight: 400,
              marginTop: '-4px',
              border: 'none',
            }}
            className='btn btn-general action-button px-4 py-2'
            onClick={async () => {
              discardFunction();
            }}
          >
            Discard changes
          </button>
          <button
            style={{
              backgroundColor: '#0094cc',
              fontWeight: 400,
              marginTop: '-4px',
              border: 'none',
              marginLeft: '20px',
              width: '120px',
            }}
            className='btn btn-general action-button px-4 py-2'
            onClick={() =>
              validateContactsOversubscribed(
                () =>
                  saveEmail({
                    isForSending: false,
                    notRedirect: true,
                    actionOnSuccess: discardFunction,
                  }),
                'save_email'
              )
            }
          >
            {isLoading ? <i className='fas fa-spinner fa-spin'></i> : `Save draft`}
          </button>
        </div>
      </div>
    </div>
  );
};

const CrmEmailDiscardPopup = (props) => {
  const { showPopUp, setShowPopup } = props;
  return (
    <ModalComponent maxWidth={500} isOpen={showPopUp} setIsOpen={setShowPopup}>
      <ContentPopup {...props} />
    </ModalComponent>
  );
};

export default CrmEmailDiscardPopup;
