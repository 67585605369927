const createUtmParameters = (character) => {
  //LocalStorage.getItem('utm_parameters') !== null
  let utm_parameters = '';
  let cookieItem = document.cookie.split('; ').find((row) => row.startsWith('utm_parameters='));
  if (cookieItem) {
    let parameters = JSON.parse(cookieItem?.replace('utm_parameters=', ''));
    utm_parameters = `${parameters.join('&')}`;
  }
  return utm_parameters !== '' ? `${character}${utm_parameters}` : '';
};
export default createUtmParameters;
