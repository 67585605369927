export const createTagFilterOption = (array, tagIsNot) => {
  return array.map((tag) => {
    if (tag.selected === 'exclude' && tagIsNot) {
      return { name: tag.value, selected: true, value: tag.value };
    } else if (tag.selected === true && !tagIsNot) {
      return { name: tag.value, selected: true, value: tag.value };
    } else {
      return { name: tag.value, value: tag.value, hidden: true };
    }
  });
};

const InfluenceContactsFilters = [
  {
    name: 'Vocal about a topic',
    pages: ['InfluenceContacts'],
    searchState: 'always',
    filterName: 'topicMatchedKeywordListIds',
    customOrder: 0,
    type: 'topicPickerWithSentiment',
    prefix: 'vocal-about',
    sentiment: null,
    options: [],
  },
  {
    name: 'Tag',
    pages: ['InfluenceContacts'],
    searchState: 'always',
    filterName: 'tags',
    additionalItemsText: 'tags',
    notUseFromLocalStorage: true,
    customOrder: 5,
    prefix: 'tag',
    options: [],
  },
  {
    name: 'Champion status',
    pages: ['InfluenceContacts'],
    searchState: 'always',
    filterName: 'championStatus',
    customOrder: 7,
    optionSelected: '',
    options: [
      {
        name: 'Promoter',
      },
      {
        name: 'Detractor',
      },
      {
        name: 'Neutral',
      },
      {
        name: 'No status',
        filterValue: null,
        operator: 'not_exists',
      },
    ],
    prefix: '',
  },
];

export default InfluenceContactsFilters;
